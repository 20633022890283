import React from "react";
import { useTranslation } from "react-i18next";
import Navbar from "../components/navbar";

import { CookieEN, CookieTH } from "./cookieText";

export default function Cookie(props) {
  const { i18n } = useTranslation();
  const navitems = [
    {
      title: "Term and Condition",
      title_th: "เงื่อนไขการใช้บริการ",
      active: false,
      link: "/term_and_condition",
    },
    {
      title: "Cookie Policy",
      title_th: "นโยบายคุกกี้",
      active: true,
      link: "/cookie_policy",
    },
    {
      title: "Privacy Policy",
      title_th: "นโยบายความเป็นส่วนตัว",
      active: false,
      link: "/privacy_policy",
    },
  ];
  return (
    <section className="term-condition">
      <div className="mb-[150px] md:mb-[170px]">
        <Navbar navitems={navitems} />
      </div>
      {i18n.language == "en" ? <CookieEN /> : <CookieTH />}
    </section>
  );
}
