import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useTranslation, Trans } from "react-i18next";
import { FaRegFilePdf } from "react-icons/fa6";
import { BsCloudDownload } from "react-icons/bs";

import NavbarWpicture from "../components/navbarWpicture";

export default function ManagementDiscuss(props) {
  const { i18n } = useTranslation();
  const navitems = [
    {
      title: "REIT Performance",
      title_th: "ผลการดำเนินงานของกองทุน",
      active: false,
      link: "/Investor_Relations/Financial_Information/REIT_Performance",
    },
    {
      title: "Asset Performance",
      title_th: "ผลการดำเนินงานของอสังหาริมทรัพย์",
      active: false,
      link: "/Investor_Relations/Financial_Information/Asset_Performace",
    },
    {
      title: "Financial Statement",
      title_th: "งบการเงิน",
      active: false,
      link: "/Investor_Relations/Financial_Information/Financial_Statement",
    },
    {
      title: "Management Discussion and Analysis",
      title_th: "คำอธิบายและการวิเคราะห์ของฝ่ายจัดการ",
      active: true,
      link: "/Investor_Relations/Financial_Information/Management_Discussion_and_Analysis",
    },
    {
      title: "Appraisal Report",
      title_th: "รายงานประเมินมูลค่าทรัพย์สิน",
      active: false,
      link: "/Investor_Relations/Financial_Information/Appraisal_Report",
    },
    {
      title: "Unit NAV",
      title_th: "มูลค่าสินทรัพย์สุทธิ(NAV)",
      active: false,
      link: "/Investor_Relations/Financial_Information/Unit_NAV",
    },
  ];

  const [selectedYear, setSelectedYear] = useState("2024");
  const [data, setData] = useState([]);

  const handleSelectChange = async (e) => {
    const year = e.target.value;
    setSelectedYear(year);
  };

  const populateMDNAData = async () => {
    const response = await Axios.get(
      process.env.REACT_APP_API_URI +
        "/Financial/GetManagementDiscussion?lng=" +
        i18n.language +
        "&fnYear=" +
        selectedYear
    ).then((res) => {
      const data = res.data;
      setData(data);
    });
  };

  useEffect(() => {
    populateMDNAData();
  }, [i18n.language, selectedYear]);

  const [navFix, setFixed] = useState(false);

  const updatefixed = (fix) => {
    setFixed(fix);
  };

  useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <section className="assetperform">
      <div className="absolute top-[90px] left-0 w-screen">
        <div className="w-full max-w-[1920px] max-h-[280px] overflow-hidden mx-auto">
          <img
            src={require("./img/financeCover.png")}
            alt="cover"
            className="object-cover w-full"
          />
        </div>
      </div>
      <div className="mb-[50px] md:mb-[50px] mt-[350px]">
        <NavbarWpicture navitems={navitems} fixed={updatefixed} />
      </div>
      <div>
        <h1
          className={`text-xl md:text-2xl font-cbold text-blue-950 mb-6 ${
            navFix ? "mt-[500px]" : ""
          }`}
        >
          <Trans i18nKey="mda.header">Management Discussion and Analysis</Trans>
        </h1>
        <div className="flex gap-2 mb-6">
          <label htmlFor="selectBox" className="block text-gray-700">
            <Trans i18nKey="mda.year">Year:</Trans>
          </label>
          <select
            id="selectBox"
            name="selectBox"
            onChange={handleSelectChange}
            className="appearance-none w-[100px] pl-2 border-b-2 border-gold rounded-none rounded-se-md focus:outline-none focus:ring focus:ring-gold/60 focus:ring-offset-1"
          >
            <option value="2024">2024</option>
            <option value="2023">2023</option>
            <option value="2022">2022</option>
            <option value="2021">2021</option>
            <option value="2020">2020</option>
            <option value="2019">2019</option>
            <option value="2017">2017</option>
          </select>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
            />
          </svg>
        </div>
      </div>
      <div className="py-4 grid grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 md:gap-4">
        {data.map((val, i) => (
          <div className="w-full shadow-lg h-[200px] p-3 justify-between flex flex-col items-center bg-white rounded-md">
            <div className="text-stone-300 text-[50px]">
              <FaRegFilePdf />
            </div>
            <div className="text-blue-900">{val.mdTitle}</div>
            <div className="text-stone-300 border-t w-full pt-3">
              <a
                href={val.mdUrl}
                download={val.mdName}
                className="hover:text-blue-900"
              >
                <div className="flex gap-2 items-center justify-center">
                  <BsCloudDownload />{" "}
                  <Trans i18nKey="mda.download">Download File</Trans>
                </div>
              </a>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
