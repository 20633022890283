import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useTranslation, Trans } from "react-i18next";

export default function FinancialPosition(props) {
  const { i18n } = useTranslation();

  const [data, setData] = useState([]);

  const populateREITData = async () => {
    const response = await Axios.get(
      process.env.REACT_APP_API_URI + "/Financial/GetFinancialPosition"
    ).then((res) => {
      const data = res.data;
      setData(data);
    });
  };

  useEffect(() => {
    populateREITData();
  }, [i18n.language]);

  return (
    <table className="w-full table-fixed border-collapse">
      <tbody>
        <tr className="font-cbold bg-neutral-100 h-[40px]">
          <td className="w-1/2">
            <Trans i18nKey="REITPerformance.Topic2">
              Financial Position (Thousand Baht)
            </Trans>
          </td>
          {data.map((val, i) => (
            <td className="p-2 text-right"></td>
          ))}
        </tr>
        <tr className="border-b border-gray-100">
          <td className="w-1/2">
            <Trans i18nKey="REITPerformance.Finance16">
              Investment Properties
            </Trans>
          </td>
          {data.map((val, i) => (
            <td className="p-2 text-right w-[10%]">
              {val.investmentProperties}
            </td>
          ))}
        </tr>
        <tr className="border-b border-gray-100">
          <td className="w-1/2">
            <Trans i18nKey="REITPerformance.Finance17">Other assets</Trans>
          </td>
          {data.map((val, i) => (
            <td className="p-2 text-right">{val.otherAssets}</td>
          ))}
        </tr>
        <tr className="border-b border-gray-100">
          <td className="w-1/2">
            <Trans i18nKey="REITPerformance.Finance18">Total assets</Trans>
          </td>
          {data.map((val, i) => (
            <td className="p-2 text-right">{val.totalAssets}</td>
          ))}
        </tr>
        <tr className="border-b border-gray-100">
          <td className="w-1/2">
            <Trans i18nKey="REITPerformance.Finance19">Long-term loans</Trans>
          </td>
          {data.map((val, i) => (
            <td className="p-2 text-right">{val.longTermLoans}</td>
          ))}
        </tr>
        <tr className="border-b border-gray-100">
          <td className="w-1/2">
            <Trans i18nKey="REITPerformance.Finance20">
              Other payable and accrued expenses
            </Trans>
          </td>
          {data.map((val, i) => (
            <td className="p-2 text-right">
              {val.otherPayableAndAccruedExpenses}
            </td>
          ))}
        </tr>
        <tr className="border-b border-gray-100">
          <td className="w-1/2">
            <Trans i18nKey="REITPerformance.Finance21">Total liabilities</Trans>
          </td>
          {data.map((val, i) => (
            <td className="p-2 text-right">{val.totalLiabilities}</td>
          ))}
        </tr>
        <tr className="border-b border-gray-100">
          <td className="w-1/2">
            <Trans i18nKey="REITPerformance.Finance22">Net assets</Trans>
          </td>
          {data.map((val, i) => (
            <td className="p-2 text-right">{val.netAssets}</td>
          ))}
        </tr>
      </tbody>
    </table>
  );
}
