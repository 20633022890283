import React from "react";

import Navbar from "../components/navbar";

import ComFact from "./cominfo/comfact";
import ComContact from "./cominfo/comcontact";
import { useTranslation, Trans } from "react-i18next";

export default function ComInfo(props) {
  const { i18n } = useTranslation();
  const navitems = [
    {
      title: "Company Information",
      title_th: "ผู้จัดการกองทรัสต์",
      active: true,
      link: "/Management/REIT_Manager/Company_Information",
    },
    {
      title: "Organization Structure",
      title_th: "โครงสร้างองค์กร",
      active: false,
      link: "/Management/REIT_Manager/Organization_Structure",
    },
    {
      title: "Board of Directors",
      title_th: "คณะกรรมการบริษัท",
      active: false,
      link: "/Management/REIT_Manager/Board_of_Directors",
    },
  ];
  return (
    <section className="company-info">
      <div className="mb-[150px] md:mb-[170px]">
        <Navbar navitems={navitems} />
      </div>
      <div>
        <h1 className="text-xl md:text-2xl font-cbold text-blue-950 pb-4">
          <Trans i18nKey="CompanyInformation.Topic">
            Dusit Thani Properties REIT Co., Ltd., the REIT Manager
          </Trans>
        </h1>
        <p className="mb-4 md:text-lg">
          <Trans i18nKey="CompanyInformation.Desc1">
            is a registered company in Thailand, which was established on 21
            August 2517 with registered paid-up capital of 10,000,000 Baht,
            consisting of common stock of 100,000 shares at Par value of 100
            Baht.
          </Trans>
        </p>
        <p className="md:text-lg">
          <Trans i18nKey="CompanyInformation.Desc2">
            Dusit Thani Properties REIT Co., Ltd. has Dusit Thani Public Company
            Limited as a major shareholder who holds for 99.99% of total paid-up
            capital. Dusit Thani Properties REIT Co., Ltd. obtained the approval
            from the Office of the SEC to be the REIT Manager on 31 October 2017
            with main objectives to specifically operate and manage the Trust.
          </Trans>
        </p>
      </div>
      <div className="flex flex-col items-center lg:flex-row gap-6 mt-6 lg:justify-between py-6">
        <ComFact />
        <ComContact />
      </div>
    </section>
  );
}
