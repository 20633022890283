import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useTranslation, Trans } from "react-i18next";
import { FaRegFilePdf } from "react-icons/fa6";
import { BsCloudDownload } from "react-icons/bs";

import NavbarWpicture from "../components/navbarWpicture";

export default function Appraisal(props) {
  const { i18n } = useTranslation();
  const navitems = [
    {
      title: "REIT Performance",
      title_th: "ผลการดำเนินงานของกองทุน",
      active: false,
      link: "/Investor_Relations/Financial_Information/REIT_Performance",
    },
    {
      title: "Asset Performance",
      title_th: "ผลการดำเนินงานของอสังหาริมทรัพย์",
      active: false,
      link: "/Investor_Relations/Financial_Information/Asset_Performace",
    },
    {
      title: "Financial Statement",
      title_th: "งบการเงิน",
      active: false,
      link: "/Investor_Relations/Financial_Information/Financial_Statement",
    },
    {
      title: "Management Discussion and Analysis",
      title_th: "คำอธิบายและการวิเคราะห์ของฝ่ายจัดการ",
      active: false,
      link: "/Investor_Relations/Financial_Information/Management_Discussion_and_Analysis",
    },
    {
      title: "Appraisal Report",
      title_th: "รายงานประเมินมูลค่าทรัพย์สิน",
      active: true,
      link: "/Investor_Relations/Financial_Information/Appraisal_Report",
    },
    {
      title: "Unit NAV",
      title_th: "มูลค่าสินทรัพย์สุทธิ(NAV)",
      active: false,
      link: "/Investor_Relations/Financial_Information/Unit_NAV",
    },
  ];

  const [data, setData] = useState([]);

  const populateAppraisalData = async () => {
    const response = await Axios.get(
      process.env.REACT_APP_API_URI +
        "/Financial/GetAppraisalReport?lng=" +
        i18n.language
    ).then((res) => {
      const data = res.data;
      setData(data);
    });
  };

  useEffect(() => {
    populateAppraisalData();
  }, [i18n.language]);

  const [navFix, setFixed] = useState(false);

  const updatefixed = (fix) => {
    setFixed(fix);
  };

  return (
    <section className="assetperform">
      <div className="absolute top-[90px] left-0 w-screen">
        <div className="w-full max-w-[1920px] max-h-[280px] overflow-hidden mx-auto">
          <img
            src={require("./img/financeCover.png")}
            alt="cover"
            className="object-cover w-full"
          />
        </div>
      </div>
      <div className="mb-[50px] md:mb-[50px] mt-[350px]">
        <NavbarWpicture navitems={navitems} fixed={updatefixed} />
      </div>
      <div>
        <h1
          className={`text-xl md:text-2xl font-cbold text-blue-950 mb-6 ${
            navFix ? "mt-[500px]" : ""
          }`}
        >
          <Trans i18nKey="appraisal.header">Appraisal Report</Trans>
        </h1>
        <div className="py-4 grid grid-cols-1 gap-4 md:grid-cols-2">
          {data.map((val, i) => (
            <div className="w-full shadow-lg h-[200px] p-3 justify-between flex flex-col sm:flex-row sm:items-center gap-3 bg-white rounded-ee-md rounded-ss-md hover:bg-blue-900 hover:text-white hover:border-2 hover:border-gold">
              <div>
                <div className="font-cbold">{val.arDesc}</div>
                <div className="text-stone-400">{val.arTitle}</div>
              </div>
              <div className="py-3 px-6 md:px-4 lg:px-6 w-full sm:w-fit whitespace-nowrap border border-blue-900 bg-blue-900 text-white hover:text-blue-900 hover:bg-white">
                <a href={val.arUrl} download={val.arName} target="_blank">
                  <div className="flex gap-2 items-center justify-center">
                    <BsCloudDownload />{" "}
                    <Trans i18nKey="appraisal.download">Download File</Trans>
                  </div>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
