import React, { useEffect, useRef } from "react";
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net";

export default function DataTable({ data, dataColumn, dataDefs, dataOrder }) {
  const tableRef = useRef(null);

  useEffect(() => {
    let table;
    if (data) {
      if (tableRef.current && data.length > 0) {
        table = $(tableRef.current).DataTable({
          data: data,
          columns: dataColumn,
          columnDefs: dataDefs,
          dom: '<"top"f>rt<"bottom"p><"clear">',
          lengthMenu: [10, 50, 100, 1000], // Set the available options for entries per page
          pageLength: 10,
          order: dataOrder,
        });
      }
      // Destroy the DataTable instance when the component unmounts
      return () => {
        if (table) {
          table.destroy();
        }
      };
    }
  }, [data, dataColumn, dataDefs, dataOrder]);

  return (
    <div className="w-full my-8">
      <table
        ref={tableRef}
        className="text-xs md:text-base lg:text-base stripe hover no-footer dataTable"
        style={{ width: "100%" }}
      >
        <thead>
          <tr className="font-cbold text-blue-950 bg-neutral-50 border-b-4 border-gold">
            {dataColumn.map((column, index) => (
                <th key={index} className={`${column.className}`}>{column.title}</th>
            ))}
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>
  );
}
