import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { useCookies } from "react-cookie";

import AppRoutes from "./AppRoutes";
import AppRoutesIR from "./AppRoutesIR";
import Layout from "./components/Layout";
import LayoutBC from "./components/LayoutWbc";
import Home from "./Home/Home";
import "tw-elements-react/dist/css/tw-elements-react.min.css";
import "./custom.css";

export default function App(props) {
  const [cookies, setCookie, removeCookie] = useCookies([]);

  const Xversion = cookies.x_client_version;
  const version = process.env.REACT_APP_VERSION;

  if ("caches" in window) {
    if (Xversion !== version) {
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach((name) => {
          caches.delete(name);
        });
      });
      setCookie("x_client_version", version);
      window.location.reload(true);
    }
  }

  return (
    <Suspense fallback="Loading...">
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Home />} />

          {AppRoutes.map((route, i) => (
            <Route
              key={i}
              element={<LayoutBC path={route.path} pathth={route.pathth} />}
            >
              <Route path={route.path} element={route.element} />
            </Route>
          ))}
          {AppRoutesIR.map((route, i) => (
            <Route
              key={i}
              element={<LayoutBC path={route.path} pathth={route.pathth} />}
            >
              <Route path={route.path} element={route.element} />
            </Route>
          ))}
        </Route>
      </Routes>
    </Suspense>
  );
}
