import React, { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useOutletContext } from "react-router-dom";

export default function Navbar(props) {
  const { i18n } = useTranslation();
  const [nav, setNav] = useState([]);

  const bheight = useOutletContext();

  useEffect(() => {
    setNav(props.navitems);
  }, [props.navitems]);

  useEffect(() => {
    if (nav.filter((n) => n.active == true)[0] != undefined) {
      document.title =
        nav.filter((n) => n.active == true)[0].title +
        " | Dusit Thani Properties REIT (DREIT)";
    }
  }, [nav]);

  return (
    <div
      className={`container max-w-[1400px] fixed bg-white z-50 overflow-auto`}
      style={{ top: `${60 + bheight}px` }}
    >
      <div className="flex gap-5 border-b-2">
        {nav.map((n, i) => (
          <div
            key={i}
            className={`${
              n.active
                ? "text-gold border-b-2 border-gold font-cbold"
                : "text-neutral-500"
            } py-3 px-2 hover:text-gold hover:border-b-2 hover:border-gold`}
          >
            <a
              href={n.link}
              className={`${
                n.active ? "cursor-default" : "cursor-pointer"
              } text-sm md:text-base`}
            >
              {i18n.language == "en"
                ? n.title
                : n.title_th != undefined
                ? n.title_th
                : n.title}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}
