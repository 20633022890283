import React, { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import Axios from "axios";

import { RiRefund2Line, RiMoneyDollarBoxLine } from "react-icons/ri";
import { CiCalendarDate } from "react-icons/ci";
import { IoLocationOutline } from "react-icons/io5";
import { BsBuildings } from "react-icons/bs";
import { TiInfoLargeOutline } from "react-icons/ti";

import ModalPic from "./modalPic";

export default function HHexpand(props) {
  const { i18n } = useTranslation();
  const [expic, setExpic] = useState([]);

  const getPic = async () => {
    const response = await Axios.get(
      process.env.REACT_APP_API_URI + "/AssetInfo/GetExpansion"
    ).then((res) => {
      const s = res.data;
      const s_sort = s.sort((a, b) => b.rank - a.rank);
      setExpic(s_sort);
    });
  };

  useEffect(() => {
    getPic();
  }, []);

  useEffect(() => {
    console.log(expic);
  }, [expic]);

  const [modalShow, setModalShow] = useState(false);

  const modalClose = () => {
    setModalShow(false);
  };

  const [ix, setIx] = useState(0);

  const showModal = (i) => {
    setIx(i);
    setModalShow(true);
  };

  return (
    <div className="mt-8">
      <h2 className="text-xl md:text-3xl font-cbold text-blue-950 pb-4">
        <Trans i18nKey="Huahin.expand">Expansion</Trans>
      </h2>
      <h2 className="text-lg md:text-2xl font-cbold text-gold pb-4">
        <Trans i18nKey="Huahin.subexpand">Sub Expansion</Trans>
      </h2>
      <div>
        <p>
          <Trans i18nKey="Huahin.exdetail">Expansion detail</Trans>
        </p>
      </div>

      <div className="grid grid-cols-1 max-lg:gap-4 lg:grid-cols-2 my-4">
        {/*-------- section investment----------*/}
        <div className="grid grid-col-1 md:grid-cols-3 lg:py-20">
          <div className="flex flex-col justify-center items-center max-md:border-b md:border-r py-4">
            <div className="text-gold text-3xl">
              <RiRefund2Line />
            </div>
            <h6 className="text-zinc-800 font-cbold">
              <Trans i18nKey="Huahin.date">Expansion date</Trans>
            </h6>
            <p className="text-neutral-500">
              <Trans i18nKey="Huahin.datedetail">Expansion date</Trans>
            </p>
          </div>

          <div className="flex flex-col justify-center items-center max-md:border-b md:border-r py-4">
            <div className="text-gold text-3xl">
              <CiCalendarDate />
            </div>
            <h6 className="text-zinc-800 font-cbold">
              <Trans i18nKey="Huahin.operate">Operate date</Trans>
            </h6>
            <p className="text-neutral-500">
              <Trans i18nKey="Huahin.operatedetail">Operate date</Trans>
            </p>
          </div>

          <div className="flex flex-col justify-center items-center py-4">
            <div className="text-gold text-3xl">
              <RiMoneyDollarBoxLine />
            </div>
            <h6 className="text-zinc-800 font-cbold">
              <Trans i18nKey="Huahin.value">Value</Trans>
            </h6>
            <p className="text-neutral-500">
              <Trans i18nKey="Huahin.valuedetail">Value detail</Trans>
            </p>
          </div>
        </div>
        {/*-------- section investment end----------*/}
        {/*-------- section detail ----------*/}
        <div className="flex flex-col divide-y p-6 bg-zinc-100">
          <div className="flex gap-2 md:gap-6 pb-4 flex-col md:flex-row">
            <div className="flex gap-2 w-60">
              <div className="text-2xl text-blue-950">
                <IoLocationOutline />
              </div>
              <h6 className="font-cbold text-blue-950">
                <Trans i18nKey="Huahin.loc">Location</Trans>
              </h6>
            </div>
            <div className="w-full">
              <p>
                <Trans i18nKey="Huahin.locdetail">Location</Trans>
              </p>
            </div>
          </div>

          <div className="flex gap-2 md:gap-6 pb-4 flex-col md:flex-row pt-4">
            <div className="flex gap-2 w-60">
              <div className="text-2xl text-blue-950">
                <BsBuildings />
              </div>
              <h6 className="font-cbold text-blue-950">
                <Trans i18nKey="Huahin.type">Investment Type</Trans>
              </h6>
            </div>
            <div className="w-full">
              <p>
                <Trans i18nKey="Huahin.typedetail">Investment Type</Trans>
              </p>
            </div>
          </div>

          <div className="flex gap-2 md:gap-6 pb-4 flex-col md:flex-row pt-4">
            <div className="flex gap-2 w-60">
              <div className="text-2xl text-blue-950">
                <TiInfoLargeOutline />
              </div>
              <h6 className="font-cbold text-blue-950">
                <Trans i18nKey="Huahin.prop">detail</Trans>
              </h6>
            </div>
            <div className="w-full">
              <p>
                <Trans i18nKey="Huahin.propdetail">detail</Trans>
              </p>
            </div>
          </div>
        </div>
        {/*-------- section detail end ----------*/}
      </div>
      <div>
        <h2 className="text-lg md:text-2xl font-cbold text-gold pb-4">
          <Trans i18nKey="Huahin.exppic">Expansion Pictures</Trans>
        </h2>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
        {expic.map((e, i) => (
          <div className="group relative" onClick={() => showModal(i)}>
            <img
              src={
                "https://dreit.blob.core.windows.net/assetinfo/" + e.fileName
              }
              alt={e.fileName}
              className="object-cover w-full h-full"
            />
            <div className="group-hover:bg-black group-hover:bg-opacity-50 hidden w-full h-full absolute top-0 left-0 group-hover:flex justify-center items-center text-white transition duration-500">
              {i18n.language == "en" ? "click for enlarge" : "คลิกดูรูปใหญ่"}
            </div>
          </div>
        ))}
      </div>
      <ModalPic show={modalShow} onHide={modalClose} source={expic} pos={ix} />
    </div>
  );
}
