import React, { useEffect, useState, useRef } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useOutletContext } from "react-router-dom";

export default function NavbarWpicture(props) {
  const { i18n } = useTranslation();
  const [nav, setNav] = useState([]);
  const bheight = useOutletContext();

  useEffect(() => {
    setNav(props.navitems);
  }, [props.navitems]);

  useEffect(() => {
    if (nav.filter((n) => n.active == true)[0] != undefined) {
      document.title =
        nav.filter((n) => n.active == true)[0].title +
        " | Dusit Thani Properties REIT (DREIT)";
    }
  }, [nav]);

  const [navPos, setNavPos] = useState(0);

  const refPos = useRef();

  useEffect(() => {
    setNavPos(refPos.current.offsetTop);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", scrollYfixed);
    return () => {
      window.removeEventListener("scroll", scrollYfixed);
    };
  });

  const [fixedNav, setFixedNav] = useState(false);

  const scrollYfixed = () => {
    let y = navPos - 93;
    if (window.scrollY < y) {
      setFixedNav(false);
    } else {
      setFixedNav(true);
    }
  };

  useEffect(() => {
    props.fixed(fixedNav);
  }, [fixedNav]);

  return (
    <div
      ref={refPos}
      className={`container max-w-[1400px] ${
        fixedNav ? "fixed z-[99]" : ""
      } bg-white pt-3 overflow-y-auto`}
      style={{ top: `${60 + bheight}px` }}
    >
      <div className="flex gap-5 border-b-2">
        {nav.map((n, i) => (
          <div
            key={i}
            className={`${
              n.active
                ? "text-gold border-b-2 border-gold font-cbold"
                : "text-neutral-500"
            } py-3 px-2 hover:text-gold hover:border-b-2 hover:border-gold`}
          >
            <a
              href={n.link}
              className={`${
                n.active ? "cursor-default" : "cursor-pointer"
              } text-sm md:text-base `}
            >
              {i18n.language == "en"
                ? n.title
                : n.title_th != undefined
                ? n.title_th
                : n.title}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}
