import { VscGraph } from "react-icons/vsc";
import { HiOutlineDocumentText, HiOutlineNewspaper } from "react-icons/hi2";
import { BiAnalyse } from "react-icons/bi";
import {
  TbMessageReport,
  TbAdjustmentsCode,
  TbRotateDot,
  TbTimelineEventMinus,
} from "react-icons/tb";
import {
  AiOutlineStock,
  AiOutlineNotification,
  AiOutlineDeploymentUnit,
  AiOutlineQuestionCircle,
} from "react-icons/ai";
import { RiStockLine, RiPresentationLine } from "react-icons/ri";
import { IoPeopleOutline } from "react-icons/io5";
import { DiStackoverflow } from "react-icons/di";
import {
  MdOutlineSwitchAccessShortcutAdd,
  MdFormatListBulleted,
  MdAutoGraph,
} from "react-icons/md";
import { LiaFileContractSolid } from "react-icons/lia";
import { TiInfoLargeOutline } from "react-icons/ti";

const NavLocIR = [
  {
    title: "Financial Information",
    title_th: "ข้อมูลทางการเงิน",
    url: "/Investor_Relations/Financial_Information/REIT_Performance",
    active: true,
    submenu: [
      {
        icon: <MdAutoGraph />,
        title: "REIT Performance",
        title_th: "ผลการดำเนินงานของกองทุน",
        url: "/Investor_Relations/Financial_Information/REIT_Performance",
      },
      {
        icon: <VscGraph />,
        title: "Asset Performance",
        title_th: "ผลการดำเนินงานของอสังหาริมทรัพย์",
        url: "/Investor_Relations/Financial_Information/Asset_Performace",
      },
      {
        icon: <HiOutlineDocumentText />,
        title: "Financial Statement",
        title_th: "งบการเงิน",
        url: "/Investor_Relations/Financial_Information/Financial_Statement",
      },
      {
        icon: <BiAnalyse />,
        title: "Management Discussion and Analysis",
        title_th: "คำอธิบายและการวิเคราะห์ของฝ่ายจัดการ",
        url: "/Investor_Relations/Financial_Information/Management_Discussion_and_Analysis",
      },
      {
        icon: <TbMessageReport />,
        title: "Appraisal Report",
        title_th: "รายงานประเมินมูลค่าทรัพย์สิน",
        url: "/Investor_Relations/Financial_Information/Appraisal_Report",
      },
      {
        icon: <TbAdjustmentsCode />,
        title: "Unit NAV",
        title_th: "มูลค่าสินทรัพย์สุทธิ (NAV)",
        url: "/Investor_Relations/Financial_Information/Unit_NAV",
      },
    ],
  },
  {
    title: "Stock Information",
    title_th: "ข้อมูลราคาหลักทรัพย์",
    url: "/Investor_Relations/Stock_Information/Stock_Quotes",
    active: false,
    submenu: [
      {
        icon: <RiStockLine />,
        title: "Stock Quotes",
        title_th: "ราคาหลักทรัพย์",
        url: "/Investor_Relations/Stock_Information/Stock_Quotes",
      },
      {
        icon: <AiOutlineStock />,
        title: "Historical Price",
        title_th: "ราคาย้อนหลัง",
        url: "/Investor_Relations/Stock_Information/Historical_Price",
      },
    ],
  },
  {
    title: "Unitholder Information",
    title_th: "ข้อมูลผู้ถือหน่วยทรัสต์",
    url: "/Investor_Relations/Unitholders_Information/List_of_Major_Unitholders",
    active: false,
    submenu: [
      {
        icon: <IoPeopleOutline />,
        title: "List of Major Unitholders",
        title_th: "รายชื่อผู้ถือหน่วยทรัสต์",
        url: "/Investor_Relations/Unitholders_Information/List_of_Major_Unitholders",
      },
      {
        icon: <AiOutlineDeploymentUnit />,
        title: "Shareholder Meeting",
        title_th: "การประชุมผู้ถือหน่วยทรัสต์",
        url: "/Investor_Relations/Unitholders_Information/Shareholder_Meeting",
      },
      {
        icon: <DiStackoverflow />,
        title: "Distribution Information",
        title_th: "การจ่ายผลตอบแทน",
        url: "/Investor_Relations/Unitholders_Information/Distribution_Information",
      },
      {
        icon: <TbRotateDot />,
        title: "Distribution on Reduction Capital",
        title_th: "การจ่ายเงินลดทุน",
        url: "/Investor_Relations/Unitholders_Information/Distribution_on_Reduction_Capital",
      },
      {
        icon: <MdOutlineSwitchAccessShortcutAdd />,
        title: "Allotment of Additional Trust Units",
        title_th: "การจัดสรรหน่วยทรัสต์เพิ่มเติม",
        url: "/Investor_Relations/Unitholders_Information/Allotment_of_Additional_Trust_Units",
      },
    ],
  },
  {
    title: "Publication & Download",
    title_th: "เอกสารสำคัญเพื่อการเผยแพร่",
    url: "/Investor_Relations/Publication_&_Download/Annual_Report",
    active: false,
    submenu: [
      {
        icon: <LiaFileContractSolid />,
        title: "Annual Report",
        title_th: "รายงานประจำปี",
        url: "/Investor_Relations/Publication_&_Download/Annual_Report",
      },
      {
        icon: <MdFormatListBulleted />,
        title: "Form 56-REIT",
        title_th: "แบบฟอร์ม 56-REIT",
        url: "/Investor_Relations/Publication_&_Download/Form_56-REIT",
      },
      {
        icon: <TiInfoLargeOutline />,
        title: "Prospectus",
        title_th: "หนังสือชื้ชวน",
        url: "/Investor_Relations/Publication_&_Download/Prospectus",
      },
    ],
  },
  {
    title: "Webcast & Presentation",
    title_th: "เว็บแคสต์และเอกสารนำเสนอ",
    url: "/Investor_Relations/Webcast_&_Presentation",
    active: false,
    submenu: [
      {
        icon: <RiPresentationLine />,
        title: "Webcast & Presentation",
        title_th: "เว็บแคสต์และเอกสารนำเสนอ",
        url: "/Investor_Relations/Webcast_&_Presentation",
      },
    ],
  },
  {
    title: "Newsroom",
    title_th: "ห้องข่าว",
    url: "/Investor_Relations/Newsroom/SET_Announcements",
    active: false,
    submenu: [
      {
        icon: <AiOutlineNotification />,
        title: "SET Announcements",
        title_th: "ข่าวแจ้งตลาดหลักทรัพย์",
        url: "/Investor_Relations/Newsroom/SET_Announcements",
      },
      {
        icon: <HiOutlineNewspaper />,
        title: "Press Releases",
        title_th: "ข่าวแจ้งสื่อมวลชน",
        url: "/Investor_Relations/Newsroom/Press_Releases",
      },
      {
        icon: <TbTimelineEventMinus />,
        title: "News & Events",
        title_th: "ข่าวสารและกิจกรรม",
        url: "/Investor_Relations/Newsroom/News_&_Events",
      },
    ],
  },
  {
    title: "Frequently Asked Questions",
    title_th: "คำถามที่พบบ่อย",
    url: "/faq",
    active: false,
    submenu: [
      {
        icon: <AiOutlineQuestionCircle />,
        title: "Frequently Asked Questions",
        title_th: "คำถามที่พบบ่อย",
        url: "/faq",
      },
    ],
  },
];

export default NavLocIR;
