import Overview from "./aboutus/overview";
import Trust from "./aboutus/truststructure";
import ComInfo from "./management/cominfo";
import Lessee from "./management/lessee";
import OrganizeStructure from "./management/organization";
import Hotel from "./management/hotel";
import Trustee from "./management/trustee";
import Board from "./management/board";
import Phuket from "./assetInfo/phuket";
import Huahin from "./assetInfo/huahin";
import Maldives from "./assetInfo/maldive";
import Contactus from "./contact/contactus";
import Term from "./term/term";
import Cookie from "./term/cookie";
import Privacy from "./term/privacy";
import Faq from "./faq/faq";

const AppRoutes = [
  {
    path: "/About_Us/Overview",
    pathth: "/เกี่ยวกับเรา/ข้อมูลทั่วไป",
    element: <Overview />,
  },
  {
    path: "/About_Us/Trust_Structures",
    pathth: "/เกี่ยวกับเรา/โครงสร้างกองทรัสต์",
    element: <Trust />,
  },
  {
    path: "/Management/REIT_Manager/Company_Information",
    pathth: "/การบริหารจัดการ/ผู้จัดการกองทรัสต์/ข้อมูลบริษัท",
    element: <ComInfo />,
  },
  {
    path: "/Management/REIT_Manager/Organization_Structure",
    pathth: "/การบริหารจัดการ/ผู้จัดการกองทรัสต์/โครงสร้างองค์กร",
    element: <OrganizeStructure />,
  },
  {
    path: "/Management/REIT_Manager/Board_of_Directors",
    pathth: "/การบริหารจัดการ/ผู้จัดการกองทรัสต์/คณะกรรมการบริษัท",
    element: <Board />,
  },
  {
    path: "/Management/Lessee%2F_Sub-Lessee_and_Hotel_Operator/Lessee%2F_Sub-Lessee",
    pathth:
      "/การบริหารจัดการ/ผู้เช่า%2Fผู้เช่าช่วง_และผู้บริหารโรงแรม/ผู้เช่าและผู้เช่าช่วงทรัพย์สิน",
    element: <Lessee />,
  },
  {
    path: "/Management/Lessee%2F_Sub-Lessee_and_Hotel_Operator/Hotel_Operator",
    pathth:
      "/การบริหารจัดการ/ผู้เช่า%2Fผู้เช่าช่วง_และผู้บริหารโรงแรม/ผู้บริหารทรัพย์สิน",
    element: <Hotel />,
  },
  {
    path: "/Management/Trustee_Inforamtion/General_Information",
    pathth: "/การบริหารจัดการ/ทรัสตี/ข้อมูลบริษัท",
    element: <Trustee />,
  },
  {
    path: "/Management/Asset_Infomation/Laguna_Phuket_Hotel",
    pathth:
      "/การบริหารจัดการ/อสังหาริมทรัพย์ที่ลงทุน/โครงการดุสิตธานี_ลากูนา_ภูเก็ต",
    element: <Phuket />,
  },
  {
    path: "/Management/Asset_Infomation/Hua_Hin_Hotel",
    pathth: "/การบริหารจัดการ/อสังหาริมทรัพย์ที่ลงทุน/โครงการดุสิตธานี_หัวหิน",
    element: <Huahin />,
  },
  {
    path: "/Management/Asset_Infomation/Maldives_Hotel",
    pathth:
      "/การบริหารจัดการ/อสังหาริมทรัพย์ที่ลงทุน/โครงการดุสิตธานี_มัลดีฟส์",
    element: <Maldives />,
  },
  {
    path: "/Contact_Us",
    pathth: "/ติดต่อเรา",
    element: <Contactus />,
  },
  {
    path: "/term_and_condition",
    pathth: "/เงื่อนไขการใช้บริการ",
    element: <Term />,
  },
  {
    path: "/cookie_policy",
    pathth: "/นโยบายคุกกี้",
    element: <Cookie />,
  },
  {
    path: "/privacy_policy",
    pathth: "/นโยบายความเป็นส่วนตัว",
    element: <Privacy />,
  },
  {
    path: "/faq",
    pathth: "/คำถามที่พบบ่อย",
    element: <Faq />,
  },
];

export default AppRoutes;
