import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Breadcrumb from "./breadcrumb";
export default function LayoutBC(props) {
  const [bheight, setBheight] = useState(0);

  const getHeight = (h) => {
    setBheight(h);
  };

  return (
    <div>
      <Breadcrumb path={props.path} pathth={props.pathth} height={getHeight} />
      <div className="container max-w-[1400px] px-2 md:px-6 py-10">
        <Outlet context={bheight} />
      </div>
    </div>
  );
}
