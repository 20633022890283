import React from "react";
import { useTranslation, Trans } from "react-i18next";

import Message from "./message";
import ConInfo from "./info";

export default function Contactus(props) {
  const { i18n } = useTranslation();

  return (
    <section className="contactus">
      <div className="flex max-md:flex-col w-full pt-[100px]">
        <Message />
        <ConInfo />
      </div>
    </section>
  );
}
