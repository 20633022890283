import React, { useEffect, useState, useRef } from "react";
import { useTranslation, Trans } from "react-i18next";

export default function Breadcrumb(props) {
  const [path, setPath] = useState([]);
  const { i18n } = useTranslation();
  const ref = useRef(null);

  useEffect(() => {
    let strpath =
      i18n.language == "en"
        ? props.path.replaceAll("_", " ")
        : props.pathth != undefined
        ? props.pathth.replaceAll("_", " ")
        : props.path.replaceAll("_", " ");
    strpath = strpath.split("/");
    strpath = strpath.filter((a, i) => i != 0);
    if (props.path.indexOf("Management")) {
      strpath = strpath.map((s) => {
        return s.replaceAll("%2F", "/");
      });
    }
    setPath(strpath);
  }, [props.path, i18n.language]);

  const [height, setHeight] = useState(0);
  useEffect(() => {
    setHeight(ref.current.clientHeight);
  });

  useEffect(() => {
    props.height(height);
  }, [height]);

  return (
    <section
      ref={ref}
      className="breadcrumb w-full fixed top-[60px] bg-white z-[900]"
    >
      <div className="w-full bg-gradient-to-r from-gold to-transparent relative">
        <div className="flex gap-2 max-w-[1400px] mx-auto px-6 py-1 max-md:text-sm flex-wrap">
          <div>
            <a href="/" className="text-white">
              {i18n.language == "en" ? "Home" : "หน้าแรก"}
            </a>
          </div>
          {path.map((p, i) => (
            <div className="flex gap-2 text-white" key={i}>
              <div>{">"}</div>
              <div className={`${i + 1 == path.length && "text-blue-950"}`}>
                {p}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
