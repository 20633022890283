import React from "react";

import Navbar from "../components/navbar";

import ComFact from "./hotel/comfact";
import ComContact from "./hotel/comcontact";
import { useTranslation, Trans } from "react-i18next";

export default function Hotel(props) {
  const { i18n } = useTranslation();
  const navitems = [
    {
      title: "Lessee/ Sub-Lessee",
      title_th: "ผู้เช่าและผู้เช่าช่วงทรัพย์สิน",
      active: false,
      link: "/Management/Lessee%2F_Sub-Lessee_and_Hotel_Operator/Lessee%2F_Sub-Lessee",
    },
    {
      title: "Hotel Operator",
      title_th: "ผู้บริหารทรัพย์สิน",
      active: true,
      link: "/Management/Lessee%2F_Sub-Lessee_and_Hotel_Operator/Hotel_Operator",
    },
  ];
  return (
    <section className="lessee-and-sub-lessee">
      <div className="mb-[150px] md:mb-[170px]">
        <Navbar navitems={navitems} />
      </div>
      <div>
        <h1 className="text-xl md:text-2xl font-cbold text-blue-950 pb-4">
          <Trans i18nKey="HotelOperator.Topic">Hotel Operator</Trans>
        </h1>
        <p className="mb-4 md:text-lg">
          <Trans i18nKey="HotelOperator.Desc1">
            Dusit Thani Public Company Limited (DTC) is one of the leading
            company in hotel industry of Thailand with over 40 years{"’"}{" "}
            experience in the hotel and hospitality fields and premium services
            qualified by international standard under the brand of “Dusit
            International”
          </Trans>
        </p>
        <p className="mb-4 md:text-lg">
          <Trans i18nKey="HotelOperator.Desc2">
            Presently hotels under the management agreement with DTC are
            totaling of 62 properties in 17 countries and franchising agreement
            in China totaling of 40 properties.
          </Trans>
        </p>
        <p className="mb-4 md:text-lg">
          <Trans i18nKey="HotelOperator.Desc3">
            DTC, the hotel operator of DREIT{"’"}s assets, was a registered
            company in Thailand which was established on 30 June 1993 with
            issued and paid-up capital of 850,000,000 Baht consisting of
            850,000,000 shares at Par value of 1 Baht per share. DTC will
            typically manage those three assets in which DREIT has invest.
          </Trans>
        </p>
        <p className="mb-4 md:text-lg">
          <Trans i18nKey="HotelOperator.Desc4">
            DTC will organize and manage the properties in accordance with
            policies and plans of the REIT Manager in order to complete the
            objectives of asset management. DTC has the key responsibilities
            such as seeking for commercial benefits from assets, coordinating
            and facilitate lessee or sublessee, collecting benefits for the
            Trust together with maintaining and renovating such asset, furniture
            and fixture and public utilities in a good condition for generating
            benefits. The Trust will be responsible for all maintenance
            expenses.
          </Trans>
        </p>
      </div>
      <div className="flex flex-col items-center lg:flex-row gap-6 mt-6 lg:justify-between py-6">
        <ComFact />
        <div className="order-first md:order-last w-full lg:w-1/2">
          <ComContact />
        </div>
      </div>
    </section>
  );
}
