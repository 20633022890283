import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useTranslation, Trans } from "react-i18next";
import PdfModal from "../components/PdfModal";
import NavbarWpicture from "../components/navbarWpicture";
import { ImFilePdf } from "react-icons/im";
import { BsCloudDownload } from "react-icons/bs";

export default function AnnualReport(props) {
  const { i18n } = useTranslation();
  const navitems = [
    {
      title: "Annual Report",
      title_th: "รายงานประจำปี",
      active: true,
      link: "/Investor_Relations/Publication_&_Download/Annual_Report",
    },
    {
      title: "Form 56-REIT",
      title_th: "แบบฟอร์ม 56-REIT",
      active: false,
      link: "/Investor_Relations/Publication_&_Download/Form_56-REIT",
    },
    {
      title: "Prospectus",
      title_th: "หนังสือชี้ชวน",
      active: false,
      link: "/Investor_Relations/Publication_&_Download/Prospectus",
    },
  ];

  const [showModal, setShowModal] = useState(false);
  const [pdfObj, setPdfObj] = useState(null);
  const [data, setData] = useState([]);

  const populateAnnualData = async () => {
    const response = await Axios.get(
      process.env.REACT_APP_API_URI +
        "/Publication/GetAnnualReport?lng=" +
        i18n.language
    ).then((res) => {
      const data = res.data;
      setData(data);
    });
  };

  const handleButtonClick = (title, url, blobName, fileName) => {
    // Set the PDF object here
    const newPdfObj = {
      title,
      url,
      blobName,
      fileName,
    };

    // Set both showModal and pdfObj state
    setShowModal(true);
    setPdfObj(newPdfObj);
  };

  useEffect(() => {
    populateAnnualData();
  }, [i18n.language]);

  const [navFix, setFixed] = useState(false);

  const updatefixed = (fix) => {
    setFixed(fix);
  };

  useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <section className="assetperform">
      <div className="absolute top-[90px] left-0 w-screen">
        <div className="w-full h-[280px] max-w-[1920px] overflow-hidden mx-auto">
          <img
            src={require("./img/publicCover.jpg")}
            alt="cover"
            className="object-cover w-full h-full object-center"
          />
        </div>
      </div>
      <div className="mb-[50px] md:mb-[50px] mt-[350px]">
        <NavbarWpicture navitems={navitems} fixed={updatefixed} />
      </div>
      <div>
        <h1
          className={`text-xl md:text-2xl font-cbold text-blue-950 mb-6 ${
            navFix ? "mt-[500px]" : ""
          }`}
        >
          <Trans i18nKey="AnnualReport.header">Annual Report</Trans>
        </h1>
        <div className="flex items-center flex-wrap">
          {data.map((item, i) => (
            <div key={i} className="w-1/2 md:w-1/3 lg:w-1/4 p-2">
              <div className="flex flex-col rounded-md bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] p-2">
                <img
                  className="w-full aspect-auto rounded-t-lg object-cover md:h-auto"
                  src={item.annualCoverUrl}
                  alt={item.annualName}
                />
                <div className="flex flex-col justify-start py-4">
                  <h5 className="mb-2 md:text-lg font-medium text-blue-950 text-center">
                    {item.annualName}
                  </h5>
                  <div className="flex justify-between">
                    <div className="w-full border-r cursor-pointer">
                      <a
                        download
                        onClick={() =>
                          handleButtonClick(
                            item.annualName,
                            item.annualPdfUrl,
                            item.annualBlobName,
                            item.annualFileName
                          )
                        }
                      >
                        <div className="flex gap-1 items-center text-stone-300 hover:text-blue-950 justify-center">
                          <ImFilePdf />
                          <div className="hidden sm:block md:max-lg:hidden">
                            <Trans i18nKey="AnnualReport.view">View PDF</Trans>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="w-full cursor-pointer">
                      <a href={item.annualPdfUrl} download target="_blank">
                        <div className="flex gap-1 items-center text-stone-300 hover:text-blue-950 w-full justify-center">
                          <BsCloudDownload />
                          <div className="hidden sm:block md:max-lg:hidden">
                            <Trans i18nKey="AnnualReport.download">
                              Download
                            </Trans>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <PdfModal
            pdfObj={pdfObj}
            isOpen={showModal}
            onClose={() => {
              document.body.style.overflow = "auto";
              setShowModal(false);
            }}
          />
        </div>
      </div>
    </section>
  );
}
