import { LiaBusinessTimeSolid } from "react-icons/lia";
import { LuFolderTree } from "react-icons/lu";
import { MdStackedBarChart } from "react-icons/md";
import { RiHotelLine } from "react-icons/ri";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { TbLetterP, TbLetterH, TbLetterM } from "react-icons/tb";

const NavLocManage = [
  {
    title: "About Us",
    title_th: "เกี่ยวกับเรา",
    url: "/About_Us/Overview",
    active: true,
    submenu: [
      {
        icon: <LiaBusinessTimeSolid />,
        title: "Overview",
        title_th: "ข้อมูลทั่วไป",
        url: "/About_Us/Overview",
      },
      {
        icon: <LuFolderTree />,
        title: "Trust Structure",
        title_th: "โครงสร้างกองทรัสต์",
        url: "/About_Us/Trust_Structures",
      },
    ],
  },
  {
    title: "Management",
    title_th: "การบริหารจัดการ",
    url: "/Management/REIT_Manager",
    active: false,
    submenu: [
      {
        icon: <MdStackedBarChart />,
        title: "REIT Manager",
        title_th: "ผู้จัดการกองทรัสต์",
        url: "/Management/REIT_Manager/Company_Information",
      },
      {
        icon: <RiHotelLine />,
        title: "Lessee/ Sub-Lessee and Hotel Operator",
        title_th: "ผู้เช่า/ ผู้เช่าช่วง และผู้บริหารโรงแรม",
        url: "/Management/Lessee%2F_Sub-Lessee_and_Hotel_Operator/Lessee%2F_Sub-Lessee",
      },
      {
        icon: <AiOutlineInfoCircle />,
        title: "Trustee Information",
        title_th: "ทรัสตี",
        url: "/Management/Trustee_Inforamtion/General_Information",
      },
    ],
  },
  {
    title: "Investment Assets",
    title_th: "อสังหาริมทรัพย์ที่ลงทุน",
    url: "/Management/Asset_Information",
    active: false,
    submenu: [
      {
        icon: <TbLetterP />,
        title: "Dusit Thani Laganu Phuket Hotel",
        title_th: "โครงการดุสิตธานี ลากูนา ภูเก็ต",
        url: "/Management/Asset_Infomation/Laguna_Phuket_Hotel",
      },
      {
        icon: <TbLetterH />,
        title: "Dusit Thani Hua Hin Hotel",
        title_th: "โครงการดุสิตธานี หัวหิน",
        url: "/Management/Asset_Infomation/Hua_Hin_Hotel",
      },
      {
        icon: <TbLetterM />,
        title: "Dusit Thani Maldives Hotel",
        title_th: "โครงการดุสิตธานี มัลดีฟส์",
        url: "/Management/Asset_Infomation/Maldives_Hotel",
      },
    ],
  },
];

export default NavLocManage;
