import React, { useEffect, useState } from "react";
import Axios from "axios";
import DataTable from "../components/DataTableGroup";
import { useTranslation, Trans } from "react-i18next";

import NavbarWpicture from "../components/navbarWpicture";

export default function Reduction(props) {
  const { i18n, t } = useTranslation();
  const navitems = [
    {
      title: "List of Major Unitholders",
      title_th: "รายชื่อผู้ถือหน่วยทรัสต์",
      active: false,
      link: "/Investor_Relations/Unitholders_Information/List_of_Major_Unitholders",
    },
    {
      title: "Shareholder Meeting and Operation Report",
      title_th: "การประชุมผู้ถือหน่วยทรัสต์และรายงานการดำเนินงาน",
      active: false,
      link: "/Investor_Relations/Unitholders_Information/Shareholder_Meeting",
    },
    {
      title: "Distribution Information",
      title_th: "การจ่ายผลตอบแทน",
      active: false,
      link: "/Investor_Relations/Unitholders_Information/Distribution_Information",
    },
    {
      title: "Distribution on Reduction Capital",
      title_th: "การจ่ายเงินลดทุน",
      active: true,
      link: "/Investor_Relations/Unitholders_Information/Distribution_on_Reduction_Capital",
    },
    {
      title: "Allotment of Additional Trust Units",
      title_th: "การจัดสรรหน่วยทรัสต์เพิ่มเติม",
      active: false,
      link: "/Investor_Relations/Unitholders_Information/Allotment_of_Additional_Trust_Units",
    },
  ];

  const [data, setData] = useState([]);

  const populateReductionData = async () => {
    const response = await Axios.get(
      process.env.REACT_APP_API_URI +
        "/Unitholder/GetDistributionOnReduction?lng=" +
        i18n.language
    ).then((res) => {
      const data = res.data;
      setData(data);
    });
  };

  const dataColumn = [
    {
      title: t("Reduction.XNDate"),
      data: "xnDate",
      orderable: false,
    },
    {
      title: t("Reduction.BookClosingDate"),
      data: "bookClosingDate",
      orderable: false,
    },
    {
      title: t("Reduction.PaymentDate"),
      data: "paymentDate",
      orderable: false,
    },
    {
      title: t("Reduction.CapitalReduction"),
      data: "capitalReductionPerShare",
      orderable: false,
    },
  ];

  const dataDefs = [
    {
      targets: 0, // Target the second column (index 1)
      width: "25%", // Set width to 50% for column 2
    },
    {
      targets: 1, // Target the second column (index 1)
      width: "25%", // Set width to 50% for column 2
    },
    {
      targets: 2, // Target the second column (index 1)
      width: "25%", // Set width to 50% for column 2
    },
    {
      targets: 3, // Target the second column (index 1)
      width: "25%", // Set width to 50% for column 2
    },
  ];

  const dataOrder = [];

  useEffect(() => {
    populateReductionData();
  }, [i18n.language]);

  const [navFix, setFixed] = useState(false);

  const updatefixed = (fix) => {
    setFixed(fix);
  };

  return (
    <section className="assetperform">
      <div className="absolute top-[90px] left-0 w-screen">
        <div className="w-full h-[280px] max-w-[1920px] overflow-hidden mx-auto">
          <img
            src={require("./img/unitholderCover.png")}
            alt="cover"
            className="object-cover w-full h-full object-center"
          />
        </div>
      </div>
      <div className="mb-[50px] md:mb-[50px] mt-[350px]">
        <NavbarWpicture navitems={navitems} fixed={updatefixed} />
      </div>
      <div>
        <h1
          className={`text-xl md:text-2xl font-cbold text-blue-950 mb-6 ${
            navFix ? "mt-[500px]" : ""
          }`}
        >
          <Trans i18nKey="Reduction.header">
            Distribution on Reduction Capital
          </Trans>
        </h1>
        <div className="table-performance">
          <DataTable
            data={data}
            dataColumn={dataColumn}
            dataDefs={dataDefs}
            dataOrder={dataOrder}
            dataGroup="distributionYear"
          />
        </div>
      </div>
    </section>
  );
}
