import React, { useEffect, useState } from "react";
import Axios from "axios";
import Chart from "react-google-charts";

import { useTranslation, Trans } from "react-i18next";
import NavbarWpicture from "../components/navbarWpicture";

export default function StockQuote(props) {
  const { i18n } = useTranslation();
  const navitems = [
    {
      title: "Stock Quotes",
      title_th: "ราคาหลักทรัพย์",
      active: true,
      link: "/Investor_Relations/Stock_Information/Stock_Quotes",
    },
    {
      title: "Historical Price",
      title_th: "ราคาย้อนหลัง",
      active: false,
      link: "/Investor_Relations/Stock_Information/Historical_Price",
    },
  ];

  const [data, setData] = useState([]);
  const [selectedDate, setDateData] = useState([]);
  const [chartdata, setChartData] = useState([]);

  const populateStockData = async () => {
    const response = await Axios.get(
      process.env.REACT_APP_API_URI + "/Stock/GetStock"
    ).then((res) => {
      const data = res.data;
      setData(data);
    });
  };

  const populateStockChartData = async () => {
    const response = await Axios.get(
      process.env.REACT_APP_API_URI +
        "/Stock/GetListStock?stDateType=" +
        selectedDate +
        "&lng=" +
        i18n.language
    ).then((res) => {
      const data = res.data;

      setChartData(data.reverse());
    });
  };

  useEffect(() => {
    populateStockData();
    populateStockChartData();
  }, [i18n.language, selectedDate]);

  const [navFix, setFixed] = useState(false);

  const updatefixed = (fix) => {
    setFixed(fix);
  };

  const [change, setChange] = useState(true);
  useEffect(() => {
    if (data.changePrice != undefined && data.changePrice.startsWith("-")) {
      setChange(false);
    } else {
      setChange(true);
    }
  }, [data]);

  const options = {
    legend: "none",
    bar: { groupWidth: "50%" }, // Remove space between bars.
    candlestick: {
      fallingColor: { strokeWidth: 0, fill: "#a52714" }, // red
      risingColor: { strokeWidth: 0, fill: "#0f9d58" }, // green
    },
    vAxis: {
      title: "",
    },
  };

  return (
    <section className="assetperform">
      <div className="absolute top-[90px] left-0 w-screen">
        <div className="w-full h-[280px] max-w-[1920px] overflow-hidden mx-auto">
          <img
            src={require("./img/stockCover.png")}
            alt="cover"
            className="object-cover w-full h-full object-center"
          />
        </div>
      </div>
      <div className="mb-[50px] md:mb-[50px] mt-[350px]">
        <NavbarWpicture navitems={navitems} fixed={updatefixed} />
      </div>
      <h1
        className={`text-xl md:text-2xl font-cbold text-blue-950 mb-6 ${
          navFix ? "mt-[500px]" : ""
        }`}
      >
        <Trans i18nKey="stock.header">Stock Quotes</Trans>
      </h1>
      <div className="stock-price flex max-md:flex-col w-full justify-between mb-10 md:mb-6 bg-neutral-50">
        <div className="flex flex-col bg-blue-950 w-full md:basis-[17%] justify-center items-center max-md:py-3">
          <h1 className="text-3xl text-gold">DREIT</h1>
          <h1 className="text-3xl text-gold"> {data.price}</h1>
          <h2 className={`${change ? "text-lime-600" : "text-red-500"}`}>
            {data.changePrice} ({data.changePercent} %)
          </h2>
          <p className="text-white text-sm">
            <Trans i18nKey="stock.currency">Currency: THB</Trans>
          </p>
        </div>
        <div className="w-full md:basis-[83%] flex max-sm:flex-col">
          <div className="flex flex-col justify-around items-center w-full sm:basis-1/2 md:basis-[60%] h-[180px]">
            <div className="text-center border-b w-full h-full flex flex-col justify-center">
              <p className="text-neutral-400">
                <Trans i18nKey="stock.updated">Updated:</Trans>
              </p>
              <p className="font-bold">{data.lastUpdateDate}</p>
            </div>
            <div className="w-full h-full flex justify-around items-center">
              <div className="text-center w-full border-r h-full flex flex-col justify-center">
                <p className="text-neutral-400">
                  <Trans i18nKey="stock.share">Volume (Shares)</Trans>
                </p>
                <p className="font-bold">{data.volumnShares}</p>
              </div>
              <div className="text-center w-full">
                <p className="text-neutral-400">
                  <Trans i18nKey="stock.baht">Volume ('000 Baht)</Trans>
                </p>
                <p className="font-bold">{data.volumnBaht}</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-around items-center w-full sm:basis-1/2 md:basis-[40%] border-t sm:border-l sm:border-t-0 h-[180px]">
            <div className="flex w-full justify-around border-b h-full items-center">
              <div className="w-full h-full flex flex-col justify-center text-center border-r">
                <p className="text-neutral-400">
                  <Trans i18nKey="stock.open">Open</Trans>
                </p>
                <p className="font-bold">{data.openPrice}</p>
              </div>
              <div className="w-full text-center">
                <p className="text-neutral-400">
                  <Trans i18nKey="stock.close">Close</Trans>
                </p>
                <p className="font-bold">{data.closePrice}</p>
              </div>
            </div>
            <div className="flex w-full justify-around h-full items-center">
              <div className="w-full h-full flex flex-col justify-center text-center border-r">
                <p className="text-neutral-400">
                  <Trans i18nKey="stock.high">High</Trans>
                </p>
                <p className="font-bold">{data.highPrice}</p>
              </div>
              <div className="w-full text-center">
                <p className="text-neutral-400">
                  <Trans i18nKey="stock.low">Low</Trans>
                </p>
                <p className="font-bold">{data.lowPrice}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-1 mt-20">
        <label htmlFor="selectBox" className=" text-gray-700">
          <Trans i18nKey="stock.range">Stock Range:</Trans>
        </label>
        <select
          id="selectBox"
          name="selectBox"
          onChange={(e) => setDateData(e.target.value)}
          className="appearance-none w-[200px] pl-2 border-b-2 border-gold rounded-none rounded-se-md focus:outline-none focus:ring focus:ring-gold/60 focus:ring-offset-1"
        >
          <option value="Week">
            1 <Trans i18nKey="stock.week">Week</Trans>
          </option>
          <option value="Month">
            1 <Trans i18nKey="stock.month">Month</Trans>
          </option>
          <option value="3Month">
            3 <Trans i18nKey="stock.months">Months</Trans>
          </option>
          <option value="6Month">
            6 <Trans i18nKey="stock.months">Months</Trans>
          </option>
          <option value="Year">
            1 <Trans i18nKey="stock.year">Year</Trans>
          </option>
          <option value="3Year">
            3 <Trans i18nKey="stock.years">Years</Trans>
          </option>
          <option value="5Year">
            5 <Trans i18nKey="stock.years">Years</Trans>
          </option>
        </select>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
          />
        </svg>
      </div>
      <div className="w-full">
        <Chart
          width={"100%"}
          height={"400px"}
          chartType="CandlestickChart"
          data={[
            ["Date", "Low", "Open", "Close", "High"],
            ...chartdata.map((item) => [
              item.lastUpdateDate,
              parseFloat(item.lowPrice),
              parseFloat(item.openPrice),
              parseFloat(item.closePrice),
              parseFloat(item.highPrice),
            ]),
          ]}
          options={options}
        />
      </div>
    </section>
  );
}
