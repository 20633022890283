import React from "react";
import { useTranslation, Trans } from "react-i18next";

import Navbar from "../components/navbar";
import InfoLoc from "./infoLoc";
import AssetPic from "./assetPic";

import { MdFamilyRestroom, MdOutlineSurfing } from "react-icons/md";
import { LiaUmbrellaBeachSolid } from "react-icons/lia";
import { GiGolfFlag } from "react-icons/gi";
import { PiFlowerLotusLight } from "react-icons/pi";

export default function Phuket(props) {
  const { i18n } = useTranslation();
  const navitems = [
    {
      title: "Dusit Thani Laguna Phuket Hotel",
      title_th: "โครงการดุสิตธานี ลากูนา ภูเก็ต",
      active: true,
      link: "/Management/Asset_Infomation/Laguna_Phuket_Hotel",
    },
    {
      title: "Dusit Thani Hua Hin Hotel",
      title_th: "โครงการดุสิตธานี หัวหิน",
      active: false,
      link: "/Management/Asset_Infomation/Hua_Hin_Hotel",
    },
    {
      title: "Dusit Thani Maldives Hotel",
      title_th: "โครงการดุสิตธานี มัลดีฟส์",
      active: false,
      link: "/Management/Asset_Infomation/Maldives_Hotel",
    },
  ];

  const loc = "phuket";
  const info = {
    sqm: "16,606",
    room: "226",
    addr: "No. 390 Srisoontorn Road, Tambon Cherngtalay, Amphur Talang, Phuket",
    addrth: "เลขที่ 390 ถนนศรีสุนทร ตำบลเชิงทะเล อำเภอถลาง จังหวัดภูเก็ต",
  };
  const weblink = "https://www.dusit.com/dusitthani-lagunaphuket/";

  return (
    <section className="asset-phuket">
      <div className="mb-[150px] md:mb-[170px]">
        <Navbar navitems={navitems} />
      </div>
      <AssetPic place={"phuket"} />
      <div className="grid grid-cols-1 lg:grid-cols-4">
        <div className="lg:border-r lg:pr-9 md:col-span-3">
          <h1 className="text-xl md:text-3xl font-cbold text-blue-950 pb-4">
            <Trans i18nKey="Phuket.project">
              Dusit Thani Laguna Phuket Hotel
            </Trans>
          </h1>
          <p className="md:text-lg">
            <Trans i18nKey="Phuket.detail">
              Dusit Thani Laguna Phuket Hotel is a large 5-star hotel, situated
              in Phuket Province. This hotel is a uniquely designed resort
              hotel. The hotel has a furnishing style and services which
              accentuate the distinguished Thai taste in every detail. The hotel
              is spacious and convenient, and the areas of which stretch
              extensively alongside the Bang Tao beach and are flanked by large
              lagoons while most guest rooms have the pleasure of the sea view.
            </Trans>
          </p>
        </div>
        <InfoLoc loc={loc} info={info} weblink={weblink} />
        <div className="lg:border-r lg:pr-9 md:col-span-3 pt-8">
          <h2 className="text-xl md:text-3xl font-cbold text-blue-950 pb-4">
            <Trans i18nKey="Phuket.facil">Facilities</Trans>
          </h2>
          <div className="grid grid-cols-2 sm:grid-cols-3 max-md:gap-y-4 md:grid-cols-5 [&>*:not(:last-child)]:pr-8 [&>*:not(:first-child)]:pl-8 odd:[&>*]:border-r sm:[&>*]:border-r sm:[&>*:nth-child(3)]:border-none sm:[&>*:last-child]:border-none md:[&>*:nth-child(4)]:border-l">
            <div className="flex flex-col gap-4 items-center">
              <div className="text-gold text-4xl">
                <MdFamilyRestroom />
              </div>
              <div className="text-zinc-800 text-center">
                <Trans i18nKey="Phuket.facil-1">
                  wide range of interests and ages
                </Trans>
              </div>
            </div>
            <div className="flex flex-col gap-4 items-center">
              <div className="text-gold text-4xl">
                <LiaUmbrellaBeachSolid />
              </div>
              <div className="text-zinc-800 text-center">
                <Trans i18nKey="Phuket.facil-2">private beach</Trans>
              </div>
            </div>
            <div className="flex flex-col gap-4 items-center">
              <div className="text-gold text-4xl">
                <GiGolfFlag />
              </div>
              <div className="text-zinc-800 text-center">
                <Trans i18nKey="Phuket.facil-3">
                  18-hole championship golf course
                </Trans>
              </div>
            </div>
            <div className="flex flex-col gap-4 items-center">
              <div className="text-gold text-4xl">
                <PiFlowerLotusLight />
              </div>
              <div className="text-zinc-800 text-center">
                <Trans i18nKey="Phuket.facil-4">Angsana Spa</Trans>
              </div>
            </div>
            <div className="flex flex-col gap-4 items-center">
              <div className="text-gold text-4xl">
                <MdOutlineSurfing />
              </div>
              <div className="text-zinc-800 text-center">
                <Trans i18nKey="Phuket.facil-5">Activities Center</Trans>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
