import React, { useEffect, useState } from "react";

import Navbar from "../components/navbar";

import { useTranslation, Trans } from "react-i18next";

export default function OrganizeStructure(props) {
  const { i18n } = useTranslation();

  const navitems = [
    {
      title: "Company Information",
      title_th: "ผู้จัดการกองทรัสต์",
      active: false,
      link: "/Management/REIT_Manager/Company_Information",
    },
    {
      title: "Organization Structure",
      title_th: "โครงสร้างองค์กร",
      active: true,
      link: "/Management/REIT_Manager/Organization_Structure",
    },
    {
      title: "Board of Directors",
      title_th: "คณะกรรมการบริษัท",
      active: false,
      link: "/Management/REIT_Manager/Board_of_Directors",
    },
  ];

  const [imgUrl, setImgUrl] = useState(
    "https://dreit.blob.core.windows.net/organization/organization-structure-en.png"
  );

  useEffect(() => {
    let lng = i18n.language;
    if (lng == "en") {
      setImgUrl(
        "https://dreit.blob.core.windows.net/organization/organization-structure-en.png"
      );
    } else {
      setImgUrl(
        "https://dreit.blob.core.windows.net/organization/organization-structure-th.png"
      );
    }
  }, [i18n.language]);

  return (
    <section>
      <div className="companyInfo mb-[150px] md:mb-[170px]">
        <Navbar navitems={navitems} />
      </div>
      <div>
        <h1 className="text-xl md:text-2xl font-cbold text-blue-950">
          <Trans i18nKey="OrganizationStructure.Topic">
            Organization Structure
          </Trans>
        </h1>
        <p className="py-4 md:text-lg">
          <Trans i18nKey="OrganizationStructure.Desc"></Trans>
        </p>
      </div>
      <div className="py-6">
        <img
          src={imgUrl}
          alt="organization_structure"
          className="w-full md:w-[60%] mx-auto"
        />
      </div>
    </section>
  );
}
