import React, { useEffect, useState } from "react";
import Axios from "axios";
import DataTable from "../components/DataTable";
import { useTranslation, Trans } from "react-i18next";

import NavbarWpicture from "../components/navbarWpicture";

export default function MUlist(props) {
  const { i18n, t } = useTranslation();
  const navitems = [
    {
      title: "List of Major Unitholders",
      title_th: "รายชื่อผู้ถือหน่วยทรัสต์",
      active: true,
      link: "/Investor_Relations/Unitholders_Information/List_of_Major_Unitholders",
    },
    {
      title: "Shareholder Meeting and Operation Report",
      title_th: "การประชุมผู้ถือหน่วยทรัสต์และรายงานการดำเนินงาน",
      active: false,
      link: "/Investor_Relations/Unitholders_Information/Shareholder_Meeting",
    },
    {
      title: "Distribution Information",
      title_th: "การจ่ายผลตอบแทน",
      active: false,
      link: "/Investor_Relations/Unitholders_Information/Distribution_Information",
    },
    {
      title: "Distribution on Reduction Capital",
      title_th: "การจ่ายเงินลดทุน",
      active: false,
      link: "/Investor_Relations/Unitholders_Information/Distribution_on_Reduction_Capital",
    },
    {
      title: "Allotment of Additional Trust Units",
      title_th: "การจัดสรรหน่วยทรัสต์เพิ่มเติม",
      active: false,
      link: "/Investor_Relations/Unitholders_Information/Allotment_of_Additional_Trust_Units",
    },
  ];

  const [data, setData] = useState([]);
  const [dataDate, setDataDate] = useState("");
  const [dataType, setDataType] = useState("XD");

  const populateMajorUnitholderData = async () => {
    const response = await Axios.get(
      process.env.REACT_APP_API_URI +
        "/Unitholder/GetMajorUnitholder?lng=" +
        i18n.language
    ).then((res) => {
      const data = res.data;
      setDataDate(data[0].overviewDate);
      setDataType(data[0].overviewType);
      setData(data);
    });
  };

  const dataColumn = [
    {
      title: t("MUlist.No"),
      data: "rank",
      orderable: true,
      className: "text-center",
    },
    {
      title: t("MUlist.TrustUnitholders"),
      data: "unitholderName",
      orderable: false,
    },
    {
      title: t("MUlist.NumberOfUnits"),
      data: "numberUnits",
      orderable: false,
      className: "hidden md:table-cell",
    },
    {
      title: t("MUlist.UnitsProportion"),
      data: "unitsProportion",
      orderable: false,
      className: "hidden md:table-cell",
    },
  ];

  const dataDefs = [
    {
      targets: 0, // Index of the 'mDate' column
      orderData: [0], // Sort by this column
    },
    {
      targets: 1, // Target the second column (index 1)
      width: "50%", // Set width to 50% for column 2
    },
  ];

  const dataOrder = [[0, "asc"]];

  useEffect(() => {
    populateMajorUnitholderData();
  }, [i18n.language]);

  const [navFix, setFixed] = useState(false);

  const updatefixed = (fix) => {
    setFixed(fix);
  };

  return (
    <section className="assetperform">
      <div className="absolute top-[90px] left-0 w-screen">
        <div className="w-full h-[280px] max-w-[1920px] overflow-hidden mx-auto">
          <img
            src={require("./img/unitholderCover.png")}
            alt="cover"
            className="object-cover w-full h-full object-center"
          />
        </div>
      </div>
      <div className="mb-[50px] md:mb-[50px] mt-[350px]">
        <NavbarWpicture navitems={navitems} fixed={updatefixed} />
      </div>
      <div>
        <h1
          className={`text-xl md:text-2xl font-cbold text-blue-950 mb-6 ${
            navFix ? "mt-[500px]" : ""
          }`}
        >
          <Trans i18nKey="MUlist.header">List of Major Unitholders</Trans>
        </h1>
        <div className="table-performance">
          <h1 className="text-l md:text-l font-cbold">
            {i18n.language === "en"
              ? "Overview As of " + dataDate + " Rights Type : " + dataType
              : "ภาพรวมข้อมูลผู้ถือหุ้น ณ วันที่ " +
                dataDate +
                " ประเภท : " +
                dataType}
          </h1>
          <DataTable
            data={data}
            dataColumn={dataColumn}
            dataDefs={dataDefs}
            dataOrder={dataOrder}
          />
        </div>
      </div>
    </section>
  );
}
