import React, { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";

import OccupancyRate from "./OccupancyRate";
import Performance from "./Performance";
/*import Nationality from "./Nationality";*/
export default function Asset({ selectedName, selectedAsset, selectedYear }) {
  const { i18n } = useTranslation();
  useEffect(() => {}, [i18n.language, selectedAsset, selectedYear]);

  return (
    <div className="w-full border-b border-gray-400 mb-10">
      <div className="w-full">
        <h1 className="text-xl">{selectedName}</h1>
      </div>
      <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-1/2">
          <OccupancyRate
            selectedYear={selectedYear}
            selectedAsset={selectedAsset}
          />
        </div>
        <div className="w-full md:w-1/2">
          <Performance
            selectedYear={selectedYear}
            selectedAsset={selectedAsset}
          />
        </div>
      </div>
      {/*<div className="w-full">*/}
      {/*  <Nationality*/}
      {/*    selectedYear={selectedYear}*/}
      {/*    selectedAsset={selectedAsset}*/}
      {/*  />*/}
      {/*</div>*/}
    </div>
  );
}
