import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useTranslation, Trans } from "react-i18next";
import icon from "./img/quote.svg";
import uparrow from "./img/uparrow.svg";
import downarrow from "./img/downarrow.svg";

export default function Quote(props) {
  const { i18n, t } = useTranslation();

  const initStock = {
    stockPrice: "",
    stockDate: "",
    stockVolShares: "",
    stockVolBaht: "",
    stockChgPercent: "",
    stockChgPrice: "",
  };
  const [stock, setStock] = useState(initStock);

  const populateStockData = async () => {
    const response = await Axios.get(
        process.env.REACT_APP_API_URI + "/Stock/GetStock?lng=" + i18n.language
    ).then((res) => {
      const data = res.data;
      setStock({
        ...stock,
        stockPrice: data.price,
        stockDate: data.lastUpdateDate,
        stockVolShares: data.volumnShares,
        stockVolBaht: data.volumnBaht,
        stockChgPercent: data.changePercent,
        stockChgPrice: data.changePrice,
      });
    });
  };

  useEffect(() => {
    populateStockData();
  }, [i18n.language]);

  const [change, setChange] = useState(true);

  useEffect(() => {
    if (stock.stockChgPrice.startsWith("-")) {
      setChange(false);
    } else {
      setChange(true);
    }
  }, [stock]);

  return (
    <div
      className={`w-full h-fit md:w-96 md:h-96 md:px-8 py-6 bg-white flex-col justify-between items-center gap-4 inline-flex md:shadow-lg shadow-gray-500 max-md:border-r`}
    >
      <div className="flex max-md:flex-col max-md:items-center justify-between w-full">
        <div>
          <img
            src={icon}
            alt="card-ico"
            id="quote-ico"
            className="w-[60%] max-md:mx-auto md:w-[calc(100%-25%)] xl:w-fit"
          />
        </div>
        <div className="text-2xl xl:text-4xl text-blue-950 font-cbold">
          DREIT
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <div className="text-2xl md:text-5xl xl:text-6xl text-blue-950 font-cbold text-center">
                  {stock.stockPrice !== "" ? stock.stockPrice + " " + t("Home.THB") : "Loading..."}
        </div>
        <div className="flex gap-4 justify-center">
          <img src={change ? uparrow : downarrow} className="max-md:w-[10%]" />
          <div
            className={`md:text-2xl xl:text-3xl ${
              change ? "text-lime-600" : "text-red-500"
            }`}
          >
            {stock.stockChgPrice} ({stock.stockChgPercent} %)
          </div>
        </div>
      </div>
      <div className="text-neutral-500 font-cbold max-md:text-sm text-center">              
        {t("Home.VolumeShares") + " "}
        {stock.stockVolShares !== "" ? stock.stockVolShares : "Loading..."}
      </div>
      <div className="text-neutral-500 text-xs md:text-sm text-center">
              {t("Home.Updated") + " "}
        {stock.stockDate !== "" ? stock.stockDate : "Loading..."}
      </div>
    </div>
  );
}
