import React, { useState, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import { GiBookshelf } from "react-icons/gi";
import { GrTree } from "react-icons/gr";

export default function BoardBio(props) {
  const { i18n } = useTranslation();

  const [edu, setEdu] = useState([]);
  const [current, setCurrent] = useState([]);

  useEffect(() => {
    setEdu(props.edu);
    setCurrent(props.current);
  }, [props]);

  return (
    <div className="border border-gold w-full flex flex-col lg:flex-row lg:justify-between py-9">
      <div className="px-9">
        <div className="flex gap-2 mb-6">
          <div className="text-blue-950 text-3xl">
            <GiBookshelf />
          </div>
          <h3 className="text-blue-950 font-cbold text-3xl">
            <Trans i18nKey="Board.Edu">Educations</Trans>
          </h3>
        </div>
        <ul className="list-disc text-zinc-800 pl-6">
          {edu.map((e, i) => (
            <li>{e}</li>
          ))}
        </ul>
      </div>
      <div className="hidden lg:block border-r flex-auto"></div>
      <div className="px-9 mt-9 lg:mt-0 min-w-[50%]">
        <div className="flex gap-2 mb-6">
          <div className="text-blue-950 text-3xl">
            <GrTree />
          </div>
          <h3 className="text-blue-950 font-cbold text-3xl">
            <Trans i18nKey="Board.Current">Current Positions</Trans>
          </h3>
        </div>
        <div>
          <table className="table-auto">
            <tbody>
              {current.map((c, i) => (
                <tr>
                  <td
                    key={i}
                    className="whitespace-nowrap min-w-fit text-gold font-cbold align-text-top"
                  >
                    {c.year}
                  </td>
                  <td>
                    <ul className="list-disc list-outside text-zinc-800 pl-8 pb-4">
                      {c.position.map((p, i) => (
                        <li key={i}>{p}</li>
                      ))}
                    </ul>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
