import React, { useEffect, useState } from "react";
import Axios from "axios";

import ModalPic from "./modalPic";

export default function AssetPic(props) {
  const [source, setSource] = useState([]);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  });

  const getSource = async () => {
    const response = await Axios.get(
      process.env.REACT_APP_API_URI + "/AssetInfo/GetAssetInfo/" + props.place
    ).then((res) => {
      const s = res.data;
      const s_sort = s.sort((a, b) => b.rank - a.rank);
      setSource(s_sort);
    });
  };

  useEffect(() => {
    getSource();
  }, [props]);

  useEffect(() => {
    filterShow();
  }, [source]);

  const [show, setShow] = useState([]);

  const filterShow = () => {
    if (width >= 1025) {
      const ndata = source.filter((s, i) => i < 7);
      setShow(ndata);
    } else if (width >= 768 && width < 1025) {
      const ndata = source.filter((s, i) => i < 5);
      setShow(ndata);
    } else {
      const ndata = source.filter((s, i) => i < 4);
      setShow(ndata);
    }
  };

  useEffect(() => {
    filterShow();
  }, [width]);

  const [modalShow, setModalShow] = useState(false);

  const modalClose = () => {
    setModalShow(false);
  };

  const [ix, setIx] = useState(0);

  const showModal = (i) => {
    setIx(i);
    setModalShow(true);
  };

  return (
    <div className="asset-picture">
      <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 md:grid-rows-2 gap-2 mb-9">
        {show.map((s, i) => {
          if (show.length != i + 1) {
            return (
              <div
                key={i}
                className="first:col-span-3 first:row-span-3 md:first:col-span-2 md:first:row-span-2"
                onClick={() => showModal(i)}
              >
                <img
                  src={
                    "https://dreit.blob.core.windows.net/assetinfo/" +
                    s.fileName
                  }
                  alt={s.fileName}
                  className="object-cover w-full h-full"
                />
              </div>
            );
          } else {
            return (
              <div key={i} className="relative" onClick={() => showModal(i)}>
                <img
                  src={
                    "https://dreit.blob.core.windows.net/assetinfo/" +
                    s.fileName
                  }
                  alt={s.fileName}
                  className="object-cover w-full h-full"
                />
                <div className="absolute top-0 left-0 bg-zinc-700 w-full h-full bg-opacity-70"></div>
                <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white whitespace-pre">
                  {"more " + (source.length - show.length)}
                </div>
              </div>
            );
          }
        })}
      </div>
      <ModalPic show={modalShow} onHide={modalClose} source={source} pos={ix} />
    </div>
  );
}
