import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

export default function ModalPic(props) {
  const [source, setSource] = useState([]);
  const [pos, setPos] = useState(0);

  useEffect(() => {
    setSource(props.source);
  }, [props.source]);

  useEffect(() => {
    setPos(props.pos);
  }, [props.pos]);

  useEffect(() => {}, [pos]);

  return (
    <div className={`relative z-[9999] ${props.show ? "" : "hidden"}`}>
      <div
        className={`w-full h-screen bg-slate-500 opacity-50 fixed top-0 left-0`}
        onClick={props.onHide}
      ></div>
      <div className="w-full sm:w-4/5 md:w-3/4 h-[47vh] md:h-[60vh] lg:h-[85vh] bg-zinc-100 fixed top-1/3 left-1/2 -translate-x-1/2 -translate-y-1/3 p-2 overflow-hidden">
        <div className="flex flex-col gap-y-2 justify-around h-full w-full">
          <div className="w-full flex relative overflow-hidden">
            {source
              .filter((s, i) => i == pos)
              .map((s, i) => (
                <div key={i} className="w-full">
                  <img
                    src={
                      "https://dreit.blob.core.windows.net/assetinfo/" +
                      s.fileName
                    }
                    alt={s.fileName}
                    className="object-cover w-full"
                  />
                </div>
              ))}
            <div
              className="absolute top-[3%] right-[2%] text-2xl text-gold cursor-pointer"
              onClick={props.onHide}
            >
              <AiOutlineClose />
            </div>
            <div
              className={`absolute top-[50%] -translate-x-1/2 left-[2%] text-4xl text-gold drop-shadow-xl cursor-pointer bg-black bg-opacity-30 rounded-full ${
                pos == 0 ? "hidden" : ""
              }`}
              onClick={() => setPos(pos - 1)}
            >
              <IoIosArrowBack />
            </div>
            <div
              className={`absolute top-[50%] -translate-x-1/2 right-0 text-4xl text-gold drop-shadow-xl cursor-pointer bg-black bg-opacity-30 rounded-full ${
                pos + 1 == source.length ? "hidden" : ""
              }`}
              onClick={() => setPos(pos + 1)}
            >
              <IoIosArrowForward />
            </div>
          </div>
          <div className="flex w-full h-fit overflow-x-auto items-center snap-x snap-proximity overflow-y-hidden">
            {source.map((s, i) => (
              <div
                key={i}
                className={`shrink-0 w-[13.5%] ${
                  i == pos
                    ? "border-4 md:border-8 border-gold snap-center"
                    : "px-1"
                } h-full`}
                onClick={() => setPos(i)}
              >
                <img
                  src={
                    "https://dreit.blob.core.windows.net/assetinfo/" +
                    s.fileName
                  }
                  alt={s.fileName}
                  className="object-cover w-full aspect-square"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
