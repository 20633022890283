import React from "react";

import Quote from "./QuoteCard";
import Nav from "./navcard";
import Announce from "./announcecard";

export default function Card(props) {
  return (
    <div className="card">
      <div className="max-w-[1400px] flex gap-2 md:gap-8 md:px-8 max-md:flex-col max-md:items-center mx-auto md:flex-wrap justify-center">
        <div className="flex md:gap-8 justify-between max-md:w-full max-md:py-6 max-md:bg-white">
          <div className="basis-1/2 md:basis-0">
            <Quote />
          </div>
          <div className="basis-1/2 md:basis-0">
            <Nav />
          </div>
        </div>
        <Announce />
      </div>
    </div>
  );
}
