import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useTranslation, Trans } from "react-i18next";
import PdfModal from "../components/PdfModal";
import VideoModal from "../components/VideoModal";
import NavbarWpicture from "../components/navbarWpicture";
import { ImFilePdf } from "react-icons/im";
import { BsCloudDownload } from "react-icons/bs";
import { AiOutlinePlayCircle } from "react-icons/ai";

export default function Webcast(props) {
  const { i18n } = useTranslation();
  const navitems = [
    {
      title: "Webcast & Presentation",
      title_th: "เว็บแคสต์และเอกสารนำเสนอ",
      active: true,
      link: "/Investor_Relations/Webcast_&_Presentation",
    },
  ];

  const [showModal, setShowModal] = useState(false);
  //const [showVideoModal, setShowVideoModal] = useState(false);
  const [pdfObj, setPdfObj] = useState(null);
  //const [videoObj, setVideoObj] = useState(null);
  const [data, setData] = useState([]);

  const populateWebCastData = async () => {
    const response = await Axios.get(
      process.env.REACT_APP_API_URI + "/Webcast/GetWebcast?lng=" + i18n.language
    ).then((res) => {
      const data = res.data;
      setData(data);
    });
  };

  const handleButtonClick = (title, url, blobName, fileName) => {
    // Set the PDF object here
    const newPdfObj = {
      title,
      url,
      blobName,
      fileName,
    };

    // Set both showModal and pdfObj state
    setShowModal(true);
    setPdfObj(newPdfObj);
  };

  //const handleVideoClick = (title, url) => {
  //    // Set the PDF object here
  //    const newVideoObj = {
  //        title,
  //        url,
  //    };

  //    // Set both showModal and pdfObj state
  //    setShowVideoModal(true);
  //    setVideoObj(newVideoObj);
  //};

  useEffect(() => {
    populateWebCastData();
  }, [i18n.language]);

  const [navFix, setFixed] = useState(false);

  const updatefixed = (fix) => {
    setFixed(fix);
  };

  return (
    <section className="assetperform">
      <div className="absolute top-[90px] left-0 w-screen">
        <div className="w-full h-[280px] max-w-[1920px] overflow-hidden mx-auto">
          <img
            src={require("../Unitholder/img/unitholderCover.png")}
            alt="cover"
            className="object-cover w-full h-full object-center"
          />
        </div>
      </div>
      <div className="mb-[50px] md:mb-[50px] mt-[350px]">
        <NavbarWpicture navitems={navitems} fixed={updatefixed} />
      </div>
      <div>
        <h1
          className={`text-xl md:text-2xl font-cbold text-blue-950 mb-6 ${
            navFix ? "mt-[500px]" : ""
          }`}
        >
          <Trans i18nKey="Webcast.header">Webcast & Presentation</Trans>
        </h1>
        <div className="flex items-center justify-between flex-wrap">
          {data.map((item, i) => (
            <div key={i} className="w-full sm:w-1/2 md:w-1/3 p-2">
              <div className="flex flex-col rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] p-2">
                <img
                  className="w-full aspect-auto rounded-t-lg object-cover md:h-auto"
                  src={item.webcastCoverUrl}
                  alt={item.webcastName}
                />
                <div className="flex flex-col justify-start py-3">
                  <h5 className="mb-2 md:text-lg font-medium text-blue-950">
                    {item.webcastName}
                  </h5>
                </div>
                <div className="flex justify-between my-4">
                  {item.webcastVideoUrl && (
                    <div className="w-full border-r cursor-pointer">
                      <a href={item.webcastVideoUrl} target="_blank" download>
                        <div className="flex gap-1 items-center text-stone-300 hover:text-blue-950 justify-center">
                          <AiOutlinePlayCircle />
                          <div className="hidden sm:block md:max-lg:hidden">
                            <Trans i18nKey="Webcast.viewVdo">View VDO</Trans>
                          </div>
                        </div>
                      </a>
                    </div>
                  )}

                  <div className="w-full border-r cursor-pointer">
                    <a
                      download
                      onClick={() =>
                        handleButtonClick(
                          item.webcastName,
                          item.webcastPdfUrl,
                          "webcast",
                          item.webcastFileName
                        )
                      }
                    >
                      <div className="flex gap-1 items-center text-stone-300 hover:text-blue-950 justify-center">
                        <ImFilePdf />
                        <div className="hidden sm:block md:max-lg:hidden">
                          <Trans i18nKey="Webcast.view">View PDF</Trans>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="w-full cursor-pointer">
                    <a href={item.webcastPdfUrl} download target="_blank">
                      <div className="flex gap-1 items-center text-stone-300 hover:text-blue-950 w-full justify-center">
                        <BsCloudDownload />
                        <div className="hidden sm:block md:max-lg:hidden">
                          <Trans i18nKey="Webcast.download">Download</Trans>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <PdfModal
                      pdfObj={pdfObj}
                      isOpen={showModal}
                      onClose={() => {
                          document.body.style.overflow = "auto";
                          setShowModal(false);
                      }}
          />
          {/*<VideoModal videoObj={videoObj} isOpen={showVideoModal} onClose={() => setShowVideoModal(false)} />*/}
        </div>
      </div>
    </section>
  );
}
