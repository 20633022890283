import ReitPerform from "./financialInfo/reitperform";
import UnitNAV from "./financialInfo/UnitNAV";
import AssetPerform from "./financialInfo/assetperform";
import FinancialStatement from "./financialInfo/financialStatement";
import ManagementDiscuss from "./financialInfo/managementDiscuss";
import Appraisal from "./financialInfo/Appraisal";
import StockQuote from "./stockInfo/stockQuote";
import Historical from "./stockInfo/historical";
import MUlist from "./Unitholder/MUlist";
import ShareholderMeeting from "./Unitholder/meeting";
import Distribution from "./Unitholder/distribution";
import Reduction from "./Unitholder/reduction";
import Allotment from "./Unitholder/allotment";
import AnnualReport from "./publication/annualReport";
import Form56 from "./publication/form56";
import Prospectus from "./publication/prospectus";
import Webcast from "./webcast/webcast";
import SETAnnounce from "./newsroom/SETAnnounce";
import Press from "./newsroom/Press";
import News from "./newsroom/news";

const AppRoutesIR = [
  {
    path: "/Investor_Relations/Financial_Information/REIT_Performance",
    pathth: "/นักลงทุนสัมพันธ์/ข้อมูลทางการเงืน/ผลการดำเนินงานของกองทุน",
    element: <ReitPerform />,
  },
  {
    path: "/Investor_Relations/Financial_Information/Unit_NAV",
    pathth: "/นักลงทุนสัมพันธ์/ข้อมูลทางการเงืน/มูลค่าสินทรัพย์สุทธิ(NAV)",
    element: <UnitNAV />,
  },
  {
    path: "/Investor_Relations/Financial_Information/Asset_Performace",
    pathth:
      "/นักลงทุนสัมพันธ์/ข้อมูลทางการเงืน/ผลการดำเนินงานของอสังหาริมทรัพย์",
    element: <AssetPerform />,
  },
  {
    path: "/Investor_Relations/Financial_Information/Financial_Statement",
    pathth: "/นักลงทุนสัมพันธ์/ข้อมูลทางการเงืน/งบการเงิน",
    element: <FinancialStatement />,
  },
  {
    path: "/Investor_Relations/Financial_Information/Management_Discussion_and_Analysis",
    pathth:
      "/นักลงทุนสัมพันธ์/ข้อมูลทางการเงืน/คำอธิบายและการวิเคราะห์ของฝ่ายจัดการ",
    element: <ManagementDiscuss />,
  },
  {
    path: "/Investor_Relations/Financial_Information/Appraisal_Report",
    pathth: "/นักลงทุนสัมพันธ์/ข้อมูลทางการเงืน/รายงานประเมินมูลค่าทรัพย์สิน",
    element: <Appraisal />,
  },
  {
    path: "/Investor_Relations/Stock_Information/Stock_Quotes",
    pathth: "/นักลงทุนสัมพันธ์/ข้อมูลราคาทรัพย์/ราคาหลักทรัพย์",
    element: <StockQuote />,
  },
  {
    path: "/Investor_Relations/Stock_Information/Historical_Price",
    pathth: "/นักลงทุนสัมพันธ์/ข้อมูลราคาทรัพย์/ราคาย้อนหลัง",
    element: <Historical />,
  },
  {
    path: "/Investor_Relations/Unitholders_Information/List_of_Major_Unitholders",
    pathth:
      "/นักลงทุนสัมพันธ์/ข้อมูลผู้ถือหน่วยทรัสต์/รายชื่อผู้ถือหน่วยทรัสต์",
    element: <MUlist />,
  },
  {
    path: "/Investor_Relations/Unitholders_Information/Shareholder_Meeting",
    pathth:
      "/นักลงทุนสัมพันธ์/ข้อมูลผู้ถือหน่วยทรัสต์/การประชุมผู้ถือหน่วยทรัสต์",
    element: <ShareholderMeeting />,
  },
  {
    path: "/Investor_Relations/Unitholders_Information/Distribution_Information",
    pathth: "/นักลงทุนสัมพันธ์/ข้อมูลผู้ถือหน่วยทรัสต์/การจ่ายผลตอบแทน",
    element: <Distribution />,
  },
  {
    path: "/Investor_Relations/Unitholders_Information/Distribution_on_Reduction_Capital",
    pathth: "/นักลงทุนสัมพันธ์/ข้อมูลผู้ถือหน่วยทรัสต์/การจ่ายเงินลดทุน",
    element: <Reduction />,
  },
  {
    path: "/Investor_Relations/Unitholders_Information/Allotment_of_Additional_Trust_Units",
    pathth:
      "/นักลงทุนสัมพันธ์/ข้อมูลผู้ถือหน่วยทรัสต์/่การจัดสรรหน่วยทรัสต์เพิ่มเติม",
    element: <Allotment />,
  },
  {
    path: "/Investor_Relations/Publication_&_Download/Annual_Report",
    pathth: "/นักลงทุนสัมพันธ์/เอกสารสำคัญเพื่อการเผยแพร่/รายงานประจำปี",
    element: <AnnualReport />,
  },
  {
    path: "/Investor_Relations/Publication_&_Download/Form_56-REIT",
    pathth: "/นักลงทุนสัมพันธ์/เอกสารสำคัญเพื่อการเผยแพร่/แบบฟอร์ม_56-REIT",
    element: <Form56 />,
  },
  {
    path: "/Investor_Relations/Publication_&_Download/Prospectus",
    pathth: "/นักลงทุนสัมพันธ์/เอกสารสำคัญเพื่อการเผยแพร่/หนังสือชี้ชวน",
    element: <Prospectus />,
  },
  {
    path: "/Investor_Relations/Webcast_&_Presentation",
    pathth: "/นักลงทุนสัมพันธ์/เว็บแคสต์และเอกสารนำเสนอ",
    element: <Webcast />,
  },
  {
    path: "/Investor_Relations/Newsroom/SET_Announcements",
    pathth: "/นักลงทุนสัมพันธ์/ห้องข่าว/ข่าวแจ้งตลาดหลักทรัพย์",
    element: <SETAnnounce />,
  },
  {
    path: "/Investor_Relations/Newsroom/Press_Releases",
    pathth: "/นักลงทุนสัมพันธ์/ห้องข่าว/ข่าวแจ้งสื่อมวลชน",
    element: <Press />,
  },
  {
    path: "/Investor_Relations/Newsroom/News_&_Events",
    pathth: "/นักลงทุนสัมพันธ์/ห้องข่าว/ข่าวสารและกิจกรรม",
    element: <News />,
  },
];

export default AppRoutesIR;
