import React, { useEffect, useState } from "react";
import Axios from "axios";
import { TECollapse } from "tw-elements-react";
import { useTranslation, Trans } from "react-i18next";
import Shareholder from "./Shareholder/Shareholder";
import NavbarWpicture from "../components/navbarWpicture";

export default function ShareholderMeeting(props) {
  const { i18n } = useTranslation();
  const navitems = [
    {
      title: "List of Major Unitholders",
      title_th: "รายชื่อผู้ถือหน่วยทรัสต์",
      active: false,
      link: "/Investor_Relations/Unitholders_Information/List_of_Major_Unitholders",
    },
    {
      title: "Shareholder Meeting and Operation Report",
      title_th: "การประชุมผู้ถือหน่วยทรัสต์และรายงานการดำเนินงาน",
      active: true,
      link: "/Investor_Relations/Unitholders_Information/Shareholder_Meeting",
    },
    {
      title: "Distribution Information",
      title_th: "การจ่ายผลตอบแทน",
      active: false,
      link: "/Investor_Relations/Unitholders_Information/Distribution_Information",
    },
    {
      title: "Distribution on Reduction Capital",
      title_th: "การจ่ายเงินลดทุน",
      active: false,
      link: "/Investor_Relations/Unitholders_Information/Distribution_on_Reduction_Capital",
    },
    {
      title: "Allotment of Additional Trust Units",
      title_th: "การจัดสรรหน่วยทรัสต์เพิ่มเติม",
      active: false,
      link: "/Investor_Relations/Unitholders_Information/Allotment_of_Additional_Trust_Units",
    },
  ];
  const [selectedYear, setSelectedYear] = useState("2024");
  const [data, setData] = useState([]);

  const handleSelectChange = async (e) => {
    const year = e.target.value;
    setSelectedYear(year);
  };

  const populateShareholderData = async () => {
    const response = await Axios.get(
      process.env.REACT_APP_API_URI +
        "/Unitholder/GetShareholder?fnYear=" +
        selectedYear +
        "&lng=" +
        i18n.language
    ).then((res) => {
      const data = res.data;
      setData(data);
    });
  };

  useEffect(() => {
    populateShareholderData();
  }, [i18n.language, selectedYear]);

  const [navFix, setFixed] = useState(false);

  const updatefixed = (fix) => {
    setFixed(fix);
  };

  return (
    <section className="assetperform">
      <div className="absolute top-[90px] left-0 w-screen">
        <div className="w-full h-[280px] max-w-[1920px] overflow-hidden mx-auto">
          <img
            src={require("./img/unitholderCover.png")}
            alt="cover"
            className="object-cover w-full h-full object-center"
          />
        </div>
      </div>
      <div className="mb-[50px] md:mb-[50px] mt-[350px]">
        <NavbarWpicture navitems={navitems} fixed={updatefixed} />
      </div>
      <div>
        <h1
          className={`text-xl md:text-2xl font-cbold text-blue-950 mb-6 ${
            navFix ? "mt-[500px]" : ""
          }`}
        >
          <Trans i18nKey="ShareholderMeeting.header">
            List of Major Unitholders
          </Trans>
        </h1>
        <div className="flex gap-2 mb-6">
          <label htmlFor="selectBox" className="block text-gray-700">
            <Trans i18nKey="stock.year">Year</Trans>:
          </label>
          <select
            id="selectBox"
            name="selectBox"
            onChange={handleSelectChange}
            className="appearance-none w-[100px] pl-2 border-b-2 border-gold rounded-none rounded-se-md focus:outline-none focus:ring focus:ring-gold/60 focus:ring-offset-1"
          >
            <option value="2024" selected>
              2024
            </option>
            <option value="2023">2023</option>
            <option value="2022">2022</option>
            <option value="2021">2021</option>
            <option value="2020">2020</option>
            <option value="2019">2019</option>
            <option value="2018">2018</option>
            <option value="2017">2017</option>
          </select>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
            />
          </svg>
        </div>
      </div>
      <div className="w-full flex flex-col">
        {data.map((val, i) => (
          <div
            key={i}
            className="block rounded-lg bg-neutral-50 text-center shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700"
          >
            <div className="border-b-4 border-gold px-6 py-3 bg-blue-950 text-white">
              <h1>{val.groupName}</h1>
            </div>
            <div className="p-6">
              <h5 className="mb-2 text-xl font-medium leading-tight text-neutral-800 dark:text-neutral-50">
                <Shareholder fnYear={selectedYear} groupCode={val.groupCode} />
              </h5>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
