import React from "react";

import Navbar from "../components/navbar";

import ComFact from "./lessee/comfact";
import ComContact from "./lessee/comcontact";
import ComFactDMM from "./lessee/comfactDMM";
import ComContactDMM from "./lessee/comcontactDMM";
import { useTranslation, Trans } from "react-i18next";

export default function Lesee(props) {
  const { i18n } = useTranslation();
  const navitems = [
    {
      title: "Lessee/ Sub-Lessee",
      title_th: "ผู้เช่าและผู้เช่าช่วงทรัพย์สิน",
      active: true,
      link: "/Management/Lessee%2F_Sub-Lessee_and_Hotel_Operator/Lessee%2F_Sub-Lessee",
    },
    {
      title: "Hotel Operator",
      title_th: "ผู้บริหารทรัพย์สิน",
      active: false,
      link: "/Management/Lessee%2F_Sub-Lessee_and_Hotel_Operator/Hotel_Operator",
    },
  ];
  return (
    <section className="lessee-and-sub-lessee">
      <div className="mb-[150px] md:mb-[170px]">
        <Navbar navitems={navitems} />
      </div>
      <div>
        <h1 className="text-xl md:text-2xl font-cbold text-blue-950 pb-4">
          <Trans i18nKey="LesseeSubLessee.Topic">Lessee/ Sub-Lessee</Trans>
        </h1>
        <p className="mb-4 md:text-lg">
          <Trans i18nKey="LesseeSubLessee.Desc1">
            DREIT has a benefit generation policy from hotel investments by
            leasing lands, buildings, utilities systems together with furniture
            and fixtures of Dusit Thani Laguna Phuket Hotel and Dusit D2
            Chiangmai Hotel, as well as, subleasing leasehold rights in lands
            and buildings of Dusit Thani Hua Hin to hotel operator, Dusit
            Management Co., Ltd. (DMCO) which is one of subsidiaries of Dusit
            Thani Public Company Limited.
          </Trans>
        </p>
      </div>
      <div className="flex flex-col items-center lg:flex-row gap-6 mt-6 lg:justify-between py-6">
        <ComFact />
        <div className="order-first md:order-last w-full lg:w-1/2">
          <ComContact />
        </div>
      </div>
      <div>
        <p className="mb-4 md:text-lg">
          <Trans i18nKey="LesseeSubLessee.Desc2">
            DREIT has a benefit generation policy from hotel investments by
            leasing lands, buildings, utilities systems together with furniture
            and fixtures of Dusit Thani Laguna Phuket Hotel and Dusit D2
            Chiangmai Hotel, as well as, subleasing leasehold rights in lands
            and buildings of Dusit Thani Hua Hin to hotel operator, Dusit
            Management Co., Ltd. (DMCO) which is one of subsidiaries of Dusit
            Thani Public Company Limited.
          </Trans>
        </p>
      </div>
      <div className="flex flex-col items-center lg:flex-row gap-6 mt-6 lg:justify-between py-6">
        <ComFactDMM />
        <div className="order-first md:order-last w-full lg:w-1/2">
          <ComContactDMM />
        </div>
      </div>
    </section>
  );
}
