import React from "react";
import { useTranslation, Trans } from "react-i18next";

import Navbar from "../components/navbar";
import InfoLoc from "./infoLoc";
import AssetPic from "./assetPic";

import { MdHotTub, MdOutlineSportsTennis } from "react-icons/md";
import { LiaUmbrellaBeachSolid } from "react-icons/lia";
import { PiFlowerLotusLight } from "react-icons/pi";

import { LuWaves } from "react-icons/lu";
import { BiSwim } from "react-icons/bi";
import { AiOutlineMedicineBox } from "react-icons/ai";

export default function Maldives(props) {
  const { i18n } = useTranslation();
  const navitems = [
    {
      title: "Dusit Thani Laguna Phuket Hotel",
      title_th: "โครงการดุสิตธานี ลากูนา ภูเก็ต",
      active: false,
      link: "/Management/Asset_Infomation/Laguna_Phuket_Hotel",
    },
    {
      title: "Dusit Thani Hua Hin Hotel",
      title_th: "โครงการดุสิตธานี หัวหิน",
      active: false,
      link: "/Management/Asset_Infomation/Hua_Hin_Hotel",
    },
    {
      title: "Dusit Thani Maldives Hotel",
      title_th: "โครงการดุสิตธานี มัลดีฟส์",
      active: true,
      link: "/Management/Asset_Infomation/Maldives_Hotel",
    },
  ];

  const loc = "maldives";
  const info = {
    sqm: "186,640",
    room: "95",
    addr: "P.O. Box 2188 Mudhdhoo Island in Baa Atoll, the Republic of Maldives",
    addrth:
      "P.O. Box 2188 Mudhdhoo Island in Baa Atoll, the Republic of Maldives",
  };
  const weblink = "https://www.dusit.com/dusitthani-maldives/";

  return (
    <section className="asset-maldives">
      <div className="mb-[150px] md:mb-[170px]">
        <Navbar navitems={navitems} />
      </div>
      <AssetPic place={"maldives"} />
      <div className="grid grid-cols-1 lg:grid-cols-4">
        <div className="lg:border-r lg:pr-9 md:col-span-3">
          <h1 className="text-xl md:text-3xl font-cbold text-blue-950 pb-4">
            <Trans i18nKey="Maldives.project">Dusit Thani Maldives Hotel</Trans>
          </h1>
          <p className="md:text-lg">
            <Trans i18nKey="Maldives.detail">
              Dusit Thani Maldives blends gracious Thai hospitality with the
              unparalleled luxury setting of The Maldives. Encircled by white
              sandy beaches, a stunning 360 degree living house reef and
              turquoise lagoon, the resort is situated on the 16-hectare
              Mudhdhoo Island in Baa Atoll - The Maldives' only UNESCO World
              Biosphere Reserve - and provides elegant accommodation in 94
              villas and residences, each combining lavish Thai furnishings with
              a Maldivian twist and complemented by discreet cutting-edge
              technology and luxurious amenities.
            </Trans>
          </p>
        </div>
        <InfoLoc loc={loc} info={info} weblink={weblink} />
        <div className="lg:border-r lg:pr-9 md:col-span-3 pt-8">
          <h2 className="text-xl md:text-3xl font-cbold text-blue-950 pb-4">
            <Trans i18nKey="Maldives.facil">Facilities</Trans>
          </h2>
          <div className="grid grid-cols-2 sm:grid-cols-3 max-md:gap-y-4 md:grid-cols-5 [&>*:not(:last-child)]:pr-8 [&>*:not(:first-child)]:pl-8 odd:[&>*]:border-r sm:[&>*]:border-r sm:[&>*:nth-child(3)]:border-none sm:[&>*:last-child]:border-none md:[&>*:nth-child(4)]:border-l">
            <div className="flex flex-col gap-4 items-center">
              <div className="text-gold text-4xl">
                <LuWaves />
              </div>
              <div className="text-zinc-800 text-center">
                <Trans i18nKey="Maldives.facil-1">
                  Beautiful white sandy beaches
                </Trans>
              </div>
            </div>
            <div className="flex flex-col gap-4 items-center">
              <div className="text-gold text-4xl">
                <BiSwim />
              </div>
              <div className="text-zinc-800 text-center">
                <Trans i18nKey="Maldives.facil-2">
                  750 Sq.m. infinity pool
                </Trans>
              </div>
            </div>
            <div className="flex flex-col gap-4 items-center">
              <div className="text-gold text-4xl">
                <AiOutlineMedicineBox />
              </div>
              <div className="text-zinc-800 text-center">
                <Trans i18nKey="Maldives.facil-3">
                  24 Hours Medical clinic
                </Trans>
              </div>
            </div>

            <div className="flex flex-col gap-4 items-center">
              <div className="text-gold text-4xl">
                <MdOutlineSportsTennis />
              </div>
              <div className="text-zinc-800 text-center">
                <Trans i18nKey="Maldives.facil-4">Activities Center</Trans>
              </div>
            </div>
            <div className="flex flex-col gap-4 items-center">
              <div className="text-gold text-4xl">
                <PiFlowerLotusLight />
              </div>
              <div className="text-zinc-800 text-center">
                <Trans i18nKey="Maldives.facil-5">Devarana Spa</Trans>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
