import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useTranslation, Trans } from "react-i18next";

export default function OperationPerformance(props) {
  const { i18n } = useTranslation();

  const [data, setData] = useState([]);

  const populateREITData = async () => {
    const response = await Axios.get(
      process.env.REACT_APP_API_URI + "/Financial/GetOperationPerformance"
    ).then((res) => {
      const data = res.data;
      setData(data);
    });
  };

  useEffect(() => {
    populateREITData();
  }, [i18n.language]);

  return (
    <div>
      <table className="w-full table-auto min-w-max">
        <thead>
          <tr className="text-blue-900 bg-neutral-50">
            <th className="w-1/2 p-2 "></th>
            {data.map((val, i) => (
              <th className="w-[10%] p-2 text-right sticky top-0">
                {val.financialYear}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr className="bg-neutral-100 font-cbold">
            <td className="w-1/2 h-[40px]">
              <Trans i18nKey="REITPerformance.Topic1">
                Operation Performance (Thousand Baht)
              </Trans>
            </td>
            {data.map((val, i) => (
              <td className="p-2 text-right"></td>
            ))}
          </tr>
          <tr className="border-b border-gray-100">
            <td className="w-1/2">
              <Trans i18nKey="REITPerformance.Finance1">Rental income</Trans>
            </td>
            {data.map((val, i) => (
              <td className="p-2 text-right">{val.rentalIncome}</td>
            ))}
          </tr>
          <tr className="border-b border-gray-100">
            <td className="w-1/2">
              <Trans i18nKey="REITPerformance.Finance2">Interest income</Trans>
            </td>
            {data.map((val, i) => (
              <td className="p-2 text-right">{val.interestIncome}</td>
            ))}
          </tr>
          <tr className="border-b border-gray-100">
            <td className="w-1/2">
              <Trans i18nKey="REITPerformance.Finance3">Other income</Trans>
            </td>
            {data.map((val, i) => (
              <td className="p-2 text-right">{val.otherIncome}</td>
            ))}
          </tr>
          <tr className="border-b border-gray-100">
            <td className="w-1/2">
              <Trans i18nKey="REITPerformance.Finance4">Total income</Trans>
            </td>
            {data.map((val, i) => (
              <td className="p-2 text-right">{val.totalIncome}</td>
            ))}
          </tr>
          <tr className="border-b border-gray-100">
            <td className="w-1/2">
              <Trans i18nKey="REITPerformance.Finance5">Total expenses</Trans>
            </td>
            {data.map((val, i) => (
              <td className="p-2 text-right">{val.totalExpenses}</td>
            ))}
          </tr>
          <tr className="border-b border-gray-100">
            <td className="w-1/2">
              <Trans i18nKey="REITPerformance.Finance6">Finance costs</Trans>
            </td>
            {data.map((val, i) => (
              <td className="p-2 text-right">{val.financeCosts}</td>
            ))}
          </tr>
          <tr className="border-b border-gray-100">
            <td className="w-1/2">
              <Trans i18nKey="REITPerformance.Finance7">
                Net profit on investments
              </Trans>
            </td>
            {data.map((val, i) => (
              <td className="p-2 text-right">{val.netProfitOnInvestments}</td>
            ))}
          </tr>
          <tr className="border-b border-gray-100">
            <td className="w-1/2">
              <Trans i18nKey="REITPerformance.Finance8">
                Net gain (loss) on investment
              </Trans>
            </td>
            {data.map((val, i) => (
              <td className="p-2 text-right">{val.netGainOnInvestment}</td>
            ))}
          </tr>
          <tr className="border-b border-gray-100">
            <td className="w-1/2">
              <Trans i18nKey="REITPerformance.Finance9">
                Net gain on changes in fair value of investments
              </Trans>
            </td>
            {data.map((val, i) => (
              <td className="p-2 text-right">
                {val.netGainOnChangesInFairValue}
              </td>
            ))}
          </tr>
          <tr className="border-b border-gray-100">
            <td className="w-1/2">
              <Trans i18nKey="REITPerformance.Finance10">
                Net realised gain (loss) from sale of investments in securities
              </Trans>
            </td>
            {data.map((val, i) => (
              <td className="p-2 text-right">{val.netRealisedGainFromSale}</td>
            ))}
          </tr>
          <tr className="border-b border-gray-100">
            <td className="w-1/2">
              <Trans i18nKey="REITPerformance.Finance11">
                Net realised gain (loss) from investments in freehold and
                leasehold properties
              </Trans>
            </td>
            {data.map((val, i) => (
              <td className="p-2 text-right">
                {val.netRealisedGainFromInvestments}
              </td>
            ))}
          </tr>
          <tr className="border-b border-gray-100">
            <td className="w-1/2">
              <Trans i18nKey="REITPerformance.Finance12">
                Net unrealised gain (loss) from investments in freehold and
                leasehold properties
              </Trans>
            </td>
            {data.map((val, i) => (
              <td className="p-2 text-right">
                {val.netUnrealisedGainFromInvestmentsInFreehold}
              </td>
            ))}
          </tr>
          <tr className="border-b border-gray-100">
            <td className="w-1/2">
              <Trans i18nKey="REITPerformance.Finance13">
                Net unrealised gain (loss) from investments in securities
              </Trans>
            </td>
            {data.map((val, i) => (
              <td className="p-2 text-right">
                {val.netUnrealisedGainFromInvestmentsInSecurities}
              </td>
            ))}
          </tr>
          <tr className="border-b border-gray-100">
            <td className="w-1/2">
              <Trans i18nKey="REITPerformance.Finance14">
                The effect of changes in foreign exchange rates
              </Trans>
            </td>
            {data.map((val, i) => (
              <td className="p-2 text-right">
                {val.theEffectOfChangesInForeignExchangeRates}
              </td>
            ))}
          </tr>
          <tr className="border-b border-gray-100">
            <td className="w-1/2">
              <Trans i18nKey="REITPerformance.Finance15">
                Net increase in net assets resulting from operations
              </Trans>
            </td>
            {data.map((val, i) => (
              <td className="p-2 text-right">
                {val.netIncreaseInNetAssetsResulting}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
}
