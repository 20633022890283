import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useTranslation, Trans } from "react-i18next";
import Asset from "./assetsperform/Asset";
import NavbarWpicture from "../components/navbarWpicture";

export default function AssetPerform(props) {
  const { i18n } = useTranslation();
  const navitems = [
    {
      title: "REIT Performance",
      title_th: "ผลการดำเนินงานของกองทุน",
      active: false,
      link: "/Investor_Relations/Financial_Information/REIT_Performance",
    },
    {
      title: "Asset Performance",
      title_th: "ผลการดำเนินงานของอสังหาริมทรัพย์",
      active: false,
      link: "/Investor_Relations/Financial_Information/Asset_Performace",
    },
    {
      title: "Financial Statement",
      title_th: "งบการเงิน",
      active: false,
      link: "/Investor_Relations/Financial_Information/Financial_Statement",
    },
    {
      title: "Management Discussion and Analysis",
      title_th: "คำอธิบายและการวิเคราะห์ของฝ่ายจัดการ",
      active: false,
      link: "/Investor_Relations/Financial_Information/Management_Discussion_and_Analysis",
    },
    {
      title: "Appraisal Report",
      title_th: "รายงานประเมินมูลค่าทรัพย์สิน",
      active: false,
      link: "/Investor_Relations/Financial_Information/Appraisal_Report",
    },
    {
      title: "Unit NAV",
      title_th: "มูลค่าสินทรัพย์สุทธิ(NAV)",
      active: false,
      link: "/Investor_Relations/Financial_Information/Unit_NAV",
    },
  ];

  const [navFix, setFixed] = useState(false);

  const updatefixed = (fix) => {
    setFixed(fix);
  };

  const [data, setData] = useState([]);

  const [selectedAsset, setSelectedAsset] = useState("");
  const [selectedYear, setSelectedYear] = useState("");

  const populateAssetsData = async () => {
    const response = await Axios.get(
      process.env.REACT_APP_API_URI +
        "/Financial/GetAssetsPerformance?assetCode=" +
        selectedAsset +
        "&fnYear=" +
        selectedYear
    ).then((res) => {
      setData(res.data);
    });
  };

  useEffect(() => {
    populateAssetsData();
  }, [i18n.language, selectedAsset, selectedYear]);

  const year = () => {
    let arrY = [];
    for (var y = new Date().getFullYear(); y > 2017; y--) {
      arrY.push(y);
    }
    return arrY;
  };

  return (
    <section className="assetperform">
      <div className="absolute top-[90px] left-0 w-screen">
        <div className="w-full max-w-[1920px] max-h-[280px] overflow-hidden mx-auto">
          <img
            src={require("./img/financeCover.png")}
            alt="cover"
            className="object-cover w-full"
          />
        </div>
      </div>
      <div className="mb-[50px] md:mb-[50px] mt-[350px]">
        <NavbarWpicture navitems={navitems} fixed={updatefixed} />
      </div>
      <div>
        <h1
          className={`text-xl md:text-2xl font-cbold text-blue-950 mb-6 ${
            navFix ? "mt-[500px]" : ""
          }`}
        >
          <Trans i18nKey="AssetPerform.header">Assets Performance</Trans>
        </h1>
        <div className="flex gap-2 mb-6 flex-col md:flex-row w-full md:w-3/4">
          <div className="w-3/4 md:w-auto grow">
            <label htmlFor="selectBox" className=" text-gray-700">
              <Trans i18nKey="AssetPerform.select">Assets:</Trans>
            </label>
            <select
              id="selectBoxAsset"
              name="selectBoxAsset"
              onChange={(e) => setSelectedAsset(e.target.value)}
              className="appearance-none w-[200px] pl-2 border-b-2 border-gold rounded-none rounded-se-md focus:outline-none focus:ring focus:ring-gold/60 focus:ring-offset-1"
            >
              <option value="">
                <Trans i18nKey="AssetPerform.all">All Assets</Trans>
              </option>
              <option value="DPhuket">
                <Trans i18nKey="AssetPerform.phuket">
                  Dusit Thani Laguna Phuket
                </Trans>
              </option>
              <option value="DHuaHin">
                <Trans i18nKey="AssetPerform.huahin">Dusit Thani Hua Hin</Trans>
              </option>
              <option value="DMaldives">
                <Trans i18nKey="AssetPerform.maldives">
                  Dusit Thani Maldives
                </Trans>
              </option>
            </select>
          </div>
          <div className="w-3/4 md:w-auto grow">
            <label htmlFor="selectBox" className="text-gray-700">
              <Trans i18nKey="AssetPerform.year">Year:</Trans>
            </label>
            <select
              id="selectBox"
              name="selectBox"
              onChange={(e) => setSelectedYear(e.target.value)}
              className="appearance-none w-[200px] pl-2 border-b-2 border-gold rounded-none rounded-se-md focus:outline-none focus:ring focus:ring-gold/60 focus:ring-offset-1"
            >
              <option value="" selected>
                <Trans i18nKey="AssetPerform.5Y">5 Years Historical Data</Trans>
              </option>
              {year().map((y, i) => (
                <option value={y} key={"year-" + i}>
                  {y}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="table-performance">
          {data.map((a, i) =>
            a.assetCode == "D2CM" ? null : (
              <div className="flex" key={i}>
                <Asset
                  selectedName={a.assetName}
                  selectedYear={selectedYear}
                  selectedAsset={a.assetCode}
                />
              </div>
            )
          )}
        </div>
      </div>
    </section>
  );
}
