import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useTranslation, Trans } from "react-i18next";
import { TECollapse } from "tw-elements-react";
import ShareholderDetails from "./ShareholderDetails";
export default function Shareholder({ fnYear, groupCode }) {
  const { i18n } = useTranslation();
  const [data, setData] = useState([]);
  const [show, setShow] = useState(null);

  const toggleShow = (value) => {
    setShow({ ...show, ...value });
  };

  const populateShareholderDescData = async () => {
    const response = await Axios.get(
      process.env.REACT_APP_API_URI +
        "/Unitholder/GetShareholderMainFile?fnYear=" +
        fnYear +
        "&gCode=" +
        groupCode +
        "&lng=" +
        i18n.language
    ).then((res) => {
      const data = res.data;
      setData(data);

      const initialShow = {};
      for (let i = 0; i < data.length; i++) {
        initialShow[`collapse${i + 1}`] = false;
      }
      setShow(initialShow);
    });
  };

  useEffect(() => {
    populateShareholderDescData();
  }, [i18n.language, fnYear, groupCode]);

  return (
    <div>
      {data.map((val, i) => (
        <div
          key={i}
          className="rounded-t-lg border border-neutral-200 bg-white dark:border-neutral-600 dark:bg-neutral-800"
        >
          <h2 className="mb-0" id={`heading${i}`}>
            <button
              className={`${
                show[`collapse${i}`]
              } group relative flex w-full items-center rounded-t-[15px] border-0 px-5 py-4 text-left text-base transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none dark:bg-neutral-800 dark:text-white`}
              type="button"
              onClick={() =>
                toggleShow({ ...show, [`collapse${i}`]: !show[`collapse${i}`] })
              }
              aria-expanded={show[`collapse${i}`]}
              aria-controls={`collapse${i}`}
            >
              <span className="flex">
                <div className="w-auto text-l md:text-l">{val.title}</div>
              </span>
              <span
                className={`${
                  show[`collapse${i}`]
                    ? "rotate-[-180deg] -mr-1"
                    : "rotate-0 fill-[#212529] dark:fill-white"
                } ml-auto h-5 w-5 shrink-0 fill-[#336dec] transition-transform duration-200 ease-in-out motion-reduce:transition-none dark:fill-blue-300`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </span>
            </button>
          </h2>
          <TECollapse
            show={show[`collapse${i}`]}
            className="!mt-0 !rounded-b-none !shadow-none"
          >
            <div className="px-5 py-4">
              <ShareholderDetails
                key={i}
                fnYear={fnYear}
                groupCode={groupCode}
                subGroupCode={val.subGroupCode}
              />
            </div>
          </TECollapse>
        </div>
      ))}
    </div>
  );
}
