export const CookieEN = () => (
  <div>
    <h2 className="text-xl md:text-2xl font-cbold mb-2 w-fit whitespace-nowrap">
      Cookies Policy
    </h2>
    <p>
      Dusit Thani Properties REIT Company Limited (“Company”, “we”, “us”,
      “our”), would like to inform you that, the relevant information of your
      access to this website will be stored in the cookie format. This cookies
      policy explains the meaning, function, delete and reject cookies for your
      privacy. By accessing this website, you permit us to use cookies as
      follows:
    </p>
    <br />
    <div className="md:pl-4">
      <div className="flex w-full gap-2">
        <h2 className="text-lg md:text-xl font-cbold mb-2 w-fit whitespace-nowrap">
          What are Cookies
        </h2>
        <div className="border-b border-gold w-full mb-2"></div>
      </div>
      <p>
        Cookies are small text files that are placed on your computer and/or
        communication tool such as smartphones, tablets, etc. through a web
        browser when you visit our website without harming your computer and/or
        communication tools.
      </p>
      <br />
      <p>
        We use the necessary cookies to enhance your online experience. Cookies
        will collect your personal data: standard internet log, visitor behavior
        information and preferences to verify your language identity, your
        security data and personal interest in products and services. Also, to
        measure the amount of access to online services, remember your
        preferences, or for advertising and public relations purposes.
      </p>
      <br />
      <div className="flex w-full gap-2">
        <h2 className="text-lg md:text-xl font-cbold mb-2 w-fit whitespace-nowrap">
          How do we use Cookies
        </h2>
        <div className="border-b border-gold w-full mb-2"></div>
      </div>
      <p>
        We use Cookies in a range of ways to make our website works efficiently
        and improve your experience on our website, including understanding how
        you use our website.
      </p>
      <br />
      <div className="flex w-full gap-2">
        <h2 className="text-lg md:text-xl font-cbold mb-2 w-fit whitespace-nowrap">
          What type of Cookies we use
        </h2>
        <div className="border-b border-gold w-full mb-2"></div>
      </div>
      <p>
        <font className="font-cbold">Necessary Cookies</font> : These cookies
        are used for our website's work in order to specify display status in
        our website.
      </p>
      <p>
        <font className="font-cbold">Analytics / Performance Cookies</font> :
        These cookies are used for our website in order to acknowledge and keep
        statistics and behavior of web surfing.
      </p>
      <br />
      <div className="flex w-full gap-2">
        <h2 className="text-lg md:text-xl font-cbold mb-2 w-fit whitespace-nowrap">
          How can you manage Cookies
        </h2>
        <div className="border-b border-gold w-full mb-2"></div>
      </div>
      <p>
        You can delete or reject cookies that are set by setting cookies on your
        web browser by how to delete cookies from each web browser that you are
        using.
      </p>
    </div>
  </div>
);

export const CookieTH = () => (
  <div>
    <h2 className="text-xl md:text-2xl font-cbold mb-2 w-fit whitespace-nowrap">
      นโยบายคุกกี้
    </h2>
    <p>
      บริษัท ดุสิตธานี พร็อพเพอร์ตี้ส์ รีท จำกัด (“บริษัทฯ” “เรา” หรือ “ของเรา”)
      ขอแจ้งให้ท่านทราบว่า เมื่อท่านได้เข้าสู่เว็บไซต์ของเรา
      ข้อมูลที่เกี่ยวข้องกับการเข้าสู่เว็บไซต์ของท่านจะถูกเก็บเอาไว้ในรูปแบบของคุกกี้
      โดยนโยบายคุกกี้นี้จะอธิบายความหมาย การทำงาน วัตถุประสงค์ การลบ
      และการปฏิเสธการเก็บคุกกี้ เพื่อความเป็นส่วนตัวของท่าน ดังนั้น
      การเข้าสู่เว็บไซต์นี้ถือว่าท่านได้อนุญาตให้เราใช้คุกกี้ตามนโยบายคุกกี้ที่มีรายละเอียด
      ดังต่อไปนี้:
    </p>
    <br />
    <div className="md:pl-4">
      <div className="flex w-full gap-2">
        <h2 className="text-lg md:text-xl font-cbold mb-2 w-fit whitespace-nowrap">
          คุกกี้คืออะไร
        </h2>
        <div className="border-b border-gold w-full mb-2"></div>
      </div>
      <p>
        คุกกี้ คือ ไฟล์ขนาดเล็กเพื่อจัดเก็บข้อมูล
        โดยจะบันทึกลงในอุปกรณ์คอมพิวเตอร์
        และ/หรือเครื่องมือสื่อสารที่เข้าใช้งานของท่าน เช่น สมาร์ทโฟน แท็บเล็ต
        เป็นต้น ผ่านทางเว็บบราวน์เซอร์ในขณะที่ท่านเข้าสู่เว็บไซต์ของเรา
        โดยคุกกี้จะไม่ก่อให้เกิดอันตรายต่ออุปกรณ์คอมพิวเตอร์
        และ/หรือเครื่องมือสื่อสารของท่าน
      </p>
      <br />
      <p>
        ในกรณีนี้ ข้อมูลส่วนบุคคลของท่านจะถูกจัดเก็บ
        เพื่อใช้เพิ่มประสบการณ์การใช้งานบริการของเราทางออนไลน์
        โดยจะจำเอกลักษณ์ของภาษาและปรับแต่งข้อมูลการใช้งานตามความต้องการของท่าน
        โดยการเก็บข้อมูลนี้เพื่อเป็นการยืนยันคุณลักษณะเฉพาะตัว
        ข้อมูลความปลอดภัยของท่าน รวมถึงผลิตภัณฑ์และบริการที่ท่านสนใจ นอกจากนี้
        คุกกี้ยังถูกใช้เพื่อวัดปริมาณการเข้าใช้งานบริการทางออนไลน์
        การปรับเปลี่ยนเนื้อหาตามการใช้งานของท่านทั้งในก่อนหน้าและปัจจุบัน
        หรือเพื่อวัตถุประสงค์ในการโฆษณาและประชาสัมพันธ์
      </p>
      <br />
      <div className="flex w-full gap-2">
        <h2 className="text-lg md:text-xl font-cbold mb-2 w-fit whitespace-nowrap">
          เราใช้คุกกี้อย่างไร
        </h2>
        <div className="border-b border-gold w-full mb-2"></div>
      </div>
      <p>
        เราใช้คุกกี้เพื่อเพิ่มประสบการณ์และความพึงพอใจในการใช้บริการเว็บไซต์ของท่าน
        ซึ่งรวมถึงการให้บริการฟังก์ชันพื้นฐาน จดจำลักษณะการใช้งานเว็บไซต์
        ซึ่งทำให้เว็บไซต์ของเราเข้าถึงได้ง่ายและสะดวกยิ่งขึ้น
      </p>
      <br />
      <div className="flex w-full gap-2">
        <h2 className="text-lg md:text-xl font-cbold mb-2 w-fit whitespace-nowrap">
          การใช้งานคุกกี้
        </h2>
        <div className="border-b border-gold w-full mb-2"></div>
      </div>
      <p>
        <font className="font-cbold">คุกกี้ที่จำเป็น</font> :
        คุกกี้เหล่านี้ถูกใช้บนเว็บไซต์ของเราเพื่อระบุสถานะการแสดงผลบนเว็บไซต์ของเรา
      </p>
      <p>
        <font className="font-cbold">คุกกี้วิเคราะห์/ประสิทธิภาพ</font> :
        คุกกี้เหล่านี้ถูกใช้บนเว็บไซต์ของเราเพื่อรับทราบและเก็บรวบรวมค่าสถิติและพฤติกรรมการเยี่ยมชมเว็บไซต์
      </p>
      <br />
      <div className="flex w-full gap-2">
        <h2 className="text-lg md:text-xl font-cbold mb-2 w-fit whitespace-nowrap">
          การจัดการคุกกี้
        </h2>
        <div className="border-b border-gold w-full mb-2"></div>
      </div>
      <p>
        ท่านสามารถลบและปฏิเสธการเก็บคุกกี้ได้
        โดยการตั้งค่าการเก็บข้อมูลคุกกี้บนเว็บบราวน์เซอร์ตามวิธีการที่ระบุในแต่ละเว็บบราวน์เซอร์ที่ท่านใช้อยู่
      </p>
    </div>
  </div>
);
