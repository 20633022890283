import React, { useEffect, useRef } from "react";
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net";
import 'datatables.net-rowgroup';

export default function DataTableGroup({ data, dataColumn, dataDefs, dataOrder, dataGroup }) {
    const tableRef = useRef(null);

    useEffect(() => {
        let table;
        if (data) {
            if (tableRef.current && data.length > 0) {
                table = $(tableRef.current).DataTable({
                    data: data,
                    columns: dataColumn,
                    columnDefs: dataDefs,
                    dom: '<"top"f>rt<"bottom"p><"clear">',
                    lengthMenu: [10, 50, 100, 1000], // Set the available options for entries per page
                    pageLength: 10,
                    order: dataOrder,
                    rowGroup: {
                        dataSrc: dataGroup, // The column to group by
                        startRender: function (rows, group) {
                            // Add a custom class to the group label
                            return $('<tr/>')
                                .append('<td class="text-left font-bold text-blue-950 bg-neutral-200" colspan="' + dataColumn.length + '">' + group + '</td>');
                        },
                    },
                });
            }
            // Destroy the DataTable instance when the component unmounts
            return () => {
                if (table) {
                    table.destroy();
                }
            };
        }
    }, [data, dataColumn, dataDefs, dataOrder, dataGroup]);

    return (
        <div className="container mx-auto my-8">
            <table
                ref={tableRef}
                className="text-xs md:text-base lg:text-base stripe hover"
                style={{ width: "100%" }}
            >
                <thead>
                    <tr className="font-bold text-blue-950 bg-neutral-50">
                        {dataColumn.map((column, index) => (
                            <th key={index}>{column.title}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>

                </tbody>
            </table>
        </div>
    );
}
