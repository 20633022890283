import React from "react";

import { PiSignature } from "react-icons/pi";
import { BsTelephone } from "react-icons/bs";
import { IoLocationOutline } from "react-icons/io5";
import { TbWorldWww } from "react-icons/tb";
import { LiaFaxSolid } from "react-icons/lia";
import { useTranslation, Trans } from "react-i18next";

export default function ComContact(props) {
  const { i18n } = useTranslation();
  return (
    <div className="flex flex-col divide-y p-10 bg-zinc-100 w-full">
      <div className="flex gap-2 md:gap-6 pb-4 flex-col md:flex-row">
        <div className="flex gap-2 w-60">
            <div className="text-2xl text-blue-950">
                <PiSignature />
            </div>
            <div className="font-cbold text-blue-950">
                <Trans i18nKey="TrusteeInformation.CompanyTopic1">Name</Trans>:
            </div>
        </div>
        <div className="w-full">
            <Trans i18nKey="TrusteeInformation.CompanyName">Krung Thai Asset Management Public Company Limited</Trans>          
        </div>
      </div>
      <div className="flex gap-2 md:gap-6 py-4 flex-col md:flex-row">
        <div className="flex gap-2 w-60">
          <div className="text-2xl text-blue-950">
            <IoLocationOutline />
          </div>
          <div className="font-cbold text-blue-950">
                <Trans i18nKey="CompanyInformation.CompanyTopic2">Location of Head Office</Trans>:
          </div>
        </div>
        <div className="w-full">
            <Trans i18nKey="TrusteeInformation.CompanyAddress">
                No. 1 Empire Tower, 32th Floor, South Sathorn Road, Yannawa
                Sub-District, Sathorn District, Bangkok 10120
            </Trans>                    
        </div>
      </div>
      <div className="flex gap-2 md:gap-6 py-4 flex-col md:flex-row">
        <div className="flex gap-2 w-60">
            <div className="text-2xl text-blue-950">
                <BsTelephone />
            </div>
            <div className="font-cbold text-blue-950">
                <Trans i18nKey="CompanyInformation.CompanyTopic3">Telephone No.</Trans>:
            </div>
        </div>
        <div className="w-full">02-686-6100</div>
      </div>
      <div className="flex gap-2 md:gap-6 py-4 flex-col md:flex-row">
        <div className="flex gap-2 w-60">
            <div className="text-2xl text-blue-950">
                <LiaFaxSolid />
            </div>
            <div className="font-cbold text-blue-950">
                <Trans i18nKey="CompanyInformation.CompanyTopic4">Fax</Trans>:
            </div>
        </div>
        <div className="w-full">02-670-0430</div>
      </div>
    </div>
  );
}
