import React from "react";

import Dream from "./hlcard/dream";
import Well from "./hlcard/well";
import Manage from "./hlcard/manage";
import Growing from "./hlcard/growing";
import Expect from "./hlcard/expect";
import { useTranslation, Trans } from "react-i18next";

export default function Hilight(props) {
  const { i18n } = useTranslation();
  return (
    <section className="hilight bg-stone-50 flex flex-col items-center -mt-[0px] md:-mt-[80px] relative z-[100]">
      <div className="max-w-[1400px] mt-20">
        {/*<div className="text-gold md:text-lg font-cbold text-center">*/}
        {/*  SINCE 1995*/}
        {/*</div>*/}
        <div className="text-2xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl text-blue-950 font-cbold text-center">
          <Trans i18nKey="Investment.InvHead">Investment Highlights</Trans>
        </div>
        <div className="text-neutral-500"></div>
        <div className="flex gap-4 xl:gap-12 mb-12 max-lg:flex-col max-lg:items-center max-md:mt-4">
          <div>
            <Dream />
          </div>
          <div className="lg:pt-24">
            <Well />
          </div>
          <div className="lg:pt-48">
            <Manage />
          </div>
          <div className="lg:pt-24">
            <Growing />
          </div>
          <div>
            <Expect />
          </div>
        </div>
      </div>
    </section>
  );
}
