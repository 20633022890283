import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useTranslation, Trans } from "react-i18next";
import { BsCloudDownload } from "react-icons/bs";

import NavbarWpicture from "../components/navbarWpicture";

export default function Prospectus(props) {
  const { i18n } = useTranslation();
  const navitems = [
    {
      title: "Annual Report",
      title_th: "รายงานประจำปี",
      active: false,
      link: "/Investor_Relations/Publication_&_Download/Annual_Report",
    },
    {
      title: "Form 56-REIT",
      title_th: "แบบฟอร์ม 56-REIT",
      active: false,
      link: "/Investor_Relations/Publication_&_Download/Form_56-REIT",
    },
    {
      title: "Prospectus",
      title_th: "หนังสือชี้ชวน",
      active: true,
      link: "/Investor_Relations/Publication_&_Download/Prospectus",
    },
  ];

  /*
  const [data, setData] = useState([]);

  const populateREITData = async () => {
    const response = await Axios.get(
      process.env.REACT_APP_API_URI + "/Financial/GetFinancial"
    ).then((res) => {
      const data = res.data;
      setData(data);
    });
  };
  */

  useEffect(() => {
    //populateREITData();
  }, [i18n.language]);

  const [navFix, setFixed] = useState(false);

  const updatefixed = (fix) => {
    setFixed(fix);
  };

  const data = [
    {
      title: "Dusit Thani Freehold and Leasehold Real Estate Investment Trust",
      titleth: "ทรัสต์เพื่อการลงทุนในอสังหาริมทรัพย์และสิทธิการเช่าดุสิตธานี",
      sub: "Prospectus for the First Capital Increase",
      subth: "หนังสือชี้ชวนสำหรับการเพิ่มทุนครั้งที่หนึ่ง",
      download:
        "https://market.sec.or.th/public/ipos/IPOSEQ01.aspx?TransID=251523&lang=en",
    },
    {
      title: "Dusit Thani Freehold and Leasehold Real Estate Investment Trust",
      titleth: "ทรัสต์เพื่อการลงทุนในอสังหาริมทรัพย์และสิทธิการเช่าดุสิตธานี",
      sub: "Prospectus",
      subth: "หนังสือชี้ชวนเสนอขายหลักทรัพย์",
      download:
        "https://dreit.blob.core.windows.net/publication/prospectus-dreit-th.zip",
    },
  ];
  const FirstCapital_url =
    "https://market.sec.or.th/public/ipos/IPOSEQ01.aspx?TransID=251523&lang=en";
  const Prospectus_url =
    "https://dreit.blob.core.windows.net/publication/prospectus-dreit-th.zip";

  return (
    <section className="assetperform">
      <div className="absolute top-[90px] left-0 w-screen">
        <div className="w-full h-[280px] max-w-[1920px] overflow-hidden mx-auto">
          <img
            src={require("./img/publicCover.jpg")}
            alt="cover"
            className="object-cover w-full h-full object-center"
          />
        </div>
      </div>
      <div className="mb-[50px] md:mb-[50px] mt-[350px]">
        <NavbarWpicture navitems={navitems} fixed={updatefixed} />
      </div>
      <div>
        <h1
          className={`text-xl md:text-2xl font-cbold text-blue-950 mb-6 ${
            navFix ? "mt-[500px]" : ""
          }`}
        >
          <Trans i18nKey="Prospectus.header">Prospectus</Trans>
        </h1>
        <div className="py-4 grid grid-cols-1 gap-4 md:grid-cols-2">
          {data.map((val, i) => (
            <div className="w-full shadow-lg h-[200px] p-3 justify-between flex flex-col sm:flex-row sm:items-center gap-3 bg-white rounded-ee-md rounded-ss-md hover:bg-blue-900 hover:text-white hover:border-2 hover:border-gold">
              <div>
                <div className="font-cbold">
                  {i18n.language == "en" ? val.sub : val.subth}
                </div>
                <div className="text-stone-400">
                  {i18n.language == "en" ? val.title : val.titleth}
                </div>
              </div>
              <div className="py-3 px-6 md:px-4 lg:px-6 w-full sm:w-fit whitespace-nowrap border border-blue-900 bg-blue-900 text-white hover:text-blue-900 hover:bg-white">
                <a href={val.download} download target="_blank">
                  <div className="flex gap-2 items-center justify-center">
                    <BsCloudDownload />{" "}
                    <Trans i18nKey="Prospectus.download">Download File</Trans>
                  </div>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
