import React from "react";

import logo from "../img/DREIT_logo.svg";
import { useTranslation, Trans } from "react-i18next";

export default function Footer(props) {
  const { i18n } = useTranslation();
  return (
    <section className="footer bg-stone-50 w-full">
      <div className="max-w-[1400px] mx-auto max-2xl:px-5 py-8">
        <div className="flex max-md:flex-col gap-6">
          <div className="flex flex-col justify-between flex-auto">
            <div className="w-[110px] md:w-[130px]">
              <img src={logo} alt="dreit logo" />
            </div>
            <div className="mt-4 text-sm sm:text-base">
              <p>
                <Trans i18nKey="CompanyInformation.footer">
                  Investment in REIT contains certain risks, please study all
                  the provided information in prospectus before making
                  investment.
                </Trans>
              </p>
            </div>
          </div>
          <div className="md:w-1/3 lg:w-1/4 md:flex-auto max-md:order-last text-sm sm:text-base">
            <ul className="first:[&>*]:mt-0 [&>*]:md:mt-3 flex md:block justify-between">
              <li>
                <a className="cursor-pointer" href="/term_and_condition">
                  <Trans i18nKey="CompanyInformation.term">
                    Term and Condition
                  </Trans>
                </a>
              </li>
              <li className="md:hidden">|</li>
              <li>
                <a className="cursor-pointer" href="/cookie_policy">
                  <Trans i18nKey="CompanyInformation.cookie">
                    Cookie Policy
                  </Trans>
                </a>
              </li>
              <li className="md:hidden">|</li>
              <li>
                <a className="cursor-pointer" href="/privacy_policy">
                  <Trans i18nKey="CompanyInformation.privacy">
                    Privacy Policy
                  </Trans>
                </a>
              </li>
            </ul>
          </div>
          <div className="flex flex-col justify-between flex-auto text-sm sm:text-base">
            <h1 className="text-base md:text-xl font-cbold">
              <Trans i18nKey="CompanyInformation.CompanyName">
                Dusit Thani Properties REIT Co. Ltd.
              </Trans>
            </h1>
            <p>
              <Trans i18nKey="CompanyInformation.CompanyAddress">
                No. 319 Chamchuri Square Building, 29th Floor, Phayathai Road,
                Pathumwan, Bangkok 10330
              </Trans>
            </p>
            <p>
              <Trans i18nKey="CompanyInformation.CompanyPhone2">
                +66 2-200-9999 Ext. 3682,3684,3685,3686
              </Trans>
            </p>
            {/*<p>line id: @dreit</p>*/}
          </div>
        </div>
      </div>
      <div className="text-center py-2">
        <Trans i18nKey="CompanyInformation.footerline">
          For the convenience of investors, please feel free to reach us through
          our Line Official channel at Line ID
        </Trans>{" "}
        <span className="font-cbold">
          <a href="https://lin.ee/lIV8fdP">@dreit</a>
        </span>
      </div>
      <div className="border-t text-center py-2">
        Copyright &copy; Dusit Thani Freehold and Leasehold Real Estate
        Investment Trust
      </div>
    </section>
  );
}
