import React from "react";
import { useTranslation, Trans } from "react-i18next";
import GoogleMap from "google-map-react";

import { FaMapMarkerAlt } from "react-icons/fa";
import { BsTelephone } from "react-icons/bs";
import { IoLocationOutline } from "react-icons/io5";
import { IoMailOutline } from "react-icons/io5";
import { LiaFaxSolid } from "react-icons/lia";
import lineico from "../img/line-ico.svg";

export default function ConInfo(props) {
  return (
    <div className="border border-neutral-100 p-9 w-full md:w-2/5">
      <h1 className="text-3xl font-cbold text-blue-950">
        <Trans i18nKey="Contact.map">Contact Map</Trans>
      </h1>
      <div className="w-full h-[150px]">
        <GoogleMap
          bootstrapURLKeys={{
            key: "AIzaSyCIieFzAkVbQO3qqA8qUcVKy2MlHzWtsiw",
          }}
          defaultCenter={{ lat: 13.733008106295177, lng: 100.53071195911942 }}
          defaultZoom={16}
          zIndex={0}
        >
          <FaMapMarkerAlt
            lat={13.733008106295177}
            lng={100.53071195911942}
            className="text-xl text-red-500"
            text="See Map"
          />
        </GoogleMap>
      </div>
      <div className="mt-6">
        <h1 className="text-3xl font-cbold text-blue-950">
          <Trans i18nKey="Contact.info">Contact Infomation</Trans>
        </h1>
        <div className="flex gap-2 py-4 flex-col md:flex-row">
          <div className="flex gap-2 w-60">
            <div className="text-2xl text-zinc-800">
              <IoLocationOutline />
            </div>
            <div className="font-cbold text-zinc-800">
              <Trans i18nKey="CompanyInformation.CompanyTopic7">Company</Trans>:
            </div>
          </div>
          <div className="w-full">
            <Trans i18nKey="CompanyInformation.CompanyName1">
              Dusit Thani Properties REIT Company Limited
            </Trans>
            <Trans i18nKey="CompanyInformation.CompanyName2">
              (“REIT Manager”)
            </Trans>
          </div>
        </div>
        <div className="flex gap-2 py-4 flex-col md:flex-row">
          <div className="flex gap-2 w-60">
            <div className="text-2xl text-zinc-800">
              <IoLocationOutline />
            </div>
            <div className="font-cbold text-zinc-800">
              <Trans i18nKey="CompanyInformation.CompanyTopic8">Address</Trans>:
            </div>
          </div>
          <div className="w-full">
            <Trans i18nKey="CompanyInformation.CompanyAddress">
              No. 319 Chamchuri Square Building, 29th Floor, Phayathai Road,
              Pathumwan, Bangkok 10330
            </Trans>
          </div>
        </div>
        <div className="flex gap-2 py-4 flex-col md:flex-row">
          <div className="flex gap-2 w-60">
            <div className="text-2xl text-zinc-800">
              <BsTelephone />
            </div>
            <div className="font-cbold text-zinc-800">
              <Trans i18nKey="CompanyInformation.CompanyTopic9">
                Telephone No.
              </Trans>
              :
            </div>
          </div>
          <div className="w-full">
            <Trans i18nKey="CompanyInformation.CompanyPhone1">
              02-200-9999 extension 3681-3683
            </Trans>
          </div>
        </div>
        <div className="flex gap-2 py-4 flex-col md:flex-row">
          <div className="flex gap-2 w-60">
            <div className="text-2xl text-zinc-800">
              <LiaFaxSolid />
            </div>
            <div className="font-cbold text-zinc-800">
              <Trans i18nKey="CompanyInformation.CompanyTopic4">Fax</Trans>:
            </div>
          </div>
          <div className="w-full">02-636-3545</div>
        </div>
        <div className="flex gap-2 pt-4 flex-col md:flex-row">
          <div className="flex gap-2 w-60">
            <div className="text-2xl text-zinc-800">
              <IoMailOutline />
            </div>
            <div className="font-cbold text-zinc-800">
              <Trans i18nKey="CompanyInformation.CompanyTopic5">E-mail</Trans>:
            </div>
          </div>
          <div className="w-full cursor-pointer">
            <a href="mailto:ir@dtcreit.com">IR@dtcreit.com</a>
          </div>
        </div>
        <div className="flex gap-2 pt-4 flex-col md:flex-row">
          <div className="flex gap-2 w-60">
            <div className="w-6">
              <img src={lineico} alt="line-ico" className="object-cover" />
            </div>
            <div className="font-cbold text-zinc-800">
              <Trans i18nKey="CompanyInformation.lineID">Line ID</Trans>:
            </div>
          </div>
          <div className="w-full cursor-pointer">
            <a href="https://lin.ee/lIV8fdP">@dreit</a>
          </div>
        </div>
      </div>
    </div>
  );
}
