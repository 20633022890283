import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useTranslation, Trans } from "react-i18next";

import NavbarWpicture from "../components/navbarWpicture";

export default function Allotment(props) {
  const { i18n } = useTranslation();
  const navitems = [
    {
      title: "List of Major Unitholders",
      title_th: "รายชื่อผู้ถือหน่วยทรัสต์",
      active: false,
      link: "/Investor_Relations/Unitholders_Information/List_of_Major_Unitholders",
    },
    {
      title: "Shareholder Meeting and Operation Report",
      title_th: "การประชุมผู้ถือหน่วยทรัสต์และรายงานการดำเนินงาน",
      active: false,
      link: "/Investor_Relations/Unitholders_Information/Shareholder_Meeting",
    },
    {
      title: "Distribution Information",
      title_th: "การจ่ายผลตอบแทน",
      active: false,
      link: "/Investor_Relations/Unitholders_Information/Distribution_Information",
    },
    {
      title: "Distribution on Reduction Capital",
      title_th: "การจ่ายเงินลดทุน",
      active: false,
      link: "/Investor_Relations/Unitholders_Information/Distribution_on_Reduction_Capital",
    },
    {
      title: "Allotment of Additional Trust Units",
      title_th: "การจัดสรรหน่วยทรัสต์เพิ่มเติม",
      active: true,
      link: "/Investor_Relations/Unitholders_Information/Allotment_of_Additional_Trust_Units",
    },
  ];

  //const [data, setData] = useState([]);
  const [dataMain, setDataMain] = useState([]);
  const [dataAttach, setDataAttach] = useState([]);

  const populateAllotmentData = async () => {
    const response = await Axios.get(
      process.env.REACT_APP_API_URI +
        "/Unitholder/GetAllotment?lng=" +
        i18n.language
    ).then((res) => {
      const data = res.data;
      //setData(data);

      const dataMain = [];
      const dataAttach = [];
      data.forEach((item) => {
        if (item.typeName == "Main") dataMain.push(item);
        else dataAttach.push(item);
      });

      setDataMain(dataMain);
      setDataAttach(dataAttach);
    });
  };

  useEffect(() => {
    populateAllotmentData();
  }, [i18n.language]);

  const [navFix, setFixed] = useState(false);

  const updatefixed = (fix) => {
    setFixed(fix);
  };

  return (
    <section className="assetperform">
      <div className="absolute top-[90px] left-0 w-screen">
        <div className="w-full h-[280px] max-w-[1920px] overflow-hidden mx-auto">
          <img
            src={require("./img/unitholderCover.png")}
            alt="cover"
            className="object-cover w-full h-full object-center"
          />
        </div>
      </div>
      <div className="mb-[50px] md:mb-[50px] mt-[350px]">
        <NavbarWpicture navitems={navitems} fixed={updatefixed} />
      </div>
      <div>
        <h1
          className={`text-xl md:text-2xl font-cbold text-blue-950 mb-6 ${
            navFix ? "mt-[500px]" : ""
          }`}
        >
          <Trans i18nKey="Allotment.header">
            Allotment of Additional Trust Units
          </Trans>
        </h1>
      </div>
      <div className="block rounded-lg bg-neutral-50 text-center shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
        <div className="border-b-2 border-neutral-100 px-6 py-3 dark:border-neutral-600 dark:text-neutral-50 bg-blue-950 text-white">
          <h1>
            <Trans i18nKey="Allotment.header">
              Allotment of Additional Trust Units
            </Trans>
          </h1>
        </div>
        <div className="p-6">
          {dataMain.map((val, i) => (
            <div
              key={i}
              className={`w-full p-3 flex text-left text-l md:text-l ${
                val.subGroupCode > 0 ? "pl-10" : ""
              }`}
            >
              <a
                href={val.fileUrl}
                download={val.fileName}
                target="_blank"
                className="w-100 mr-5 flex items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                  />
                </svg>
              </a>
              <div className="w-auto">
                <span
                  className={` ${
                    val.subGroupCode > 0
                      ? "text-s md:text-sm"
                      : "text-base md:text-lg"
                  }`}
                >
                  {val.title}
                </span>
              </div>
            </div>
          ))}
          <div className="w-full p-3 flex text-left text-l md:text-l pt-10">
            <Trans i18nKey="Allotment.attach">Attachment</Trans>
          </div>
          {dataAttach.map((val, i) => (
            <div
              key={i}
              className={`w-full p-3 flex text-left text-l md:text-l ${
                val.subGroupCode > 0 ? "pl-10" : ""
              }`}
            >
              <a
                href={val.fileUrl}
                download={val.fileName}
                target="_blank"
                className="w-100 mr-5 flex items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                  />
                </svg>
              </a>
              <div className="w-auto">
                <span
                  className={` ${
                    val.subGroupCode > 0
                      ? "text-s md:text-sm"
                      : "text-base md:text-lg"
                  }`}
                >
                  {val.subGroupCode > 0
                    ? val.groupCode + "." + (val.fileNo - 1)
                    : val.groupCode}
                  . {val.title}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
