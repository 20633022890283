import React from "react";

import { FaRegAddressCard } from "react-icons/fa";
import { CiMoneyBill } from "react-icons/ci";
import { PiCurrencyCircleDollar, PiCoin } from "react-icons/pi";
import { FiShare2 } from "react-icons/fi";
import { BsCalendar4 } from "react-icons/bs";
import { useTranslation, Trans } from "react-i18next";

export default function ComFact(props) {
  const { i18n } = useTranslation();
  return (
    <div className="grid grid-cols-2 md:grid-cols-3 gap-y-5 w-full lg:w-1/2">
      <div className="flex flex-col items-center justify-center py-5 border-r px-5">
        <div className="text-gold text-3xl">
          <FaRegAddressCard />
        </div>
        <h6 className="font-cbold text-zinc-800 text-center">
          <Trans i18nKey="CompanyInformation.IconTopic1">
            Company Registration ID
          </Trans>
        </h6>
        <p className="text-neutral-500 text-center">0105560138818</p>
      </div>
      <div className="flex flex-col items-center justify-center py-5 md:border-r px-5">
        <div className="text-gold text-3xl">
          <CiMoneyBill />
        </div>
        <h6 className="font-cbold text-zinc-800 text-center">
          <Trans i18nKey="CompanyInformation.IconTopic2">
            Registered Capital (Baht)
          </Trans>
          <Trans i18nKey="CompanyInformation.IconTopicUnit1">baht</Trans>
        </h6>
        <p className="text-neutral-500 text-center">22,000,000</p>
      </div>
      <div className="md:hidden col-span-2 border-t"></div>
      <div className="flex flex-col items-center justify-center py-5 border-r md:border-r-0">
        <div className="text-gold text-3xl">
          <PiCurrencyCircleDollar />
        </div>
        <h6 className="font-cbold text-zinc-800 text-center">
          <Trans i18nKey="CompanyInformation.IconTopic3">
            Paid-up Capital (Baht)
          </Trans>
          <Trans i18nKey="CompanyInformation.IconTopicUnit1">baht</Trans>
        </h6>
        <p className="text-neutral-500 text-center">22,000,000</p>
      </div>
      <div className="hidden md:block col-span-3 border-t"></div>
      <div className="flex flex-col items-center justify-center py-5 md:border-r">
        <div className="text-gold text-3xl">
          <FiShare2 />
        </div>
        <h6 className="font-cbold text-zinc-800 text-center">
          <Trans i18nKey="CompanyInformation.IconTopic4">
            Paid-up Share (share)
          </Trans>
        </h6>
        <p className="text-neutral-500 text-center">2,200,000</p>
      </div>
      <div className="md:hidden col-span-2 border-t"></div>
      <div className="flex flex-col items-center justify-center py-5 border-r">
        <div className="text-gold text-3xl">
          <PiCoin />
        </div>
        <h6 className="font-cbold text-zinc-800 text-center">
          <Trans i18nKey="CompanyInformation.IconTopic5">
            Par Value (Baht)
          </Trans>
          <Trans i18nKey="CompanyInformation.IconTopicUnit1">baht</Trans>
        </h6>
        <p className="text-neutral-500 text-center">10</p>
      </div>
      <div className="flex flex-col items-center justify-center py-5">
        <div className="text-gold text-3xl">
          <BsCalendar4 />
        </div>
        <h6 className="font-cbold text-zinc-800 text-center">
          <Trans i18nKey="CompanyInformation.IconTopic6">
            Accounting Period
          </Trans>
        </h6>
        <p className="text-neutral-500 text-center">
          1 January {"–"} 31 December
        </p>
      </div>
    </div>
  );
}
