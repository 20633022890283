import React from "react";

import Navbar from "../components/navbar";

import ComFact from "./trustee/comfact";
import ComContact from "./trustee/comcontact";
import { useTranslation, Trans } from "react-i18next";

export default function Trustee(props) {
  const { i18n } = useTranslation();
  const navitems = [
    {
      title: "Trustee Information",
      title_th: "ข้อมูลบริษัททรัสตี",
      active: true,
      link: "/Management/Trustee_Inforamtion/General_Information",
    },
  ];
  return (
    <section className="lessee-and-sub-lessee">
      <div className="mb-[150px] md:mb-[170px]">
        <Navbar navitems={navitems} />
      </div>
      <div>
        <h1 className="text-xl md:text-2xl font-cbold text-blue-950 pb-4">
          <Trans i18nKey="TrusteeInformation.Topic">Trustee Information</Trans>
        </h1>
        <p className="md:text-lg">
          <Trans i18nKey="TrusteeInformation.detail">
            Trustee has the duties to follow up, supervise and check the REIT
            manager to manage the Trust in accordance with the trust deed and
            related laws. Trustee will attend every trust unitholder's meeting.
            If there is a request for a resolution from the trust unitholders'
            meeting, the trustee shall answer questions and give opinions on
            whether the operation is in accordance with the trust deed or not.
            In addition, the trustee is responsible for reporting to the
            Securities and Exchange Commission (SEC) If there is an event that
            affects the damage to the Trust, or the REIT manager fails to comply
            with the trust deed and relevant laws.
          </Trans>
        </p>
      </div>
      <div className="flex flex-col items-center lg:flex-row gap-6 mt-6 lg:justify-between py-6">
        <ComFact />
        <div className="order-first lg:order-last w-full lg:w-1/2">
          <ComContact />
        </div>
      </div>
    </section>
  );
}
