import React, { useEffect, useState } from "react";
import Chart from "react-google-charts";
import Axios from "axios";
import { useTranslation, Trans } from "react-i18next";

export default function Performance({ selectedAsset, selectedYear }) {
  const { i18n } = useTranslation();

  const [chartdata, setChartData] = useState([]);

  const populatePerformanceData = async (assetCode, fYear) => {
    const response = await Axios.get(
      process.env.REACT_APP_API_URI +
        "/Financial/GetPerformance?assetCode=" +
        assetCode +
        "&fnYear=" +
        fYear
    ).then((res) => {
      const data = res.data;

      const modifiedData = data.map((item) => {
        return {
          fyear: item.chartYear,
          revenue: item.revenue,
          ebitda: item.ebitda,
        };
      });

      if (fYear !== "") {
        setChartData(modifiedData);
      } else {
        setChartData(modifiedData.reverse());
      }
    });
  };

  useEffect(() => {
    populatePerformanceData(selectedAsset, selectedYear);
  }, [i18n.language, selectedAsset, selectedYear]);

  const options = {
    title: "",
    hAxis: {
      title: "",
      format: "####", // Display only the year in the hAxis labels
    },
    series: {
      0: { type: "bars", targetAxisIndex: 0 },
      1: { type: "bars", targetAxisIndex: 0 },
    },
    vAxis: {
      0: { title: "" },
    },
  };

  return (
    <div className="chart-container">
      <Chart
        width={"100%"}
        height={"400px"}
        chartType="ComboChart"
        data={[
          ["Year", "Revenue", "EBITDA"], // Define column headers
          ...chartdata.map((item) => [item.fyear, item.revenue, item.ebitda]),
        ]}
        options={options}
      />
    </div>
  );
}
