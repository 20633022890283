import React, { useState, useEffect, useRef } from 'react';
import PdfViewer from './PdfViewer';
import { TEModal, TEModalDialog, TEModalContent, TEModalHeader, TEModalBody, TEModalFooter } from "tw-elements-react";

const PdfModal = ({ pdfObj, isOpen, onClose }) => {
    const iframeRef = useRef(null);
    const [iframeH, setiframeH] = useState(720);

    useEffect(() => {
        if (isOpen && iframeRef.current) {
            const modalContent = document.querySelector('.TEModalContent');
            if (modalContent) {
                const availableHeight = modalContent.clientHeight;
                iframeRef.current.style.height = `${availableHeight - 60}px`;
                setiframeH(availableHeight - 20);
            }
        }
    }, [isOpen]);

    if (!isOpen) {
        return null;
    }
    
    return (
        <div>
            {/* Use TEModal component here */}
            <TEModal show={isOpen} setShow={onClose}>
                <TEModalDialog size="xl">
                    <TEModalContent>
                        <TEModalHeader>
                            <h5 className="text-xl font-medium leading-normal text-neutral-800 dark:text-neutral-200">
                                {pdfObj.title}
                            </h5>
                            <button
                                type="button"
                                className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                onClick={onClose}
                                aria-label="Close"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="h-6 w-6"
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </TEModalHeader>
                        <TEModalBody>
                            <iframe
                                ref={iframeRef} // Reference to the iframe
                                id="pdfIframe"
                                src={pdfObj.url}
                                width="100%"
                                height={iframeH} // Initial height (can be adjusted)
                            ></iframe>
                            {/*<PdfViewer pdfObj={pdfObj} />*/}
                        </TEModalBody>
                        <TEModalFooter>
                            <button
                                type="button"
                                className="inline-block rounded bg-primary-100 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:bg-primary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200"
                                onClick={onClose}
                            >
                                Close
                            </button>
                        </TEModalFooter>
                    </TEModalContent>
                </TEModalDialog>
            </TEModal>
        </div>
    );
};

export default PdfModal;