import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useTranslation, Trans } from "react-i18next";

import Navbar from "../components/navbar";

export default function Faq(props) {
  const { i18n } = useTranslation();
  const [data, setData] = useState([]);
  const populateFaqData = async () => {
    const response = await Axios.get(
      process.env.REACT_APP_API_URI + "/Faq/GetFaq?lng=" + i18n.language
    ).then((res) => {
      const dat = res.data.map((d, i) => {
        d.active = false;
        return d;
      });
      setData(dat);
    });
  };

  const navitems = [
    {
      title: "Frequently Asked Questions",
      title_th: "คำถามที่พบบ่อย",
      active: true,
      link: "/faq",
    },
  ];

  useEffect(() => {
    populateFaqData();
  }, [i18n.language]);

  useEffect(() => {
    //console.log(data);
  }, [data]);

  const showAns = (index) => {
    const dat = data.map((d, i) => {
      if (i == index) {
        if (!d.active) {
          d.active = true;
        } else {
          d.active = false;
        }
      } else {
        d.active = false;
      }
      return d;
    });
    setData(dat);
  };

  const [mousePos, setMousePos] = useState({});

  useEffect(() => {
    const handleMouseMove = (event) => {
      setMousePos({ x: event.clientX, y: event.clientY });
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const mouse = "top-[" + mousePos.y + "px]" + " left-[" + mousePos.x + "px]";

  return (
    <section className="faq">
      <div className="mb-[150px] md:mb-[170px]">
        <Navbar navitems={navitems} />
      </div>
      <div>
        <h1 className="text-xl md:text-2xl font-cbold text-blue-950 pb-4">
          <Trans i18nKey="FAQ.Topic">Frequently Asked Questions</Trans>
        </h1>
      </div>
      <div>
        <p className="md:text-xl text-neutral-500 w-full mt-4">
          <Trans i18nKey="FAQ.DescPage">
            The following frequently asked questions many investors have about
            DREIT at Dusit Thani Properties REIT.
          </Trans>
        </p>
      </div>
      <div className="my-10">
        {data.map((a, i) => (
          <div key={i} className="mb-10">
            <div className="bg-gold text-white font-cbold flex px-4 py-2 md:text-xl justify-between w-full md:w-2/3 rounded-xl items-center">
              {a.question}
              <div
                className="w-4 h-4 md:w-6 md:h-6 flex justify-center items-center cursor-pointer"
                onClick={() => showAns(i)}
              >
                <div
                  className={`bg-white w-4 h-1 md:w-6 ${
                    a.active == true ? "opacity-0" : ""
                  } transition duration-300`}
                ></div>
                <div
                  className={`bg-white w-1 h-[14px] md:h-5 -translate-x-2 md:-translate-x-3 ${
                    a.active == true ? "rotate-90 h-[16px]" : ""
                  } transition duration-300`}
                ></div>
              </div>
            </div>
            <div
              className={`pt-3 pl-2 md:pl-8 ${
                a.active == true ? "" : "hidden"
              }`}
              dangerouslySetInnerHTML={{ __html: a.answer }}
            />
          </div>
        ))}
      </div>
    </section>
  );
}
