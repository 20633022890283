import React from "react";

import { PiSignature } from "react-icons/pi";
import { BsTelephone } from "react-icons/bs";
import { IoLocationOutline } from "react-icons/io5";
import { TbWorldWww } from "react-icons/tb";
import { LiaFaxSolid } from "react-icons/lia";
import { MdOutlineAccountBalance } from "react-icons/md";
import { useTranslation, Trans } from "react-i18next";
export default function ComContact(props) {
  const { i18n } = useTranslation();
  return (
    <div className="flex flex-col divide-y p-10 bg-zinc-100 w-full">
      <div className="flex gap-2 md:gap-6 pb-4 flex-col md:flex-row">
        <div className="flex gap-2 w-60">
          <div className="text-2xl text-blue-950">
            <PiSignature />
          </div>
          <div className="font-cbold text-blue-950">
            <Trans i18nKey="HotelOperator.CompanyTopic1">Name</Trans>:
          </div>
        </div>
        <div className="w-full">
          <Trans i18nKey="HotelOperator.CompanyName">
            Dusit Thani Public Company Limited
          </Trans>
        </div>
      </div>
      <div className="flex gap-2 md:gap-6 py-4 flex-col md:flex-row">
        <div className="flex gap-2 w-60">
          <div className="text-2xl text-blue-950">
            <IoLocationOutline />
          </div>
          <div className="font-cbold text-blue-950">
            <Trans i18nKey="CompanyInformation.CompanyTopic2">
              Location of Head Office
            </Trans>
            :
          </div>
        </div>
        <div className="w-full">
          <Trans i18nKey="CompanyInformation.CompanyAddress">
            No. 319 Chamchuri Square Building, 29th Floor, Phayathai Road,
            Pathumwan, Bangkok 10330
          </Trans>
        </div>
      </div>
      <div className="flex gap-2 md:gap-6 py-4 flex-col md:flex-row">
        <div className="flex gap-2 w-60">
          <div className="text-2xl text-blue-950">
            <BsTelephone />
          </div>
          <div className="font-cbold text-blue-950">
            <Trans i18nKey="CompanyInformation.CompanyTopic3">
              Telephone No.
            </Trans>
            :
          </div>
        </div>
        <div className="w-full">02-200-9999</div>
      </div>
      <div className="flex gap-2 md:gap-6 py-4 flex-col md:flex-row">
        <div className="flex gap-2 w-60">
          <div className="text-2xl text-blue-950">
            <LiaFaxSolid />
          </div>
          <div className="font-cbold text-blue-950">
            <Trans i18nKey="CompanyInformation.CompanyTopic4">Fax</Trans>:
          </div>
        </div>
        <div className="w-full">02-636-3545</div>
      </div>
      <div className="flex gap-2 md:gap-6 py-4 flex-col md:flex-row">
        <div className="flex gap-2 w-60">
          <div className="text-2xl text-blue-950">
            <MdOutlineAccountBalance />
          </div>
          <div className="font-cbold text-blue-950">
            <Trans i18nKey="CompanyInformation.audit">Auditor</Trans>:
          </div>
        </div>
        <div className="w-full">
          <Trans i18nKey="LesseeSubLessee.audit">Auditor</Trans>
        </div>
      </div>
      <div className="flex gap-2 md:gap-6 pt-4 flex-col md:flex-row">
        <div className="flex gap-2 w-60">
          <div className="text-2xl text-blue-950">
            <TbWorldWww />
          </div>
          <div className="font-cbold text-blue-950">
            <Trans i18nKey="CompanyInformation.CompanyTopic6">Website</Trans>:
          </div>
        </div>
        <div className="w-full cursor-pointer">
          <a href="https://www.dusit.com">www.dusit.com</a>
        </div>
      </div>
    </div>
  );
}
