import React from "react";

import Navbar from "../components/navbar";
import { useTranslation, Trans } from "react-i18next";

export default function Trust(props) {
  const { i18n } = useTranslation();
  const navitems = [
    {
      title: "Overview",
      title_th: "ข้อมูลทั่วไป",
      active: false,
      link: "/About_Us/Overview",
    },
    {
      title: "Trust Structures",
      title_th: "โครงสร้างกองทรัสต์",
      active: true,
      link: "/About_Us/Trust_Structures",
    },
  ];
  return (
    <section>
      <div className="mb-[150px] md:mb-[170px]">
        <Navbar navitems={navitems} />
      </div>
      <div>
        <h1 className="text-xl md:text-2xl font-cbold text-blue-950 mb-6">
          <Trans i18nKey="TrustStructures.Topic">Trust Structures</Trans>
        </h1>
        <p>
          <Trans i18nKey="TrustStructures.Desc"></Trans>
        </p>
        <img
          src={
            "https://dreit.blob.core.windows.net/trust/" +
            (i18n.language == "en" ? "structure-en.png" : "structure-th.png")
          }
          alt="truststructure"
          className=" w-full md:w-[80%] mx-auto"
        />
      </div>
    </section>
  );
}
