import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useTranslation, Trans } from "react-i18next";

export default function FinancialRatios(props) {
  const { i18n } = useTranslation();

  const [data, setData] = useState([]);

  const populateREITData = async () => {
    const response = await Axios.get(
      process.env.REACT_APP_API_URI + "/Financial/GetFinancialRatios"
    ).then((res) => {
      const data = res.data;
      setData(data);
    });
  };

  useEffect(() => {
    populateREITData();
  }, [i18n.language]);

  return (
    <table className="w-full table-fixed border-collapse">
      <tbody>
        <tr className="font-cbold bg-neutral-100 h-[40px]">
          <td className="w-1/2">
            <Trans i18nKey="REITPerformance.Topic3">Financial Ratios</Trans>
          </td>
          {data.map((val, i) => (
            <td className="p-2 text-right"></td>
          ))}
        </tr>
        <tr className="border-b border-gray-100">
          <td className="w-1/2">
            <Trans i18nKey="REITPerformance.Finance23">
              Number of outstanding units at the end of period (units)
            </Trans>
          </td>
          {data.map((val, i) => (
            <td className="p-2 text-right w-[10%]">
              {val.numberOfOutstandingUnitsAtTheEndOfPeriod}
            </td>
          ))}
        </tr>
        <tr className="border-b border-gray-100">
          <td className="w-1/2">
            <Trans i18nKey="REITPerformance.Finance24">
              Net investment income (Baht per unit)
            </Trans>
          </td>
          {data.map((val, i) => (
            <td className="p-2 text-right">{val.netInvestmentIncome}</td>
          ))}
        </tr>
        <tr className="border-b border-gray-100">
          <td className="w-1/2">
            <Trans i18nKey="REITPerformance.Finance25">
              Net asset value (Baht per unit)
            </Trans>
          </td>
          {data.map((val, i) => (
            <td className="p-2 text-right">{val.netAssetValue}</td>
          ))}
        </tr>
        <tr className="border-b border-gray-100">
          <td className="w-1/2">
            <Trans i18nKey="REITPerformance.Finance26">
              Interest bearing debt to total assets ratio (%)
            </Trans>
          </td>
          {data.map((val, i) => (
            <td className="p-2 text-right">
              {val.interestBearingDebtToTotalAssetsRatio}
            </td>
          ))}
        </tr>
        <tr className="border-b border-gray-100">
          <td className="w-1/2">
            <Trans i18nKey="REITPerformance.Finance27">
              Total liabilities to total assets (%)
            </Trans>
          </td>
          {data.map((val, i) => (
            <td className="p-2 text-right">
              {val.totalLiabilitiesToTotalAssets}
            </td>
          ))}
        </tr>
        <tr className="border-b border-gray-100">
          <td className="w-1/2">
            <Trans i18nKey="REITPerformance.Finance28">
              Total liabilities to net assets value ratio (times)
            </Trans>
          </td>
          {data.map((val, i) => (
            <td className="p-2 text-right">
              {val.totalLiabilitiesToNetAssetsValueRatio}
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  );
}
