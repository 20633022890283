import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useTranslation, Trans } from "react-i18next";

import icon from "./img/annonce.svg";
import download from "./img/download.svg";

export default function Announce(props) {
  const { t, i18n } = useTranslation();

  const [data, setData] = useState([]);

  const populateFaqData = async () => {
    const response = await Axios.get(
      process.env.REACT_APP_API_URI +
        "/NewsReport/GetNewsReport?maxQuest=5&lng=" +
        i18n.language
    ).then((res) => {
      const data = res.data;
      setData(data);
    });
  };

  useEffect(() => {
    populateFaqData();
  }, [i18n.language]);
  
  return (
    <div
      className={`w-full h-72 md:w-96 md:h-96 px-8 py-6 bg-white flex-col justify-between items-center gap-4 inline-flex shadow-md shadow-gray-300`}
    >
      <div className="flex justify-between w-full">
        <div>
          <img
            src={icon}
            alt="card-ico"
            id="quote-ico"
            className="w-[60%] max-md:mx-auto md:w-[calc(100%-25%)] xl:w-fit"
          />
        </div>
        <div className="text-2xl  xl:text-4xl text-blue-950 font-cbold">
            {t('Home.Announce')}
        </div>
      </div>
      <div className="announce-list flex flex-col justify-between gap-3 md:gap-5 w-full max-sm:text-sm">
        {data.map((a, i) => (
          <div className="flex justify-between" key={i}>
            <div className="flex-1 pr-10 text-neutral-500 font-cbold truncate">
              {a.newsTitle}
            </div>
            <div className="w-25">
              <a href={a.newsURL}>
                <img src={download} />
              </a>
            </div>
          </div>
        ))}
        <div className="flex justify-between" key="0">
            <div className="flex-1 pr-10 text-neutral-500 font-cbold truncate">
            <a href="/Investor_Relations/Newsroom/SET_Announcements" className="text-blue-950">
                    <Trans i18nKey="Home.More">
                        More
                    </Trans>
                </a>
            </div>
        </div>
      </div>
    </div>
  );
}
