import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useTranslation, Trans } from "react-i18next";

export default function Message(props) {
  const initData = {
    firstname: "",
    lastname: "",
    address: "",
    phone: "",
    email: "",
    message: "",
    accept: false,
  };
  const [data, setData] = useState(initData);

  useEffect(() => {
    console.log(data);
    phoneVerify();
    emailVerify();
  }, [data]);

  const submit = async () => {
    setData(initData);

    const response = await Axios.post(
      process.env.REACT_APP_API_URI + "/Home/SendMessage",
      data
    );

    if (response.status === 200) {
      console.error("Completed:", "Success");
    } else {
      // Handle errors here.
      console.error("Error:", response.status);
    }
  };

  const [phoneFormat, setPhoneFormat] = useState(true);
  const [emailFormat, setEmailFormat] = useState(true);

  const phoneVerify = () => {
    if (data.phone != "") {
      let check = data.phone.match(/^\d{10}$/);
      setPhoneFormat(check);
    } else {
      setPhoneFormat(true);
    }
  };

  const emailVerify = () => {
    if (data.email != "") {
      let check = data.email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);
      setEmailFormat(check);
    } else {
      setEmailFormat(true);
    }
  };

  return (
    <div className="bg-neutral-100 w-full md:w-3/5 py-4 sm:py-8 px-4 sm:px-12">
      <h1 className="text-3xl text-blue-950 font-cbold">
        <Trans i18nKey="Contact.header">Contact Form</Trans>
      </h1>
      <p className="pt-3 pb-5 text-zinc-800">
        <Trans i18nKey="Contact.detail">
          Please provide your personal information for our response (Your
          information will be strictly kept confidential)
        </Trans>
      </p>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 text-zinc-800">
        <div>
          <label htmlFor="firstName" className="block mb-2">
            <Trans i18nKey="Contact.name">Name:</Trans>
          </label>
          <input
            id="firstName"
            className="bg-white px-2 py-1 w-full appearance-none border-2 border-white focus:outline-none focus:border-b-gold"
            onChange={(e) => setData({ ...data, firstname: e.target.value })}
            value={data.firstname}
          />
        </div>
        <div>
          <label htmlFor="lastName" className="block mb-2">
            <Trans i18nKey="Contact.surname">Surname:</Trans>
          </label>
          <input
            id="lastName"
            className="bg-white px-2 py-1 w-full appearance-none border-2 border-white focus:outline-none focus:border-b-gold"
            onChange={(e) => setData({ ...data, lastname: e.target.value })}
            value={data.lastname}
          />
        </div>
        <div className="sm:col-span-2">
          <label htmlFor="address" className="block mb-2">
            <Trans i18nKey="Contact.addr">Address</Trans>{" "}
            <font className="text-neutral-400">
              <Trans i18nKey="Contact.opt">(optional)</Trans>
            </font>
            :
          </label>
          <input
            id="address"
            className="bg-white px-2 py-1 w-full appearance-none border-2 border-white focus:outline-none focus:border-b-gold"
            onChange={(e) => setData({ ...data, address: e.target.value })}
            value={data.address}
          />
        </div>
        <div>
          <label htmlFor="Phone" className="block mb-2">
            <Trans i18nKey="Contact.mobile">Mobile:</Trans>
          </label>
          <input
            id="Phone"
            className="bg-white px-2 py-1 w-full appearance-none border-2 border-white focus:outline-none focus:border-b-gold"
            onChange={(e) => setData({ ...data, phone: e.target.value })}
            value={data.phone}
          />
          <span className={`${phoneFormat ? "hidden" : "block"} text-red-500`}>
            <Trans i18nKey="Contact.wmobile">
              Mobile number format is not right
            </Trans>
          </span>
        </div>
        <div>
          <label htmlFor="email" className="block mb-2">
            <Trans i18nKey="Contact.email">Email</Trans>{" "}
            <font className="text-neutral-400">
              <Trans i18nKey="Contact.opt">(optional)</Trans>
            </font>
            :
          </label>
          <input
            id="email"
            className="bg-white px-2 py-1 w-full appearance-none border-2 border-white focus:outline-none focus:border-b-gold"
            onChange={(e) => setData({ ...data, email: e.target.value })}
            value={data.email}
          />
          <span className={`${emailFormat ? "hidden" : "block"} text-red-500`}>
            <Trans i18nKey="Contact.wemail">Email format is not right</Trans>
          </span>
        </div>
        <div className="sm:col-span-2">
          <label htmlFor="message" className="block mb-2">
            <Trans i18nKey="Contact.message">Message:</Trans>
          </label>
          <textarea
            rows="3"
            id="message"
            className="bg-white px-2 py-1 w-full appearance-none border-2 border-white focus:outline-none focus:border-b-gold"
            onChange={(e) => setData({ ...data, message: e.target.value })}
            value={data.message}
          ></textarea>
        </div>
        <div className="sm:col-span-2 flex gap-2">
          <div>
            <input
              type="checkbox"
              id="accept"
              checked={data.accept}
              onChange={(e) => setData({ ...data, accept: e.target.checked })}
              className="w-4 h-4 accent-gold"
            />
          </div>
          <label htmlFor="accept">
            <Trans i18nKey="Contact.accept">
              I have read and accepted terms and conditions specified in the
              Privacy Statement and do hereby consent to the collecting,
              processing and/or disclosing of the personal data provided by me
              to fulfil the above-said purposes.
            </Trans>
          </label>
        </div>
        <div className="sm:col-span-2 flex justify-end">
          <div
            className="bg-gold text-white border-gold border shadow-gold shadow text-base sm:text-xl px-2 sm:px-5 py-2 hover:bg-white hover:text-gold max-md:w-full text-center"
            onClick={submit}
          >
            <Trans i18nKey="Contact.send">SEND US A MESSAGE</Trans>
          </div>
        </div>
      </div>
    </div>
  );
}
