import React, { useEffect, useState } from "react";
import Axios from "axios";
import DataTable from "../components/DataTable";
import { useTranslation, Trans } from "react-i18next";

import { BsCloudDownload } from "react-icons/bs";
import Navbar from "../components/navbar";

export default function SETAnnounce(props) {
  const { i18n, t } = useTranslation();
  const navitems = [
    {
      title: "SET Announcements",
      title_th: "ข่าวแจ้งตลาดหลักทรัพย์",
      active: true,
      link: "/Investor_Relations/Newsroom/SET_Announcements",
    },
    {
      title: "Press Releases",
      title_th: "ข่าวแจ้งสื่อมวลชน",
      active: false,
      link: "/Investor_Relations/Newsroom/Press_Releases",
    },
    {
      title: "News & Events",
      title_th: "ข่าวสารและกิจกรรม",
      active: false,
      link: "/Investor_Relations/Newsroom/News_&_Events",
    },
  ];

  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [data, setData] = useState([]);

  const handleSelectChange = async (e) => {
    const year = e.target.value;
    setSelectedYear(year);
  };

  const populateNewsData = async () => {
    const response = await Axios.get(
      process.env.REACT_APP_API_URI +
        "/NewsReport/GetNewsReportByYear?fnYear=" +
        selectedYear +
        "&lng=" +
        i18n.language
    ).then((res) => {
      const data = res.data;
      const modifiedData = data.map((item, i) => {
        return {
          index: i,
          newsDate: item.newsDate,
          newsTitle: item.newsTitle,
          newsURL: item.newsURL,
        };
      });

      setData(modifiedData);
    });
  };

  const dataColumn = [
    {
      title: "",
      data: "index",
      orderable: false,
      className: "hidden",
    },
    {
      title: t("SETAnnounce.date"),
      data: "newsDate",
      orderable: false,
    },
    {
      title: t("SETAnnounce.title"),
      data: "newsTitle",
      orderable: false,
    },
    {
      title: "",
      data: "index",
      orderable: false,
      className: "text-right",
      render: function (data, type, row) {
        // Render the 'Download' button in this column
        if (type === "display") {
          return `
            <div>
              <a
                href="${row.newsURL}"
                download
                class="sm:hidden text-neutral-400 hover:text-blue-950 flex justify-center"
              >
              <div class="w-1/3">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
</svg>

</div>
              </a>
              <a
                href="${row.newsURL}"
                download
                class="max-sm:hidden mt-[5px] text-center inline-block rounded bg-blue-950 border-2 border-blue-950 px-6 pb-2 pt-2.5 text-xs font-cbold uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-white hover:text-blue-950"
              >
                Download
              </a>
            </div>
          `;
        }
        return data;
      },
    },
  ];

  const dataDefs = [
    {
      targets: 0, // Target the second column (index 1)
      orderData: [0],
    },
    {
      targets: 2, // Index of the 'mDate' column
      width: "50%",
    },
  ];

  const dataOrder = [[0, "asc"]];

  const yearOptions = [];
  for (let year = currentYear; year > 2017; year--) {
    yearOptions.push(year);
  }

  useEffect(() => {
    populateNewsData();
  }, [i18n.language, selectedYear]);

  return (
    <section className="setannounce">
      <div className="mb-[150px] md:mb-[170px]">
        <Navbar navitems={navitems} />
      </div>
      <div>
        <h1 className="text-xl md:text-2xl font-cbold text-blue-950 pb-4">
          <Trans i18nKey="SETAnnounce.header">SET Announcements</Trans>
        </h1>
        <div className="flex gap-2 mb-6">
          <label htmlFor="selectBox" className="block text-gray-700">
            <Trans i18nKey="SETAnnounce.year">Year:</Trans>
          </label>
          <select
            id="selectBox"
            name="selectBox"
            onChange={handleSelectChange}
            className="appearance-none w-[100px] pl-2 border-b-2 border-gold rounded-none rounded-se-md focus:outline-none focus:ring focus:ring-gold/60 focus:ring-offset-1"
          >
            {yearOptions.map((y, i) => (
              <option value={y} key={"year-" + i}>
                {y}
              </option>
            ))}
          </select>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
            />
          </svg>
        </div>
      </div>
      <div className="table-performance">
        <DataTable
          data={data}
          dataColumn={dataColumn}
          dataDefs={dataDefs}
          dataOrder={dataOrder}
        />
      </div>
    </section>
  );
}
