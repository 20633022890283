import React, { useEffect, useState } from "react";
import GoogleMap from "google-map-react";
import { useTranslation, Trans } from "react-i18next";

import { FaMapMarkerAlt } from "react-icons/fa";
import { BsBoundingBox } from "react-icons/bs";
import { TbCategory } from "react-icons/tb";
import { PiMapPin } from "react-icons/pi";

export default function InfoLoc(props) {
  const { i18n } = useTranslation();
  const [loc, setLoc] = useState(null);
  const initInfo = { sqm: 0, room: 0 };
  const [info, setInfo] = useState(initInfo);
  const [weblink, setWeblink] = useState("");

  useEffect(() => {
    if (props.info) {
      setLoc(props.loc);
      setInfo(props.info);
      setWeblink(props.weblink);
    }
  }, [props]);

  const loadMap = () => {
    switch (loc) {
      case "phuket":
        return (
          <GoogleMap
            bootstrapURLKeys={{
              key: "AIzaSyCIieFzAkVbQO3qqA8qUcVKy2MlHzWtsiw",
            }}
            defaultCenter={{ lat: 8.001964197206023, lng: 98.29371616441783 }}
            defaultZoom={16}
            zIndex={0}
          >
            <FaMapMarkerAlt
              lat={8.001964197206023}
              lng={98.29371616441783}
              className="text-xl text-red-500"
              text="See Map"
            />
          </GoogleMap>
        );
      case "huahin":
        return (
          <GoogleMap
            bootstrapURLKeys={{
              key: "AIzaSyCIieFzAkVbQO3qqA8qUcVKy2MlHzWtsiw",
            }}
            defaultCenter={{ lat: 12.658029376832546, lng: 99.95628863769386 }}
            defaultZoom={16}
            zIndex={0}
          >
            <FaMapMarkerAlt
              lat={12.658029376832546}
              lng={99.95628863769386}
              className="text-xl text-red-500"
              text="See Map"
            />
          </GoogleMap>
        );
      case "maldives":
        return (
          <GoogleMap
            bootstrapURLKeys={{
              key: "AIzaSyCIieFzAkVbQO3qqA8qUcVKy2MlHzWtsiw",
            }}
            defaultCenter={{ lat: 5.206889428388943, lng: 73.08459900674656 }}
            defaultZoom={16}
            zIndex={0}
          >
            <FaMapMarkerAlt
              lat={5.206889428388943}
              lng={73.08459900674656}
              className="text-xl text-red-500"
              text="See Map"
            />
          </GoogleMap>
        );
      default:
        return null;
    }
  };

  return (
    <div className="max-lg:pt-9 lg:pl-9 md:row-span-2 grid grid-col-1 sm:grid-cols-3 lg:grid-cols-1">
      <div className="sm:col-span-3 lg:col-auto max-lg:mt-9 max-lg:order-last border border-gold shadow shadow-gold bg-gold text-white text-xl hover:bg-white hover:text-gold flex justify-center items-center">
        <a href={weblink} target="_blank">
          <Trans i18nKey="InfoLoc.booking">Booking Now</Trans>
        </a>
      </div>
      <div className="sm:col-span-2 lg:col-span-1 grid grid-cols-2 py-5">
        <div className="flex flex-col items-center border-r">
          <div className="text-gold text-2xl">
            <BsBoundingBox />
          </div>
          <h5 className="text-lg font-cbold text-zinc-800">
            <Trans i18nKey="InfoLoc.area">Total usable area</Trans>
          </h5>
          <p className="text-blue-900 font-cbold">{info.sqm}</p>
          <p className="text-neutral-400">
            <Trans i18nKey="InfoLoc.aUnit">square meters</Trans>
          </p>
        </div>
        <div className="flex flex-col items-center">
          <div className="text-gold text-2xl">
            <TbCategory />
          </div>
          <h5 className="text-lg font-cbold text-zinc-800">
            <Trans i18nKey="InfoLoc.room">No. of rooms</Trans>
          </h5>
          <p className="text-blue-900 font-cbold">{info.room}</p>
          <p className="text-neutral-400">
            <Trans i18nKey="InfoLoc.rUnit">rooms</Trans>
          </p>
        </div>
      </div>
      <div>
        <div className="block w-full h-24 relative z-10">{loadMap()}</div>
        <div className="flex gap-4 mt-4">
          <div className="text-gold text-2xl">
            <PiMapPin />
          </div>
          <div>{i18n.language == "en" ? info.addr : info.addrth}</div>
        </div>
      </div>
    </div>
  );
}
