import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useTranslation, Trans } from "react-i18next";

export default function ShareholderDetails({ fnYear, groupCode, subGroupCode }) {
    const { i18n } = useTranslation();
    const [data, setData] = useState([]);

    const populateShareholderDetailsData = async () => {
        const response = await Axios.get(
            process.env.REACT_APP_API_URI + "/Unitholder/GetShareholderSubFile?fnYear=" + fnYear + "&gCode=" + groupCode + "&sgCode=" + subGroupCode + "&lng=" + i18n.language
        ).then((res) => {
            const data = res.data;
            setData(data);
        });
    };

    useEffect(() => {
        populateShareholderDetailsData();
    }, [i18n.language, fnYear, groupCode, subGroupCode]);

    return (
        <div className="flex flex-col rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-800 text-l md:text-l">
            {data.map((val, i) => (
                <div key={ i } className={`w-full p-3 flex text-left text-l md:text-l ${val.fileNo > 1 ? 'pl-10' : ''}`}>
                    <a
                        href={val.fileUrl}
                        download={val.fileName}
                        target="_blank"
                        className="w-100 mr-5 flex items-center"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                        </svg>
                    </a>
                    <div className="w-auto">
                        <span className={` ${val.fileNo > 1 ? 'text-s md:text-sm' : 'text-base md:text-lg'}`}>{val.title}</span>
                    </div>                    
                </div>
            ))}
        </div>
    );
}