export const TermEN = () => (
  <div>
    <h2 className="text-xl md:text-2xl font-cbold mb-2 w-fit whitespace-nowrap">
      Term and Condition
    </h2>
    <p>
      Access to and use of this for Dusit Thani Freehold and Leasehold Real
      Estate Investment Trust site is subject to the laws of Thailand and the
      following terms and conditions:
    </p>
    <br />
    <div className="md:pl-4">
      <div className="flex w-full gap-2">
        <h2 className="text-lg md:text-xl font-cbold mb-2 w-fit whitespace-nowrap">
          Copyrights
        </h2>
        <div className="border-b border-gold w-full mb-2"></div>
      </div>
      <p>
        All copy rights on this site is reserved by Dusit Thani Freehold and
        Leasehold Real Estate Investment Trust (2019). All copy rights and other
        intellectual property rights in all text, images, sound, software and
        other materials on this site are owned by Dusit Thani Freehold and
        Leasehold Real Estate Investment Trust.
      </p>
      <br />
      <p>
        You are permitted to browse this site and to reproduce extracts by way
        of printing, downloading to a hard disk and by distribution to other
        people but, in all cases, for non-commercial and personal use only. No
        reproduction of any part of the site may be used for the purpose of
        business, commercial gain nor shall it be modified or incorporated in
        any other work, including but not limit to publication or site, whether
        in hard copy or electronic format, including posting to any other site.
      </p>
      <br />
      <div className="flex w-full gap-2">
        <h2 className="text-lg md:text-xl font-cbold mb-2 w-fit whitespace-nowrap">
          Trademark
        </h2>
        <div className="border-b border-gold w-full mb-2"></div>
      </div>
      <p>
        All trademarks displayed on this site are owned and used under license
        by Dusit Thani Freehold and Leasehold Real Estate Investment Trust.
      </p>
      <br />
      <div className="flex w-full gap-2">
        <h2 className="text-lg md:text-xl font-cbold mb-2 w-fit whitespace-nowrap">
          Content
        </h2>
        <div className="border-b border-gold w-full mb-2"></div>
      </div>
      <p>
        All information on this site is for general information purpose only. It
        should not be relied on for any specific purpose and no representation
        and warranty is given as regards its accuracy or completeness. Neither
        the Dusit Thani Freehold and Leasehold Real Estate Investment Trust nor
        its employees shall be liable for any loss, damage or expense arising
        out of any access to use if this site or any site linked to it. Dusit
        Thani Freehold and Leasehold Real Estate Investment Trust reserves the
        right to make any changes and corrections to this site as and when its
        appropriate and without prior notice.
      </p>
      <br />
      <div className="flex w-full gap-2">
        <h2 className="text-lg md:text-xl font-cbold mb-2 w-fit whitespace-nowrap">
          Linked Internet Sites
        </h2>
        <div className="border-b border-gold w-full mb-2"></div>
      </div>
      <p>
        At various point throughout the site, you may be offered automatic links
        to other internet sites relevant to a particular aspect of this site.
        This does not indicate that Dusit Thani Freehold and Leasehold Real
        Estate Investment Trust are necessarily associated with any of these
        other sites or their owners. Neither Dusit Thani Freehold and Leasehold
        Real Estate Investment Trust or its employees shall have any
        responsibility or liability for these other sites or any information
        contained in them. None of the information in these other sites has been
        verified or endorsed by Dusit Thani Freehold and Leasehold Real Estate
        Investment Trust.
      </p>
      <br />
      <div className="flex w-full gap-2">
        <h2 className="text-lg md:text-xl font-cbold mb-2 w-fit whitespace-nowrap">
          Privacy
        </h2>
        <div className="border-b border-gold w-full mb-2"></div>
      </div>
      <p>
        Dusit Thani Freehold and Leasehold Real Estate Investment Trust may
        collect any information during the normal use of the site for the
        purpose of monitoring use of the site and helping its further
        development. Such collection shall not result in any personally
        identifiable data.
      </p>
    </div>
  </div>
);

export const TermTH = () => (
  <div>
    <h2 className="text-xl md:text-2xl font-cbold mb-2 w-fit whitespace-nowrap">
      เงื่อนไขการใช้บริการ
    </h2>
    <p>
      การเข้าและการใช้งานเว็บไซต์ของ
      ทรัสต์เพื่อการลงทุนในอสังหาริมทรัพย์และสิทธิการเช่าดุสิตธานี
      เป็นไปตามกฎหมายของประเทศไทย และกฎและเงื่อนไขดังต่อไปนี้:
    </p>
    <br />
    <div className="md:pl-4">
      <div className="flex w-full gap-2">
        <h2 className="text-lg md:text-xl font-cbold mb-2 w-fit whitespace-nowrap">
          ลิขสิทธิ์
        </h2>
        <div className="border-b border-gold w-full mb-2"></div>
      </div>
      <p>
        ข้อความทั้งหมดภายในเว็บไซต์นี้อยู่ภายใต้ลิขสิทธิ์ของ
        ทรัสต์เพื่อการลงทุนในอสังหาริมทรัพย์และสิทธิการเช่าดุสิตธานี (2562)
        ขอสงวนสิทธิ์ในข้อความ รูปภาพ เสียง ซอฟท์แวร์ และอื่นๆ
        ที่ปรากฏอยู่ในเว็บไซต์แห่งนี้ซึ่ง
        ทรัสต์เพื่อการลงทุนในอสังหาริมทรัพย์และสิทธิการเช่าดุสิตธานี
        เป็นเจ้าของลิขสิทธิ์และทรัพย์สินทางปัญญาแต่เพียงผู้เดียว
      </p>
      <br />
      <p>
        ทรัสต์เพื่อการลงทุนในอสังหาริมทรัพย์และสิทธิการเช่าดุสิตธานี
        อนุญาตให้ท่านเรียกดูเว็บไซต์
        หรือตัดทอนข้อความเหล่านี้ด้วยวิธีสั่งพิมพ์บนกระดาษ
        การถ่ายโอนข้อมูลใส่สื่อ บันทึก หรือแจกจ่ายข้อมูลให้บุคคลอื่น
        เพื่อใช้เป็นข้อมูลส่วนบุคคล อันมิใช่เพื่อผลประโยชน์ทางการค้า
        ห้ามมิให้ใช้ในกรณีใดๆ เพื่อ การค้า หรือ เพื่อผลประโยชน์ทางการค้า หรือ
        ดัดแปลงและเอาไปใช้ในงานอื่นๆ ทั้งด้านสื่อสิ่งพิมพ์และเว็บไซต์
        ไม่ว่าจะในรูปแบบใดๆ รวมถึงการนำไปใช้ในเว็บไซต์อื่นๆ
      </p>
      <br />
      <div className="flex w-full gap-2">
        <h2 className="text-lg md:text-xl font-cbold mb-2 w-fit whitespace-nowrap">
          เครื่องหมายการค้า
        </h2>
        <div className="border-b border-gold w-full mb-2"></div>
      </div>
      <p>
        เครื่องหมายการค้าที่ปรากฏในเว็บไซต์นี้เป็นกรรมสิทธิ์ของทรัสต์เพื่อการลงทุนในอสังหาริมทรัพย์และสิทธิการเช่าดุสิตธานี
      </p>
      <br />
      <div className="flex w-full gap-2">
        <h2 className="text-lg md:text-xl font-cbold mb-2 w-fit whitespace-nowrap">
          เนื้อหา
        </h2>
        <div className="border-b border-gold w-full mb-2"></div>
      </div>
      <p>
        ข้อมูลในเว็บไซต์นี้ใช้สำหรับเป็นข้อมูลทั่วไปเท่านั้น
        มิได้มีเพื่อวัตถุประสงค์ใดวัตถุประสงค์หนึ่งโดยเฉพาะ
        และไม่มีการประกันความเที่ยงตรงและความสมบูรณ์
        ทั้งทรัสต์เพื่อการลงทุนในอสังหาริมทรัพย์และสิทธิการเช่าดุสิตธานี
        และพนักงาน หรือตัวแทน ไม่รับผิดชอบต่อการสูญหาย
        หรือค่าใช้จ่ายที่เกิดขึ้นจากการเข้าหรือใช้เว็บไซต์แห่งนี้
        หรือเว็บไซต์อื่นๆ ที่มีการเชื่อมต่อ
        ทรัสต์เพื่อการลงทุนในอสังหาริมทรัพย์และสิทธิการเช่าดุสิตธานี
        ขอสงวนสิทธิ์ในการเปลี่ยนแปลง
        และแก้ไขข้อมูลที่ปรากฏในเว็บไซต์นี้หากเมื่อมีการพิจารณาว่าเหมาะสม
        โดยไม่ต้องทำการแจ้งให้ทราบล่วงหน้าแต่อย่างใด
      </p>
      <br />
      <div className="flex w-full gap-2">
        <h2 className="text-lg md:text-xl font-cbold mb-2 w-fit whitespace-nowrap">
          เว็บไซต์ที่มีการเชื่อมต่อกัน
        </h2>
        <div className="border-b border-gold w-full mb-2"></div>
      </div>
      <p>
        ทรัสต์เพื่อการลงทุนในอสังหาริมทรัพย์และสิทธิการเช่าดุสิตธานี
        อาจมีการเชื่อมข้อมูลกับเว็บไซต์ของบุคคลที่สามที่เกี่ยวข้องกับเว็บไซต์แห่งนี้
        แต่มิได้หมายความว่าทรัสต์เพื่อการลงทุนในอสังหาริมทรัพย์และสิทธิการเช่าดุสิตธานี
        ต้องเกี่ยวข้องกับเว็บไซต์หรือเจ้าของเว็บไซต์ต่างๆ เหล่านี้
        ทรัสต์เพื่อการลงทุนในอสังหาริมทรัพย์และสิทธิการเช่าดุสิตธานี
        และพนักงานไม่รับผิดชอบต่อข้อความและเนื้อหาใดๆ
        ที่สร้างขึ้นและเผยแพร่โดยบุคคลที่สามเหล่านั้น
        การเชื่อมข้อมูลกับเว็บไซต์อื่นๆ
        ไม่อาจถือโดยนัยได้ว่าทรัสต์เพื่อการลงทุนในอสังหาริมทรัพย์และสิทธิการเช่าดุสิตธานี
        รับรองเว็บไซต์ หรือข้อมูลที่กล่าวถึงในเว็บไซต์เหล่านั้น
      </p>
      <br />
      <div className="flex w-full gap-2">
        <h2 className="text-lg md:text-xl font-cbold mb-2 w-fit whitespace-nowrap">
          นโยบายพิทักษ์สิทธิส่วนบุคคล
        </h2>
        <div className="border-b border-gold w-full mb-2"></div>
      </div>
      <p>
        ทรัสต์เพื่อการลงทุนในอสังหาริมทรัพย์และสิทธิการเช่าดุสิตธานี
        อาจเก็บข้อมูลระหว่างที่ท่านใช้เว็บไซต์นี้ตามปกติ
        เพื่อวัตถุประสงค์ในการตรวจสอบการใช้เว็บไซต์และช่วยในการพัฒนาเว็บไซต์นี้ต่อไป
        โดยการเก็บข้อมูลดังกล่าวจะไม่มีการเก็บข้อมูลส่วนบุคคล
        ที่สามารถระบุถึงตัวผู้ใช้บริการแต่ละคนแต่อย่างใด
      </p>
    </div>
  </div>
);
