import React, { useState, useEffect, useRef } from 'react';
import { TEModal, TEModalDialog, TEModalContent, TEModalHeader, TEModalBody, TEModalFooter } from "tw-elements-react";

const DescModal = ({ descObj, isOpen, onClose }) => {
    const iframeRef = useRef(null);
    const [iframeH, setiframeH] = useState(720);

    useEffect(() => {
        if (isOpen && iframeRef.current) {
            const modalContent = document.querySelector('.TEModalContent');
            if (modalContent) {
                const availableHeight = modalContent.clientHeight;
                iframeRef.current.style.height = `${availableHeight - 60}px`;
                setiframeH(availableHeight - 20);
            }
        }
    }, [isOpen]);

    if (!isOpen) {
        return null;
    }

    return (
        <div>
            {/* Use TEModal component here */}
            <TEModal show={isOpen} setShow={onClose}>
                <TEModalDialog size="xl">
                    <TEModalContent>
                        <TEModalHeader>
                            <h5 className="text-xl font-medium leading-normal text-neutral-800 dark:text-neutral-200">
                                {descObj.title}
                            </h5>
                        </TEModalHeader>
                        <TEModalBody>
                            <div className="text-left" dangerouslySetInnerHTML={{ __html: descObj.desc }} />                            
                        </TEModalBody>
                        <TEModalFooter>
                            <button
                                type="button"
                                className="inline-block rounded bg-primary-100 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:bg-primary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200"
                                onClick={onClose}
                            >
                                Close
                            </button>
                        </TEModalFooter>
                    </TEModalContent>
                </TEModalDialog>
            </TEModal>
        </div>
    );
};

export default DescModal;