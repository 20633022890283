import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useTranslation, Trans } from "react-i18next";
import DescModal from "../components/DescModal";
import Navbar from "../components/navbar";

export default function News(props) {
  const { i18n } = useTranslation();
  const navitems = [
    {
      title: "SET Announcements",
      title_th: "ข่าวแจ้งตลาดหลักทรัพย์",
      active: false,
      link: "/Investor_Relations/Newsroom/SET_Announcements",
    },
    {
      title: "Press Releases",
      title_th: "ข่าวแจ้งสื่อมวลชน",
      active: false,
      link: "/Investor_Relations/Newsroom/Press_Releases",
    },
    {
      title: "News & Events",
      title_th: "ข่าวสารและกิจกรรม",
      active: true,
      link: "/Investor_Relations/Newsroom/News_&_Events",
    },
  ];

  const [showDescModal, setShowDescModal] = useState(false);
  const [descObj, setDescObj] = useState(null);
  const [data, setData] = useState([]);

  const populateEventData = async () => {
    const response = await Axios.get(
      process.env.REACT_APP_API_URI +
        "/NewsReport/GetEventsReport?lng=" +
        i18n.language
    ).then((res) => {
      const data = res.data;
      const modifiedData = data.map((item, i) => {
        return {
          index: i,
          newsDate: item.newsDate,
          newsTitle: item.newsTitle,
          newsDesc: item.newsDesc,
          newsURL: item.newsURL,
        };
      });

      setData(modifiedData);
    });
  };

  const handleDescClick = (title, desc) => {
    const newDescObj = {
      title,
      desc,
    };

    setShowDescModal(true);
    setDescObj(newDescObj);
  };

  useEffect(() => {
    populateEventData();
  }, [i18n.language]);

  const [navFix, setFixed] = useState(false);

  const updatefixed = (fix) => {
    setFixed(fix);
  };

  return (
    <section className="assetperform">
      <div className="mb-[150px] md:mb-[170px]">
        <Navbar navitems={navitems} />
      </div>
      <div>
        <h1
          className={`text-xl md:text-2xl font-cbold text-blue-950 mb-6 ${
            navFix ? "mt-[500px]" : ""
          }`}
        >
          <Trans i18nKey="NewsEvent.header">News & Events</Trans>
        </h1>
      </div>
      <div className="flex items-center justify-between flex-wrap">
        <table
          className="w-full text-xs md:text-base lg:text-base stripe hover no-footer dataTable"
          id="DataTables_Table_3"
        >
          <thead>
            <tr className="font-cbold text-blue-950 bg-neutral-50">
              <th
                className="sorting_disabled"
                rowspan="1"
                colspan="1"
                aria-label="วันที่"
              >
                วันที่
              </th>
              <th
                className="sorting_disabled"
                rowspan="1"
                colspan="1"
                aria-label="หัวข้อข่าว"
              >
                หัวข้อข่าว
              </th>
              <th
                className="text-right sorting_disabled"
                rowspan="1"
                colspan="1"
                aria-label=""
              ></th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, i) => (
              <tr className={i % 2 === 0 ? "even" : "odd"}>
                <td>{item.newsDate}</td>
                <td>{item.newsTitle}</td>
                <td className="text-right">
                  {item.newsURL !== "" && (
                    <div>
                      <a
                        href={item.newsURL}
                        download
                        target="_blank"
                        className="sm:hidden text-neutral-400 hover:text-blue-950 flex justify-center"
                      >
                        <div className="w-1/3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="2"
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                            />
                          </svg>
                        </div>
                      </a>
                      <a
                        href={item.newsURL}
                        target="_blank"
                        download
                        className="max-sm:hidden mt-[5px] text-center inline-block rounded bg-blue-950 border-2 border-blue-950 px-6 pb-2 pt-2.5 text-xs font-cbold uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-white hover:text-blue-950"
                      >
                        Download
                      </a>
                    </div>
                  )}
                  {item.newsDesc !== "" && (
                    <div>
                      <a
                        onClick={() =>
                          handleDescClick(item.newsTitle, item.newsDesc)
                        }
                        download
                        target="_blank"
                        className="sm:hidden text-neutral-400 hover:text-blue-950 flex justify-center"
                      >
                        <div class="w-1/3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="2"
                            stroke="currentColor"
                            class="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                            />
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                          </svg>
                        </div>
                      </a>
                      <a
                        download
                        className="max-sm:hidden mt-[5px] text-center inline-block rounded bg-blue-700 border-2 border-blue-700 px-8 pb-2 pt-2.5 text-xs font-cbold uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-white hover:text-blue-700"
                        onClick={() =>
                          handleDescClick(item.newsTitle, item.newsDesc)
                        }
                      >
                        View
                      </a>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <DescModal
        descObj={descObj}
        isOpen={showDescModal}
        onClose={() => {
          document.body.style.overflow = "auto";
          setShowDescModal(false);
        }}
      />
    </section>
  );
}
