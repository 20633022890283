import React, { useEffect, useState } from "react";
import NavLocManage from "./navlocmanage";
import NavLocIR from "./navlocIR";
import { MdKeyboardArrowDown } from "react-icons/md";

export default function MegaNav(props) {
  const [nav, setNav] = useState(NavLocManage);
  const [sub, setSub] = useState([]);

  useEffect(() => {
    if (props.sec == "mg") {
      setNav(NavLocManage);
    } else {
      setNav(NavLocIR);
    }
  }, [props]);

  useEffect(() => {
    if (nav.length) {
      let subactive = nav.filter((n) => n.active == true)[0].submenu;
      setSub(subactive);
    }
  }, [nav]);

  const navActive = (index) => {
    const navA = nav.map((n, i) => {
      if (i === index) {
        n.active = true;
      } else {
        n.active = false;
      }
      return n;
    });
    setNav(navA);
  };

  const [wheight, setWheight] = useState(window.innerHeight);
  const [wwidth, setWwidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", (e) => {
      setWheight(window.innerHeight - 80);
      setWwidth(window.innerWidth);
    });
  });

  useEffect(() => {
    //console.log(wwidth);
  }, [wwidth]);

  return (
    <div
      className="hidden group-hover:block max-md:h-fit relative md:absolute z-[9999] w-full md:w-3/4 lg:w-2/3 bg-white md:top-[60px] right-0 md:right-[5%] rounded overflow-y-auto md:border-gold md:border md:border-t-0"
      style={
        wwidth > 1024
          ? { height: "fit-content", maxHeight: wheight < 694 ? wheight : 694 }
          : {}
      }
    >
      <div className="flex justify-between max-md:flex-col">
        <div className="flex flex-col md:basis-1/2">
          {nav.map((n, i) => (
            <div
              key={i}
              onClick={() => navActive(i)}
              className={`max-md:pl-8 max-md:pr-2 pt-5 pb-3 md:pb-5 md:px-5 ${
                n.active ? "md:bg-white" : "md:bg-gray-200"
              }`}
            >
              <div className="font-cbold text-blue-950 flex justify-between md:block">
                <div>
                  {props.lng == "en"
                    ? n.title
                    : n.title_th != undefined
                    ? n.title_th
                    : n.title}
                </div>
                <div className="md:hidden">
                  <MdKeyboardArrowDown />
                </div>
              </div>
              <div className="text-zinc-500 pl-2 text-base line-clamp-2 max-md:hidden">
                {n.submenu &&
                  n.submenu.map(
                    (s, si, n) =>
                      (props.lng == "en"
                        ? s.title
                        : s.title_th != undefined
                        ? s.title_th
                        : s.title) + (si + 1 !== n.length ? ", " : "")
                  )}
              </div>
              <div className={`hidden ${n.active ? "max-md:block" : ""}`}>
                <ul className="text-zinc-800">
                  {sub &&
                    sub.map((s, si) => (
                      <li
                        key={si}
                        className="md:hover:bg-stone-100 md:pl-2 max-md:pt-2 md:py-4 group/sub-menu"
                      >
                        <a href={s.url}>
                          <div className="flex gap-4 items-center ">
                            <div className="md:border border-stone-300 rounded text-gold py-2 px-2 md:group-hover/sub-menu:bg-gold md:group-hover/sub-menu:text-white">
                              {s.icon != undefined ? s.icon : ""}
                            </div>
                            <div>
                              {props.lng == "en"
                                ? s.title
                                : s.title_th != undefined
                                ? s.title_th
                                : s.title}
                            </div>
                          </div>
                        </a>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
        <div className="max-md:hidden md:basis-1/2">
          <ul className="text-zinc-800">
            {sub &&
              sub.map((s, si) => (
                <li
                  key={si}
                  className="hover:bg-stone-100 pl-2 py-4 group/sub-menu"
                >
                  <a href={s.url}>
                    <div className="flex gap-4 items-center ">
                      <div className="border border-stone-300 rounded text-gold py-2 px-2 group-hover/sub-menu:bg-gold group-hover/sub-menu:text-white">
                        {s.icon != undefined ? s.icon : ""}
                      </div>
                      <div>
                        {props.lng == "en"
                          ? s.title
                          : s.title_th != undefined
                          ? s.title_th
                          : s.title}
                      </div>
                    </div>
                  </a>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
