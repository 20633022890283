import React, { useEffect, useState } from "react";
import Axios from "axios";
import DataTable from "../components/DataTable";
import { useTranslation, Trans } from "react-i18next";

import NavbarWpicture from "../components/navbarWpicture";

export default function Historical(props) {
  const { i18n, t } = useTranslation();
  const navitems = [
    {
      title: "Stock Quotes",
      title_th: "ราคาหลักทรัพย์",
      active: false,
      link: "/Investor_Relations/Stock_Information/Stock_Quotes",
    },
    {
      title: "Historical Price",
      title_th: "ราคาย้อนหลัง",
      active: true,
      link: "/Investor_Relations/Stock_Information/Historical_Price",
    },
  ];

  const [data, setData] = useState([]);
  const [selectedDate, setDateData] = useState("");

  const populateStockData = async () => {
    const response = await Axios.get(
      process.env.REACT_APP_API_URI +
        "/Stock/GetListStock?stDateType=" + selectedDate + "&lng=" + i18n.language
    ).then((res) => {
      const data = res.data;

      const modifiedData = data.map((item) => {
        return {
          stDate: item.stockDate,
          stDateName: item.lastUpdateDate,
          openPrice: item.openPrice,
          highPrice: item.highPrice,
          lowPrice: item.lowPrice,
          closePrice: item.closePrice,
          volumnShares: item.volumnShares,
          volumnBaht: item.volumnBaht,
        };
      });

      setData(modifiedData);
    });
  };

  const dataColumn = [
    {
      title: "stDate",
      data: "stDate",
      render: function (data, type, row) {
        // Format the date as "dd/MM/yyyy" for display
        if (type === "display" || type === "filter") {
          const date = new Date(data);
          const day = date.getDate().toString().padStart(2, "0");
          const month = (date.getMonth() + 1).toString().padStart(2, "0");
          const year = date.getFullYear();
          return `${year}/${month}/${day}`;
        }
        return data; // For sorting and other types
      },
    },
    {
      title: t('stock.DailyHistoricalData'),
      data: "stDateName",
      orderable: false,
    },
    {
      title: t('stock.open'),
      data: "openPrice",
      orderable: false,      
    },
    {
      title: t('stock.high'),
      data: "highPrice",
      orderable: false,
      className: "hidden md:table-cell",
    },
    {
      title: t('stock.low'),
      data: "lowPrice",
      orderable: false,
      className: "hidden md:table-cell",
    },
    {
      title: t('stock.close'),
      data: "closePrice",
      orderable: false,
    },
    {
      title: t('stock.share'),
      data: "volumnShares",
      orderable: false,
      className: "hidden md:table-cell",
    },
    {
      title: t('stock.baht'),
      data: "volumnBaht",
      orderable: false,
    },
  ];

  const dataDefs = [
    {
      targets: 0, // Index of the 'mDate' column
      visible: false,
      orderData: [0], // Sort by this column
      orderSequence: ["desc"], // Sort in descending order
    },
  ];

  const dataOrder = [[0, "desc"]];

  useEffect(() => {
    setDateData("Month");
  }, []);

  useEffect(() => {
    populateStockData();
  }, [i18n.language, selectedDate]);

  const [navFix, setFixed] = useState(false);

  const updatefixed = (fix) => {
    setFixed(fix);
  };

  return (
    <section className="assetperform">
      <div className="absolute top-[90px] left-0 w-screen">
        <div className="w-full h-[280px] max-w-[1920px] overflow-hidden mx-auto">
          <img
            src={require("./img/stockCover.png")}
            alt="cover"
            className="object-cover w-full h-full object-center"
          />
        </div>
      </div>
      <div className="mb-[50px] md:mb-[50px] mt-[350px]">
        <NavbarWpicture navitems={navitems} fixed={updatefixed} />
      </div>
      <div>
        <h1
          className={`text-xl md:text-2xl font-cbold text-blue-950 mb-6 ${
            navFix ? "mt-[500px]" : ""
          }`}
        >
          <Trans i18nKey="stock.history">Historical Price</Trans>
        </h1>
        <div className="table-performance">
          <div className="flex gap-1 mb-6">
            <label htmlFor="selectBox" className=" text-gray-700">
              <Trans i18nKey="stock.range">Stock Range:</Trans>
            </label>
            <select
              id="selectBox"
              name="selectBox"
              onChange={(e) => setDateData(e.target.value)}
              className="appearance-none w-[200px] pl-2 border-b-2 border-gold rounded-none rounded-se-md focus:outline-none focus:ring focus:ring-gold/60 focus:ring-offset-1"
            >
              <option value="Week">
                1 <Trans i18nKey="stock.week">Week</Trans>
              </option>
              <option value="Month">
                1 <Trans i18nKey="stock.month">Month</Trans>
              </option>
              <option value="3Month">
                3 <Trans i18nKey="stock.months">Months</Trans>
              </option>
              <option value="6Month">
                6 <Trans i18nKey="stock.months">Months</Trans>
              </option>
              <option value="Year">
                1 <Trans i18nKey="stock.year">Year</Trans>
              </option>
              <option value="3Year">
                3 <Trans i18nKey="stock.years">Years</Trans>
              </option>
              <option value="5Year">
                5 <Trans i18nKey="stock.years">Years</Trans>
              </option>
            </select>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
              />
            </svg>
          </div>
          <div className="table-performance">
            <DataTable
              data={data}
              dataColumn={dataColumn}
              dataDefs={dataDefs}
              dataOrder={dataOrder}              
            />
          </div>
        </div>
      </div>
    </section>
  );
}
