import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useTranslation, Trans } from "react-i18next";
import icon from "./img/nav.svg";
import uparrow from "./img/uparrow.svg";
import downarrow from "./img/downarrow.svg";

export default function Nav(props) {
  const { i18n, t } = useTranslation();

  const initNav = {
    navPrice: "",
    navDate: "",
    navTotalAsset: "",
  };

  const [nav, setNav] = useState(initNav);

  const populateNavData = async () => {
    const response = await Axios.get(
        process.env.REACT_APP_API_URI + "/Nav/GetNav?lng=" + i18n.language
    ).then((res) => {
      const data = res.data;
      setNav({
        ...nav,
        navPrice: data.navPrice,
        navDate: data.lastUpdateDate,
        navTotalAsset: data.totalNetAsset,
      });
    });
  };

  useEffect(() => {
    populateNavData();
  }, [i18n.language]);

    let textSizeClass = i18n.language === 'en' ? 'text-2xl xl:text-4xl ' : 'text-xl xl:text-2xl ';

  return (
    <div
      className={`w-full h-fit md:w-96 md:h-96 md:px-8 py-6 bg-white flex-col justify-between items-center gap-4 inline-flex md:shadow-lg shadow-gray-500`}
    >
      <div className="flex max-md:flex-col max-md:items-center justify-between w-full">
        <div>
          <img
            src={icon}
            alt="card-ico"
            id="quote-ico"
            className="w-[60%] max-md:mx-auto md:w-[calc(100%-25%)] xl:w-fit"
          />
        </div>        
        <div className={`text-blue-950 font-cbold ${textSizeClass}`}>
            {t('Home.NAV')}
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <div className="text-2xl md:text-5xl xl:text-6xl text-blue-950 font-cbold text-center">
          {nav.navPrice !== "" ? nav.navPrice : "Loading..."}
        </div>        
      </div>
      <div className="text-neutral-500 font-cbold max-md:text-sm text-center">
        {t("Home.NetAssetValue") + " "}
        {nav.navTotalAsset !== "" ? nav.navTotalAsset : "Loading..."}
      </div>
      <div className="text-neutral-500 text-xs md:text-sm text-center">
        {t("Home.Updated") + " "}
        {nav.navDate !== "" ? nav.navDate : "Loading..."}
      </div>
    </div>
  );
}
