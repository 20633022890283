import React from "react";
import { useNavigate } from "react-router-dom";

import bgImg from "./img/asset.png";

import point from "./img/point.svg";
import { useTranslation, Trans } from "react-i18next";

export default function AssetInfo(props) {
  const nav = useNavigate();
  const { i18n } = useTranslation();

  const assetClick = (a) => {
    switch (a) {
      case "phuket":
        nav("/Management/Asset_Infomation/Laguna_Phuket_Hotel");
        break;
      case "huahin":
        nav("/Management/Asset_Infomation/Hua_Hin_Hotel");
        break;
      case "maldives":
        nav("/Management/Asset_Infomation/Maldives_Hotel");
        break;
      default:
        return;
    }
  };
  return (
    <section
      style={{ "--image-url": `url(${bgImg})` }}
      className={`asset bg-[image:var(--image-url)] w-full h-screen md:h-[90vh] bg-center relative`}
    >
      <div className="absolute top-0 w-full h-full">
        <div className="w-full h-1/2 bg-gradient-to-b from-stone-50 from-20% to-stone-50/40"></div>
        <div className="w-full h-1/2 bg-gradient-to-t from-stone-50 from-20% to-stone-50/40"></div>
      </div>
      <div className="relative h-fit">
        <div className="max-w-[1400px] mx-auto max-2xl:px-5 py-8">
          <div className="text-blue-950 text-2xl md:text-4xl lg:text-5xl xl:text-6xl font-cbold mb-6">
            <Trans i18nKey="AssetInfo.AssetHead">Asset Information</Trans>
          </div>
          <div className="flex gap-6 h-[80vh] md:h-[50vh] lg:h-[70vh] justify-between lg:mb-10 max-md:flex-col">
            <div
              className="flex-auto w-full md:hover:w-[200%] max-md:h-2/3 relative transition-all duration-300 ease-linear cursor-pointer"
              onClick={() => assetClick("phuket")}
            >
              <img
                src={require("./img/phuket.jpeg")}
                className="w-full h-full object-cover object-center"
              />
              <div className="absolute bottom-0 p-4 pt-4 md:p-8 md:pt-14 bg-gradient-to-t from-slate-900 to-slate-300/10 w-full">
                <div className="text-zinc-400 text-lg md:text-xl lg:text-2xl">
                  <Trans i18nKey="AssetInfo.AssetHotel">Dusit Thani</Trans>
                </div>
                <div className="flex justify-between items-center md:block">
                  <div className="flex gap-2">
                    <div>
                      <img
                        src={point}
                        alt="point"
                        className="w-[calc(100%-15%)]"
                      />
                    </div>
                    <div className="text-white text-lg md:text-xl lg:text-2xl uppercase">
                      <Trans i18nKey="AssetInfo.Asset1Topic">
                        Maldives Hotel
                      </Trans>
                    </div>
                  </div>
                  <div className="text-neutral-300 md:mt-4">
                    More Detail{" >"}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="flex-auto w-full md:hover:w-[200%] max-md:h-2/3 relative transition-all duration-300 ease-linear cursor-pointer"
              onClick={() => assetClick("huahin")}
            >
              <img
                src={require("./img/huahin.jpeg")}
                className="w-full h-full object-cover object-center md:object-[85%_0]"
              />
              <div className="absolute bottom-0 p-4 pt-4 md:p-8 md:pt-14 bg-gradient-to-t from-slate-900 to-slate-300/10 w-full">
                <div className="text-zinc-400 text-lg md:text-xl lg:text-2xl">
                  <Trans i18nKey="AssetInfo.AssetHotel">Dusit Thani</Trans>
                </div>
                <div className="flex justify-between items-center md:block">
                  <div className="flex gap-2 ">
                    <div>
                      <img
                        src={point}
                        alt="point"
                        className="w-[calc(100%-15%)]"
                      />
                    </div>
                    <div className="text-white text-lg md:text-xl lg:text-2xl uppercase">
                      <Trans i18nKey="AssetInfo.Asset2Topic">
                        Maldives Hotel
                      </Trans>
                    </div>
                  </div>

                  <div className="text-neutral-300 md:mt-4">
                    More Detail{" >"}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="flex-auto w-full md:hover:w-[200%] max-md:h-2/3 relative transition-all duration-300 ease-linear cursor-pointer"
              onClick={() => assetClick("maldives")}
            >
              <img
                src={require("./img/maldive.jpeg")}
                className="w-full h-full object-cover object-center md:object-[25%_0]"
              />
              <div className="absolute bottom-0 p-4 pt-4 md:p-8 md:pt-14 bg-gradient-to-t from-slate-900 to-slate-300/10 w-full">
                <div className="text-zinc-400 text-lg md:text-xl lg:text-2xl">
                  <Trans i18nKey="AssetInfo.AssetHotel">Dusit Thani</Trans>
                </div>
                <div className="flex justify-between items-center md:block">
                  <div className="flex gap-2 ">
                    <div>
                      <img
                        src={point}
                        alt="point"
                        className="w-[calc(100%-15%)]"
                      />
                    </div>
                    <div className="text-white text-lg md:text-xl lg:text-2xl uppercase">
                      <Trans i18nKey="AssetInfo.Asset3Topic">
                        Maldives Hotel
                      </Trans>
                    </div>
                  </div>

                  <div className="text-neutral-300 md:mt-4">
                    More Detail{" >"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
