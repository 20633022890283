import React, { useEffect, useState } from "react";
import Chart from "react-google-charts";
import Axios from "axios";
import NavbarWpicture from "../components/navbarWpicture";
import { useTranslation, Trans } from "react-i18next";

export default function UnitNAV(props) {
  const { i18n, t } = useTranslation();

  const navitems = [
    {
      title: "REIT Performance",
      title_th: "ผลการดำเนินงานของกองทุน",
      active: false,
      link: "/Investor_Relations/Financial_Information/REIT_Performance",
    },
    {
      title: "Asset Performance",
      title_th: "ผลการดำเนินงานของอสังหาริมทรัพย์",
      active: false,
      link: "/Investor_Relations/Financial_Information/Asset_Performace",
    },
    {
      title: "Financial Statement",
      title_th: "งบการเงิน",
      active: false,
      link: "/Investor_Relations/Financial_Information/Financial_Statement",
    },
    {
      title: "Management Discussion and Analysis",
      title_th: "คำอธิบายและการวิเคราะห์ของฝ่ายจัดการ",
      active: false,
      link: "/Investor_Relations/Financial_Information/Management_Discussion_and_Analysis",
    },
    {
      title: "Appraisal Report",
      title_th: "รายงานประเมินมูลค่าทรัพย์สิน",
      active: false,
      link: "/Investor_Relations/Financial_Information/Appraisal_Report",
    },
    {
      title: "Unit NAV",
      title_th: "มูลค่าสินทรัพย์สุทธิ(NAV)",
      active: true,
      link: "/Investor_Relations/Financial_Information/Unit_NAV",
    },
  ];

  const [selectedYear, setSelectedYear] = useState("last");
  const [data, setData] = useState([]);
  const [chartdata, setChartData] = useState([]);

  const handleSelectChange = async (e) => {
    const year = e.target.value;
    setSelectedYear(year);

    populateUnitNAVData(year);
  };

  const populateUnitNAVData = async (year) => {
    if (year == "last") {
      const response = await Axios.get(
        process.env.REACT_APP_API_URI + "/Nav/GetNavLast/" + i18n.language
      ).then((res) => {
        const data = res.data;

        const modifiedData = data.map((item) => {
          return {
            month: item.chartMonthShort,
            nav: item.chartNavValue,
            total: item.chartTotalNetValue,
          };
        });
        setData(data);
        setChartData(modifiedData.reverse());
      });
    } else {
      const response = await Axios.get(
        process.env.REACT_APP_API_URI +
          "/Nav/GetNavWithYear?fYear=" +
          year +
          "&lng=" +
          i18n.language
      ).then((res) => {
        const data = res.data;

        const modifiedData = data.map((item) => {
          return {
            month: item.chartMonthShort,
            nav: item.chartNavValue,
            total: item.chartTotalNetValue,
          };
        });
        setData(data.reverse());
        setChartData(modifiedData);
      });
    }
  };

  useEffect(() => {
    populateUnitNAVData(selectedYear);
  }, [i18n.language]);

  const [navFix, setFixed] = useState(false);

  const updatefixed = (fix) => {
    setFixed(fix);
  };

  return (
    <section className="unit-nav">
      <div className="absolute top-[90px] left-0 w-screen">
        <div className="w-full max-w-[1920px] max-h-[280px] overflow-hidden mx-auto">
          <img
            src={require("./img/financeCover.png")}
            alt="cover"
            className="object-cover w-full"
          />
        </div>
      </div>
      <div className="mb-[50px] md:mb-[50px] mt-[320px] md:mt-[350px]">
        <NavbarWpicture navitems={navitems} fixed={updatefixed} />
      </div>
      <div>
        <h1
          className={`text-xl md:text-2xl font-cbold text-blue-950 mb-6 ${
            navFix ? "mt-[500px]" : ""
          }`}
        >
          <Trans i18nKey="UnitNAV.header">Unit NAV</Trans>
        </h1>
        <div className="flex gap-2 mb-6">
          <label htmlFor="selectBox" className="block text-gray-700">
            <Trans i18nKey="UnitNAV.year">Year:</Trans>
          </label>
          <select
            id="selectBox"
            name="selectBox"
            onChange={handleSelectChange}
            className="appearance-none min-w-fit px-2 border-b-2 border-gold rounded-none rounded-se-md focus:outline-none focus:ring focus:ring-gold/60 focus:ring-offset-1"
          >
            <option value="last" selected>
              {i18n.language == "en" ? "last 12 Months" : "12 เดือนล่าสุด"}
            </option>
            <option value="2024">2024</option>
            <option value="2023">2023</option>
            <option value="2022">2022</option>
            <option value="2021">2021</option>
            <option value="2020">2020</option>
            <option value="2019">2019</option>
            <option value="2018">2018</option>
            <option value="2017">2017</option>
          </select>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
            />
          </svg>
        </div>
      </div>
      <div className="w-full flex flex-col gap-4 lg:flex-row justify-between">
        <div className="w-full lg:w-1/2">
          <Chart
            width={"100%"}
            height={"400px"}
            chartType="ComboChart" // Change the chart type to ComboChart
            data={[
              ["Months", t("UnitNAV.UnitPriceUnit")], // Define column headers
              ...chartdata.map((item) => [item.month, item.nav]),
            ]}
            options={{
              title: t("UnitNAV.Topic2"),
              vAxis: { title: t("UnitNAV.UnitPriceUnit") },
              hAxis: {
                title: `${
                  selectedYear == "last"
                    ? i18n.language == "en"
                      ? "last 12 months"
                      : "12 เดือนล่าสุด"
                    : selectedYear
                }`,
              },
              seriesType: "line",
              series: {
                0: { type: "line" },
                1: { type: "bars" },
              },
            }}
            rootProps={{ "data-testid": "1" }}
          />
        </div>
        <div className="w-full lg:w-1/2">
          <Chart
            width={"100%"}
            height={"400px"}
            chartType="ComboChart" // Change the chart type to ComboChart
            data={[
              ["Months", t("UnitNAV.NetAssetValue")], // Define column headers
              ...chartdata.map((item) => [item.month, item.total]),
            ]}
            options={{
              title: t("UnitNAV.Topic3"),
              vAxis: { title: t("UnitNAV.NetAssetValue") },
              hAxis: {
                title: `${
                  selectedYear == "last"
                    ? i18n.language == "en"
                      ? "last 12 months"
                      : "12 เดือนล่าสุด"
                    : selectedYear
                }`,
              },
              seriesType: "bars",
              series: { 1: { type: "line" } },
            }}
            rootProps={{ "data-testid": "1" }}
          />
        </div>
      </div>
      <div className="p-4">
        <table className="w-full table-fixed border-collapse">
          <thead>
            <tr className="font-cbold text-blue-900 bg-neutral-100">
              <th className="p-2 text-left">
                <Trans i18nKey="UnitNAV.Topic1">Data as of</Trans>
              </th>
              <th className="p-2">
                <Trans i18nKey="UnitNAV.Topic2">
                  Unit Price per Unit (Baht per unit)
                </Trans>
              </th>
              <th className="p-2 text-right">
                <Trans i18nKey="UnitNAV.Topic3">Net Asset Value (Baht)</Trans>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((val, i) => (
              <tr className="border-b border-gray-100">
                <td className="p-2">{val.lastUpdateDate}</td>
                <td className="p-2 text-center">{val.navPrice}</td>
                <td className="p-2 text-right">{val.totalNetAsset}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
}
