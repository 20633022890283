import React, { useEffect, useState } from "react";
import Chart from "react-google-charts";
import Axios from "axios";
import { useTranslation, Trans } from "react-i18next";

export default function OccupancyRate({ selectedAsset, selectedYear }) {
  const { i18n } = useTranslation();

  const [chartdata, setChartData] = useState([]);

  const populateOccupancyData = async (assetCode, fYear) => {
    const response = await Axios.get(
      process.env.REACT_APP_API_URI +
        "/Financial/GetOccupancyRate?assetCode=" +
        assetCode +
        "&fnYear=" +
        fYear
    ).then((res) => {
      const data = res.data;

      const modifiedData = data.map((item) => {
        return {
          fyear: item.chartYear,
          adr: item.occupancyRate_ADR,
          revpar: item.occupancyRate_REVPAR,
          percent: item.occupancyRate_Percent,
        };
      });
      if (fYear !== "") {
        setChartData(modifiedData);
      } else {
        setChartData(modifiedData.reverse());
      }
    });
  };

  useEffect(() => {
    populateOccupancyData(selectedAsset, selectedYear);
  }, [i18n.language, selectedAsset, selectedYear]);

  const options = {
    title: "",
    hAxis: {
      title: "",
      format: "####", // Display only the year in the hAxis labels
    },
    series: {
      0: { type: "bars", targetAxisIndex: 0 },
      1: { type: "bars", targetAxisIndex: 0 },
      2: {
        type: "line",
        targetAxisIndex: 1,
        pointsVisible: true,
      },
    },
    vAxes: {
      0: { title: "" },
      1: {
        title: "",
        textPosition: "none",
        format: "short",
        viewWindow: {
          max: 100,
        },
        gridlines: {
          color: "transparent",
        },
      },
    },
  };

  return (
    <div className="chart-container">
      <Chart
        width={"100%"}
        height={"400px"}
        chartType="ComboChart"
        data={[
          ["Year", "ADR", "REVPAR", "Occ. Rate"], // Define column headers
          ...chartdata.map((item) => [
            item.fyear,
            item.adr,
            item.revpar,
            item.percent,
          ]),
        ]}
        options={options}
      />
    </div>
  );
}
