import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useTranslation, Trans } from "react-i18next";
import OperationPerformance from "./reitperform/OperationPerformance";
import FinancialPosition from "./reitperform/FinancialPosition";
import FinancialRatios from "./reitperform/FinancialRatios";
import NavbarWpicture from "../components/navbarWpicture";

export default function ReitPerform(props) {
  const { i18n } = useTranslation();
  const navitems = [
    {
      title: "REIT Performance",
      title_th: "ผลการดำเนินงานของกองทุน",
      active: false,
      link: "/Investor_Relations/Financial_Information/REIT_Performance",
    },
    {
      title: "Asset Performance",
      title_th: "ผลการดำเนินงานของอสังหาริมทรัพย์",
      active: false,
      link: "/Investor_Relations/Financial_Information/Asset_Performace",
    },
    {
      title: "Financial Statement",
      title_th: "งบการเงิน",
      active: false,
      link: "/Investor_Relations/Financial_Information/Financial_Statement",
    },
    {
      title: "Management Discussion and Analysis",
      title_th: "คำอธิบายและการวิเคราะห์ของฝ่ายจัดการ",
      active: false,
      link: "/Investor_Relations/Financial_Information/Management_Discussion_and_Analysis",
    },
    {
      title: "Appraisal Report",
      title_th: "รายงานประเมินมูลค่าทรัพย์สิน",
      active: false,
      link: "/Investor_Relations/Financial_Information/Appraisal_Report",
    },
    {
      title: "Unit NAV",
      title_th: "มูลค่าสินทรัพย์สุทธิ(NAV)",
      active: false,
      link: "/Investor_Relations/Financial_Information/Unit_NAV",
    },
  ];

  const [data, setData] = useState([]);

  const populateREITData = async () => {
    const response = await Axios.get(
      process.env.REACT_APP_API_URI + "/Financial/GetFinancial"
    ).then((res) => {
      const data = res.data;
      setData(data);
    });
  };

  useEffect(() => {
    populateREITData();
  }, [i18n.language]);

  const [navFix, setFixed] = useState(false);

  const updatefixed = (fix) => {
    setFixed(fix);
  };

  return (
    <section className="reitperform">
      <div className="absolute top-[90px] left-0 w-screen">
        <div className="w-full max-w-[1920px] max-h-[280px] overflow-hidden mx-auto">
          <img
            src={require("./img/financeCover.png")}
            alt="cover"
            className="object-cover w-full"
          />
        </div>
      </div>
      <div className="mb-[50px] md:mb-[50px] mt-[350px]">
        <NavbarWpicture navitems={navitems} fixed={updatefixed} />
      </div>
      <div>
        <h1
          className={`text-xl md:text-2xl font-cbold text-blue-950 mb-6 ${
            navFix ? "mt-[500px]" : ""
          }`}
        >
          <Trans i18nKey="REITPerformance.header">REIT Performance</Trans>
        </h1>
        <div className="table-performance overflow-x-auto">
          <OperationPerformance />
          <FinancialPosition />
          <FinancialRatios />
        </div>
      </div>
    </section>
  );
}
