import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useTranslation, Trans } from "react-i18next";
export default function Faq(props) {
  const { t, i18n } = useTranslation();

  const [data, setData] = useState([]);

  const populateFaqData = async () => {
    const response = await Axios.get(
      process.env.REACT_APP_API_URI +
        "/Faq/GetFaq?maxQuest=6&lng=" +
        i18n.language
    ).then((res) => {
      const dat = res.data.map((d, i) => {
        d.active = false;
        return d;
      });
      setData(dat);
    });
  };

  useEffect(() => {
    populateFaqData();
  }, [i18n.language]);

  useEffect(() => {
    //console.log(data);
  }, [data]);

  const showAns = (index) => {
    const dat = data.map((d, i) => {
      if (i == index) {
        if (!d.active) {
          d.active = true;
        } else {
          d.active = false;
        }
      } else {
        d.active = false;
      }
      return d;
    });
    setData(dat);
  };

  const [mousePos, setMousePos] = useState({});

  useEffect(() => {
    const handleMouseMove = (event) => {
      setMousePos({ x: event.clientX, y: event.clientY });
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const mouse = "top-[" + mousePos.y + "px]" + " left-[" + mousePos.x + "px]";

  return (
    <section className="faq pt-10 container max-w-[1400px]">
      <div className="flex flex-col items-center px-6">
        <div className="text-blue-950 text-2xl md:text-4xl lg:text-5xl xl:text-6xl font-cbold">
          <Trans i18nKey="FAQ.Topic">Frequently Asked Questions</Trans>
        </div>
        <div>
          <p className="md:text-xl text-neutral-500 w-full text-center mx-auto mt-4">
            <Trans i18nKey="FAQ.Desc">
              The following frequently asked questions many investors have about
              DREIT at Dusit Thani Properties REIT.
            </Trans>
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-8">
          {data.map((a, i) => (
            <div key={i} className="pt-4">
              <div className="text-zinc-700 font-cbold md:text-xl">
                {a.question}
              </div>
              {a.answer && (
                <div>
                  <div
                    className={`text-zinc-700 md:text-md ${
                      a.active == true ? "" : "line-clamp-3"
                    } cursor-pointer`}
                    onClick={() => showAns(i)}
                  >
                    <div dangerouslySetInnerHTML={{ __html: a.answer }} />
                  </div>
                  <div
                    className={`${
                      a.active == true
                        ? "hidden"
                        : "mt-2 text-right text-gold pr-2 cursor-pointer hover:font-cbold"
                    }`}
                    onClick={() => showAns(i)}
                  >
                    {i18n.language == "en" ? "read more.." : "แสดงเพิ่ม.."}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="border-2 border-gold text-base md:text-lg lg:text-xl text-gold px-4 md:px-6 py-1 md:py-2 mb-6 hover:bg-gold hover:text-white cursor-pointer">
          <a href="/faq">{i18n.language == "en" ? "More FAQ" : "คำถามอื่นๆ"}</a>
        </div>
      </div>
    </section>
  );
}
